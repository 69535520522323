import { formatName } from "@ca-dmv-radv/utilities";

/**
 * Adapted with minimal change from previous version of app.
 */
export function parseReceivedNameChanges(nameChanges, application) {
  const identityDocName = {
    id: 0,
    name: formatName(application.documents.identity.documentName),
    firstName: application.documents.identity.documentName.firstName || "",
    middleName: application.documents.identity.documentName.middleName || "",
    lastName: application.documents.identity.documentName.lastName || "",
    suffix: application.documents.identity.documentName.suffix || "",
    catDocId: application.documents.identity.catDocId,
    order: 0,
  };

  const nextNameChanges = [identityDocName, ...nameChanges];

  if (nameChanges.length === 0) {
    nextNameChanges.push({
      id: null,
      name: formatName(application.application.name),
      firstName: application.application.name.firstName || "",
      middleName: application.application.name.middleName || "",
      lastName: application.application.name.lastName || "",
      suffix: application.application.name.suffix || "",
      catDocId: null,
      order: nameChanges.length + 1,
    });
  }

  return nextNameChanges;
}
