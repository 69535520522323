import { useEffect } from 'react';

export default function addNexisScript(sessionId) {

    const nexisUrl = process.env.REACT_APP_NEXIS_URL;
    const nexisOrgId = process.env.REACT_APP_NEXIS_ORG_ID;
    const nexisPath = '/libs/lexis-nexis/fb-clientlib-v4.js';

    useEffect(() => {
        if (sessionId !== null) {
            const script = document.createElement('script');
            script.src = nexisPath;
            script.async = true;
            document.body.append(script);

            script.onload = () => threatmetrix.profile(nexisUrl, nexisOrgId, sessionId);

            return () => {
                document.body.removeChild(script);
            }
        } 
    }, []);

}