import React from "react";
import { Button, BUTTON_STYLE_LINK, DrunkenSquare } from "@ca-dmv/core";
import { useTranslation } from "@ca-dmv-radv/translation";

export default function DropzoneMessage({ isDragActive, uploading, onClick }) {
  const { t } = useTranslation();

  if (isDragActive) {
    return (
      <p className="text--white text--700 mb-0">
        {t(
          "screens-ProofOfIdentity-Modal-Upload-dropToUpload",
          "Drop to Upload"
        )}
      </p>
    );
  }

  if (uploading) {
    return (
      <DrunkenSquare
        loadingText={t(
          "screens-ProofOfIdentity-Modal-Upload-loadingText",
          "Uploading File ..."
        )}
        textColor="blue text--700"
      />
    );
  }

  return (
    <div className="flex flex--col flex--align-center">
      <p className="flex flex--align-center text--400 mb-0">
        <Button
          label={t(
            "screens-ProofOfIdentity-Modal-Upload-selectDocumentLabel",
            "Select Document"
          )}
          btnStyle={BUTTON_STYLE_LINK}
          onClick={onClick}
          buttonClass="text--400"
        />
        <span className="ml-10 mr-10">
          {t("screens-ProofOfIdentity-Modal-Upload-or", "or")}
        </span>
        <span>
          {t(
            "screens-ProofOfIdentity-Modal-Upload-dragAndDrop",
            "Drag and Drop"
          )}
        </span>
      </p>
      <p className="text--blue-dark-2 mt-10 mb-0">
        {t(
          "screens-ProofOfIdentity-Modal-Upload-secure-message",
          "to securely submit a document"
        )}
      </p>
    </div>
  );
}
