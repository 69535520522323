import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  ApplicationContextProvider,
  DocumentUploadsContextProvider,
  SMSContextProvider,
  ModalContextProvider,
  ErrorContextProvider,
  FileUploadContextProvider,
  GoogleAnalyticsContextProvider,
  setLanguage,
  getApplicationData,
  APPLICATION_TYPE,
  getDocumentsByCategory,
  IdentityDocumentContextProvider,
  DOCUMENT_CATEGORY_MDL_IDENTITY,
  NameChangesContextProvider,
  DOCUMENT_CATEGORY_NAME_CHANGE,
  CatalogsContextProvider,
  getApplicationDocUploads,
} from "@ca-dmv-radv/data";
// dmv design styles are loaded here
import "@ca-dmv/design-system/dist/css/style.css";
import { handleApplicationError } from "./util";
import { mdlRoutes, odpRoutes, vdlRoutes } from "./routes";
import TimeoutHandler from "./TimeoutHandler";

async function renderFlowByBusinessProgram(applicationType) {
  let initialApplication;
  let applicationLanguage;
  let initialIdentityDocuments;
  let initialNameChangeDocuments;
  let initialDocumentUploads;
  let routes;

  switch (applicationType) {
    case APPLICATION_TYPE.ODP:
      routes = odpRoutes;
      break;
    case APPLICATION_TYPE.VDL:
      routes = vdlRoutes;
      break;
    case APPLICATION_TYPE.MDL:
    default:
      routes = mdlRoutes;
  }

  try {
    const { success, ...data } = await getApplicationData(applicationType);

    if (!success) {
      throw new Error("getApplicationData");
    }

    initialApplication = data;
    applicationLanguage = data?.lang;
    await setLanguage(applicationLanguage);
    if (applicationType === APPLICATION_TYPE.MDL) {
      [initialIdentityDocuments, initialNameChangeDocuments] =
        await Promise.all([
          getDocumentsByCategory(
            DOCUMENT_CATEGORY_MDL_IDENTITY,
            applicationType
          ),
          getDocumentsByCategory(
            DOCUMENT_CATEGORY_NAME_CHANGE,
            applicationType
          ),
        ]);
    }
    initialDocumentUploads = await getApplicationDocUploads(applicationType);
  } catch (error) {
    handleApplicationError(error, applicationType);
    return;
  }

  function RouteHelper() {
    return (
      <CatalogsContextProvider>
        <TimeoutHandler applicationType={applicationType} />
        <Routes>
          {routes.map(({ path, Component }) => (
            <Route path={path} key={path} element={<Component />} />
          ))}
        </Routes>
      </CatalogsContextProvider>
    );
  }

  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter basename={`/${applicationType}`}>
        <ErrorContextProvider>
          <GoogleAnalyticsContextProvider>
            <ApplicationContextProvider
              application={initialApplication}
              applicationType={applicationType}
            >
              <FileUploadContextProvider>
                <DocumentUploadsContextProvider
                  initialDocumentUploads={initialDocumentUploads?.data}
                  applicationType={applicationType}
                >
                  <ModalContextProvider>
                    <SMSContextProvider>
                      {applicationType === APPLICATION_TYPE.MDL ? (
                        <IdentityDocumentContextProvider
                          identityDocuments={initialIdentityDocuments?.data}
                        >
                          <NameChangesContextProvider
                            initialNameChangeDocuments={
                              initialNameChangeDocuments?.data
                            }
                          >
                            <RouteHelper />
                          </NameChangesContextProvider>
                        </IdentityDocumentContextProvider>
                      ) : (
                        <RouteHelper />
                      )}
                    </SMSContextProvider>
                  </ModalContextProvider>
                </DocumentUploadsContextProvider>
              </FileUploadContextProvider>
            </ApplicationContextProvider>
          </GoogleAnalyticsContextProvider>
        </ErrorContextProvider>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
  );
}
export default renderFlowByBusinessProgram;
