/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";
import { isMobile } from "react-device-detect";
import {
  RadioGroup,
  RadioItem,
  SelectMenu,
  RequiredAsterisk,
  useFocusedKey,
} from "@ca-dmv/core";
import { useTranslation } from "@ca-dmv-radv/translation";

export default function SelectDocument({
  legend,
  selectLabel,
  documentList,
  documentId,
  onChange,
  error,
  name,
  required,
  idField = "id",
}) {
  const { t } = useTranslation();
  const { focusedElementKey } = useFocusedKey();

  const fixedDocList = documentList.map(
    ({ name: label, id: value, ...rest }) => {
      return {
        label,
        value,
        ...rest,
      };
    }
  );

  return (
    <>
      {!isMobile && (
        <RadioGroup containerClass="mb-0 pb-0" legend={legend} error={error}>
          {documentList.map((document, index) => (
            <RadioItem
              key={document[idField]}
              index={index}
              label={document.name}
              containerClass={
                index < documentList.length - 1 ? "mb-24" : "mb-0"
              }
              radioStyle="bordered"
              radioClass="w--100 bp-md:max-width--600 mb-0 pb-0"
              name={name}
              checked={documentId === document[idField]}
              onChange={() => onChange(document[idField])}
              hideAsterisk
              hasFocus={
                focusedElementKey && focusedElementKey === name && index === 0
              }
            />
          ))}
        </RadioGroup>
      )}
      {isMobile && (
        <fieldset className="pb-0 mb-0 pb-0">
          <legend className="mb-8">
            {legend}
            {required && <RequiredAsterisk />}
          </legend>
          <SelectMenu
            selectPlaceholder={t(
              "screens-ProofOfIdentity-selectDocument",
              "Select Document"
            )}
            containerClass="w--100 mb-0 max-width--400"
            label={selectLabel}
            options={fixedDocList}
            selectedValue={documentId || ""}
            onChange={(value) => onChange(value)}
            error={error}
            hasFocus={focusedElementKey && focusedElementKey === name}
          />
        </fieldset>
      )}
    </>
  );
}
