import {
  STATUS_PREAPPROVED,
  STATUS_READY,
  STATUS_SUBMITTING,
  STATUS_IN_PROGRESS,
} from "@ca-dmv/core";
import {
  DOCUMENT_CATEGORY_RELATIONSHIP,
  DOCUMENT_CATEGORY_RESIDENCY,
} from "../constants";
import { getDocumentSectionStatus } from "../utils";

export default function getResidencyStatus(
  documentUploads,
  selectedDocuments,
  application,
  submitting
) {
  if (submitting) {
    return STATUS_SUBMITTING;
  }

  const residencyDocs =
    documentUploads?.[String(DOCUMENT_CATEGORY_RESIDENCY)] || [];
  const relationshipDocs =
    documentUploads?.[String(DOCUMENT_CATEGORY_RELATIONSHIP)] || [];
  const allDocs = [...residencyDocs, ...relationshipDocs];

  const atLeastOneDocIsSelected =
    selectedDocuments?.[1]?.id || selectedDocuments?.[2]?.id;

  if (!allDocs?.length) {
    return atLeastOneDocIsSelected ? STATUS_IN_PROGRESS : "";
  }
  if (!selectedDocuments?.[1]?.id || !selectedDocuments?.[2]?.id) {
    return "";
  }

  if (
    residencyDocs[0]?.catDocId !== selectedDocuments?.[1]?.id ||
    residencyDocs[1]?.catDocId !== selectedDocuments?.[2]?.id
  ) {
    return "";
  }

  if (application.status.DOCS_VERIFIED_DT && application.status.FINISHED) {
    return STATUS_PREAPPROVED;
  }

  if (application.status.DOCS_VERIFIED_DT && application.status.DOCS_VERIFIED) {
    return STATUS_READY;
  }

  const status = getDocumentSectionStatus(allDocs);

  if (!status && atLeastOneDocIsSelected) {
    return STATUS_IN_PROGRESS;
  }

  return status;
}
