const inlineDashedBorder = {
  // left top right bottom
  backgroundImage:
    "repeating-linear-gradient(0deg, #0B669E, #0B669E 10px, transparent 10px, transparent 20px), repeating-linear-gradient(90deg, #0B669E, #0B669E 10px, transparent 10px, transparent 20px), repeating-linear-gradient(180deg, #0B669E, #0B669E 10px, transparent 10px, transparent 20px), repeating-linear-gradient(270deg, #0B669E, #0B669E 10px, transparent 10px, transparent 20px)",
  backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
  backgroundSize: "1px 100%, 100% 1px, 1px 100%, 100% 1px",
  backgroundRepeat: "no-repeat",
};

export default inlineDashedBorder;
