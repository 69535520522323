import {
  APPLICATION_TYPE,
  RedirectToError,
  RedirectToUnderMaintenance,
} from "@ca-dmv-radv/data";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import routes from "./routes";

function LoadingError() {
  return (
    <React.StrictMode>
      <BrowserRouter basename="/dv">
        <Routes>
          {routes.map(({ path }) => (
            <Route path={path} key={path} element={<RedirectToError />} />
          ))}
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  );
}

function UnderMaintenance({ applicationType }) {
  return (
    <React.StrictMode>
      <BrowserRouter basename={`/${applicationType}`}>
        <Routes>
          {routes.map(({ path }) => (
            <Route
              path={path}
              key={path}
              element={<RedirectToUnderMaintenance />}
            />
          ))}
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  );
}

const urlMap = {
  [APPLICATION_TYPE.DV]: process.env.REACT_APP_EDL_URL,
  [APPLICATION_TYPE.MDL]: process.env.REACT_APP_MDL_URL,
  [APPLICATION_TYPE.VDL]: process.env.REACT_APP_VDL_URL,
  [APPLICATION_TYPE.ODP]: process.env.REACT_APP_ODP_URL,
};

export function handleApplicationError(error, applicationType) {
  if (
    error?.name === "ApplicationNotFound" ||
    error?.name === "SessionExpired"
  ) {
    window.location = urlMap[applicationType];
  } else if (error?.name === "MaintenanceEnabled") {
    ReactDOM.render(
      <UnderMaintenance applicationType={applicationType} />,
      document.getElementById("root")
    );
  } else {
    ReactDOM.render(<LoadingError />, document.getElementById("root"));
  }
}
