import { useNameChanges } from "@ca-dmv-radv/data";
import { useTranslation } from "@ca-dmv-radv/translation";
import { useMemo } from "react";

export default function useConfirmNameErrors() {
  const { t } = useTranslation();
  const { nameChanges, nameMatches, selectedNameMatchesOption, hasNameChangeDocs, noDocsOption } =
    useNameChanges();

  const firstNameChange = nameChanges[0] || {};
  const { firstName, middleName, lastName } = firstNameChange;

  return useMemo(() => {
    const newErrors = {};
    if (selectedNameMatchesOption !== true && selectedNameMatchesOption !== false) {
      newErrors.nameMatches = t(
        "errorMessages.nameMatchesMissing",
        "Please select if this is the name that matches your document."
      );

      return newErrors;
    }

    if (selectedNameMatchesOption) {
      return newErrors;
    }

    if (!lastName.trim()) {
      newErrors.lastName = t(
        "errorMessages.lastNameMissing",
        "Please enter your last name."
      );
    }

    if (hasNameChangeDocs !== true && hasNameChangeDocs !== false) {
      newErrors.hasNameChangeDocs = t(
        "errorMessages.hasNameChangeDocsMissing",
        "Please select if you have ALL of your name change documents."
      );
    }

    if (hasNameChangeDocs === false && noDocsOption === null) {
      newErrors.noDocsOption = t(
        "errorMessages.noDocsOptionMissing",
        "Please select whether you will upload name change certification documents at a later time."
      );
    }

    return newErrors;
  }, [nameMatches, selectedNameMatchesOption, firstName, lastName, t, hasNameChangeDocs, noDocsOption]);
}