import React, { useEffect } from "react";
import { RadvPageWrapper } from "@ca-dmv-radv/components";
import { AppointmentSelection } from "@ca-dmv/appointment-selection";
import {
  useAppointments,
  useApplication,
  useGoogleAnalytics,
  sendEmailFinalConfirmation,
  sendEmailAppointmentConfirmation,
} from "@ca-dmv-radv/data";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "@ca-dmv-radv/translation";
import { formatDate, formatTime } from "@ca-dmv-radv/utilities";

function Appointments({ title }) {
  const { t } = useTranslation();

  const {
    selectedFieldOffice,
    selectedAppointment,
    setSelectedAppointment,
    setNotificationMethod,
    setTelNumber,
    setNeedsAccommodations,
  } = useAppointments();
  const { application } = useApplication();
  const { setAnalyticsAction } = useGoogleAnalytics();
  const navigate = useNavigate();

  // Navigate back if selectedFieldOffice is empty.
  useEffect(() => {
    if (!selectedFieldOffice) {
      navigate("/appointment-location");
    }
  }, [selectedFieldOffice, navigate]);

  const userEmail = application.email;
  const legalName = {
    firstName: application?.application?.name?.firstName,
    lastName: application?.application?.name?.lastName,
  };

  function validateDTO(obj) {
    for (let val in obj) {
      for (let elem in val) {
        if (val[elem] === null || val[elem] === "") {
          return false;
        }
        return true;
      }
    }
  };

  function onComplete(state) {
    setSelectedAppointment(state.selectedAppointment);
    setNotificationMethod(state.notificationMethod);
    setTelNumber(state.telNumber);
    setNeedsAccommodations(state.needsAccommodations);
    navigate("/final-confirmation");
    const appointment = {
      date: formatDate(state.selectedAppointment?.date),
      time: formatTime(state.selectedAppointment?.time),
    };
    const fieldOffice = {
      title: selectedFieldOffice?.title?.rendered,
      street: selectedFieldOffice?.meta.dmv_field_office_street,
      city: `${selectedFieldOffice?.meta.dmv_field_office_city}, CA ${selectedFieldOffice?.meta.dmv_field_office_zipcode}`
    }
    const dto = {
      userEmail,
      appointment,
      fieldOffice,
    }
    if (validateDTO(dto)) {
      sendEmailAppointmentConfirmation(dto);
    } else {
      throw new Error ("Appointment information could not be validated"); 
    }
  }

  const appointmentText = {
    appointmentCanceled: t(
      "screens-appointment-text.appointmentCanceled",
      "Appointment Cancelled"
    ),
    warning: t("screens-appointment-text.warning", "Appointment Warning"),
    alreadyHaveAnAppointmentWarning: t(
      "screens-appointment-text.alreadyHaveAnAppointmentWarning",
      "We see you already have an appointment scheduled. To keep your scheduled appointment, click Confirm below. To schedule a new appointment, please click Cancel Appointment below."
    ),
    cancelAppointmentText: t(
      "screens-appointment-text.cancelAppointmentText",
      "Cancel Appointment"
    ),
    fieldOfficeTitle: t(
      "screens-appointment-text.fieldOfficeTitle",
      "Field Office"
    ),
    scheduledAppointment: t(
      "screens-appointment-text.cheduledAppointment",
      "Scheduled Appointment"
    ),
    noLongerAvailableError: t(
      "screens-appointment-text.noLongerAvailableError",
      "The selected appointment is no longer available"
    ),
    loadingImgAltText: t(
      "screens-appointment-text.loadingImgAltText",
      "spinning square"
    ),
    appointmentSelectionPrompt: t(
      "screens-appointment-text.appointmentSelectionPrompt",
      "What day and appointment time would you like?"
    ),
    appointmentSelectionPromptHint: t(
      "screens-appointment-text.appointmentSelectionPromptHint",
      "Here are some available appointment times at the location you selected. You can also see other times by clicking the button below."
    ),
    at: t("screens-appointment-text.at", "at"),
    fieldOffice: t("screens-appointment-text.fieldOffice", "Field Office"),
    locationMapMarkerAlt: t(
      "screens-appointment-text.locationMapMarkerAlt",
      "Map location marker"
    ),
    appointmentReminderLabel: t(
      "screens-appointment-text.appointmentReminderLabel",
      "What type of appointment reminder do you want?"
    ),
    confirmAppointmentPrompt: t(
      "screens-appointment-text.confirmAppointmentPrompt",
      "Enter your mobile phone number to confirm your appointment."
    ),
    requestADA: t(
      "screens-appointment-text.requestADA",
      "I would like to request an ADA compliant service counter."
    ),
    scheduleThroughDMVsAppointmentTool: t(
      "screens-appointment-text.scheduleThroughDMVsAppointmentTool",
      "See all available appointments"
    ),
    textMeLabel: t(
      "screens-appointment-text.textMeLabel",
      "Text me an appointment reminder."
    ),
    callMeLabel: t(
      "screens-appointment-text.callMeLabel",
      "Call me with an appointment reminder."
    ),
    unknownError: t(
      "screens-appointment-text.unknownError",
      "An unknown error occurred."
    ),
    confirmLabel: t("screens-appointment-text.confirmLabel", "Confirm"),
    confirmButtonPuppose: t(
      "screens-appointment-text.confirmButtonPuppose",
      "Appointment"
    ),
    phoneHint: t(
      "screens-appointment-text.phoneHint",
      "Numbers only, no special characters"
    ),
    seeAllAvailableAppointments: t(
      "screens-appointment-text.seeAllAvailableAppointments",
      "See all available appointments"
    ),
    backLabel: t(
      "screens-appointment-text.screens-appointment-text.backLabel",
      "back"
    ),
    backToDMVLabel: t("screens-appointment-text.back", "Back"),
    ariaBack: t(
      "screens-appointment-text.ariaBack",
      "go back to previous form"
    ),
    phoneLabel: t("screens-appointment-text.phoneLabel", "Phone Number"),
    adaAccomodations: t(
      "screens-appointment-text.adaAccomodations",
      "ADA Accomodations"
    ),
    errorTextTranslations: {
      singularErrorText: t(
        "screens-appointment-text.singularErrorText",
        "You have an error that needs your attention."
      ),
      pluralErrorsText: t(
        "screens-appointment-text.pluralErrorsText",
        "You have %d errors that need your attention."
      ),
      singleLinkText: t(
        "screens-appointment-text.singleLinkText",
        "Clicking on the link below will guide you to the section that needs more information."
      ),
      multipleLinksText: t(
        "screens-appointment-text.multipleLinksText",
        "Clicking on the links below will guide you to each section that needs more information."
      ),
    },
    unexpectedError: t(
      "screens-appointment-text.unexpectedError",
      "We encountered an unexpected error. Please try again."
    ),
  };

  return (
    <RadvPageWrapper
      pageHeading={t("screens-appointment-heading", "Appointment Selection")}
      formProps={{ isForm: false }}
      pageTitle={title}
    >
      <AppointmentSelection
        translations={appointmentText}
        onComplete={(state) => onComplete(state)}
        userEmail={userEmail}
        legalName={legalName}
        fieldOffice={selectedFieldOffice}
        apiBase={`${process.env.REACT_APP_WORDPRESS_API_URL}/dmv/v1`}
        recaptchaSiteKey={process.env.REACT_APP_RECAPTCHA_KEY}
        allAppointmentsLink={process.env.REACT_APP_ALL_APPOINTMENTS_LINK}
        allAppointmentsCallback={() => {
          setAnalyticsAction("SeeAll");
        }}
        backToDmvLink={process.env.REACT_APP_BACK_TO_DMV_LINK}
        onBack={() => {
          navigate(-1);
        }}
      />
    </RadvPageWrapper>
  );
}

export default Appointments;
