import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  ALERT_ERROR,
  YesNoRadioGroup,
  Button,
  ButtonWrapper,
  useFocusedKey,
  RadioGroup,
  RadioItem,
} from "@ca-dmv/core";
import { Trans, useTranslation } from "@ca-dmv-radv/translation";
import {
  RadvPageWrapper,
  NameConfirmationCard,
  CARD_RID_APPLICATION_NAME,
  ButtonSaveAndExit,
  ButtonBack,
  NameFields,
} from "@ca-dmv-radv/components";
import { useIdentityDocument, useNameChanges } from "@ca-dmv-radv/data";
import useConfirmNameErrors from "./useConfirmNameErrors";
import useConfirmNameNavigation from "./useConfirmNameNavigation";
import { ICON_WARNING_RED } from "@ca-dmv/core/dist/js/icon";

function Identity({ title }) {
  const [showErrors, setShowErrors] = useState(false);
  const [isEdited, setIsEdited] = useState(null);

  const { t } = useTranslation();
  // const { focusedElementKey } = useFocusedKey();
  const { selectedIdentityDocument, showIdentityNameOptions } = useIdentityDocument();
  const {
    nameMatches,
    setNameMatches,
    selectedNameMatchesOption,
    setSelectedNameMatchesOption,
    nameOnDocument,
    clearNameChange,
    nameChanges,
    hasNameChangeDocs,
    setHasNameChangeDocs,
    noDocsOption,
    setNoDocsOption,
  } = useNameChanges();
  const { setFirstName, setMiddleName, setLastName, setSuffix } =
    useNameChanges();
  const previousHasNameChangeDocsOption = useRef(hasNameChangeDocs);

  const firstNameChange = nameChanges[0] || {
    firstName: "",
    lastName: "",
    suffix: "",
    middleName: "",
  };
  const { firstName, middleName, lastName, suffix } = firstNameChange;
  const errors = useConfirmNameErrors();
  const { requestNavigation, navigating } = useConfirmNameNavigation({
    errors,
    setShowErrors,
    isEdited,
  });
  const [nextButtonClick, setNextButtonClick] = useState(false);

  // Clear all name fields if selectedNameMatchesOption is true.
  useEffect(() => {
    if (selectedNameMatchesOption === true) {
      clearNameChange(0);
    }
  }, [nameMatches]);

  // Clear noDocsOption when hasNameChangeDocs changes.
  useEffect(() => {
    if (hasNameChangeDocs !== previousHasNameChangeDocsOption.current) {
      setNoDocsOption(null);
    }
  }, [hasNameChangeDocs]);

  // Clear noDocsOption when nameMatches changes.
  useEffect(() => {
    if (selectedNameMatchesOption === true) {
      setNoDocsOption(null);
      setHasNameChangeDocs(null);
    }
  }, [selectedNameMatchesOption]);

  // Set isEdited to true if anything is edited.
  useEffect(() => {
    setIsEdited((previousValue) => previousValue !== null);
  }, [firstName, middleName, lastName, suffix, nameMatches, selectedNameMatchesOption, noDocsOption]);

  // Call requestNavigation when Next button is clicked
  useEffect(() => {
    if (nextButtonClick) {
      requestNavigation();
      setNextButtonClick(false);
    }
  }, [nextButtonClick]);

  return (
    <RadvPageWrapper
      pageHeading={t("screens-ProofOfIdentity-legend", "Proof of Identity")}
      formProps={{
        isForm: true,
        showErrors,
        errors,
      }}
      pageTitle={title}
    >
    {!showIdentityNameOptions && 
      <div>
        <p className="text--blue-dark-2 mb-40 bp-md:mb-48 max-width--800">
          {t(
            "screens-returnToDashboard-backButton-warning",
            "Please avoid using the browser navigation buttons to make changes to your application."
          )}
        </p>
        <ButtonWrapper wrapperClass="flex--col-reverse mt-40 bp-md:mt-60">
        <Button
          label={t("screens-returnToDashboard-button-text", "Return to Dashboard")}
          buttonClass="bp-md:mr-24 mb-24 bp-md:mb-0"
          onClick={() => setNextButtonClick(true)}
          isLoading={navigating}
        />
      </ButtonWrapper>
      </div>
  }
  {showIdentityNameOptions && 
    <div>
      <h3 className="text--h5 text--700 mb-8 bp-md:mb-16">
        {t("screens-confirm-name-subHeading", "Name Confirmation")}
      </h3>
      <p className="text--blue-dark-2 mb-40 bp-md:mb-48 max-width--800">
        {t(
          "screens-confirm-name-subHeading-description",
          "Federal regulations require DMV to issue REAL ID driver’s licenses or ID cards in accordance with the document used to establish your identity. If the name on your application does not match the name on your document, you will need to provide certification of the different name."
        )}
      </p>
      <NameConfirmationCard
        containerClass="mb-40 bp-md:mb-48"
        cardType={CARD_RID_APPLICATION_NAME}
        name={nameOnDocument}
      />
      <YesNoRadioGroup
        containerClass="mb-0 max-width--600"
        name="nameMatches"
        legend={
          <Trans
            i18nKey="screens-confirm-name-isThisYourNameLegend"
            defaults="Is <span>{{fullName}}</span> the name printed on your <span>{{document}}</span>?"
            values={{
              fullName: nameOnDocument,
              document:
                selectedIdentityDocument?.name ||
                t(
                  "screens-confirm-name-identityDocumentDescription",
                  "selected identity document"
                ),
            }}
            components={{ span: <span className="text--blue" /> }}
          />
        }
        yesLabel={t("shared.yesLabel", "Yes")}
        noLabel={t("shared.noLabel", "No")}
        onChange={setSelectedNameMatchesOption}
        value={selectedNameMatchesOption}
        // focusedElementKey={focusedElementKey}
        showErrors={showErrors}
        errorKey="nameMatches"
        translatedErrors={errors}
        yesValue
        noValue={false}
      />
      {selectedNameMatchesOption === false && (
        <>
          <NameFields
            namePrompt={
              <Trans
                i18nKey="screens-confirm-name-whatNameIsOn"
                defaults="Enter your name as it appears on your <span>{{document}}</span>."
                values={{
                  document:
                    selectedIdentityDocument?.name ||
                    t(
                      "screens-confirm-name-identityDocumentDescription",
                      "selected identity document"
                    ),
                }}
                components={{ span: <span className="text--blue" /> }}
              />
            }
            firstName={firstName}
            middleName={middleName}
            lastName={lastName}
            suffix={suffix}
            onChangeFirstName={(newFirstName) => {
              setFirstName(0, newFirstName);
            }}
            onChangeMiddleName={(newMiddleName) => {
              setMiddleName(0, newMiddleName);
            }}
            onChangeLastName={(newLastName) => {
              setLastName(0, newLastName);
            }}
            onChangeSuffix={(newSuffix) => {
              setSuffix(0, newSuffix);
            }}
            errors={errors}
            showErrors={showErrors}
          // focusedElementKey={focusedElementKey}
          />
          <YesNoRadioGroup
            containerClass="mt-40 bp-md:mt-48 mb-0 w--100 max-width--600 mb-24"
            name="doYouHaveDocuments"
            legend={
              <Trans
                i18nKey="screens-confirm-name-doYouHaveDocumentsToUploadLegend"
                defaults="Do you have a document that certifies each name change between <span>{{fullName}}</span> and the name on your <span>{{document}}</span>?"
                values={{
                  fullName: nameOnDocument,
                  document:
                    selectedIdentityDocument?.name ||
                    t(
                      "screens-confirm-name-identityDocumentDescription",
                      "selected identity document"
                    ),
                }}
                components={{ span: <span className="text--blue" /> }}
              />
            }
            yesLabel={t("shared.yesLabel", "Yes")}
            noLabel={t(
              "screens-confirm-name-doYouHaveDocumentsToUpload-NoLabel",
              "No, I do not have access to one or more of my documents."
            )}
            onChange={setHasNameChangeDocs}
            value={hasNameChangeDocs}
            // focusedElementKey={focusedElementKey}
            showErrors={showErrors}
            errorKey="hasNameChangeDocs"
            translatedErrors={errors}
            yesValue
            noValue={false}
          />
        </>
      )}
      {hasNameChangeDocs === false && selectedNameMatchesOption === false && (
        <RadioGroup
          containerClass="w--100 max-width--600 mt-40 bp-md:mt-48 mb-0 pb-0"
          legend={t(
            "screens-confirm-name-documentsToUploadLegend",
            "Since you are unable to provide name change certification documents, select from one of the options."
          )}
          error={errors && showErrors && errors.noDocsOption}
        >
          <RadioItem
            label={t(
              "screens-confirm-name-documentsToUpload-Option1Label",
              "I will obtain certified or original copies of my name change document(s) and upload them at a later time."
            )}
            containerClass="mb-24"
            radioStyle="bordered"
            radioClass="w--100 bp-md:max-width--600 mb-0 pb-0"
            name="noDocsOption"
            checked={noDocsOption === 1}
            onChange={() => setNoDocsOption(1)}
            hideAsterisk
          // hasFocus={focusedElementKey && focusedElementKey === "noDocsOption"}
          />
          <RadioItem
            label={t(
              "screens-confirm-name-documentsToUpload-Option2Label",
              "I would like to use the name on my identity document to obtain a REAL ID driver’s license or identification card."
            )}
            radioStyle="bordered"
            radioClass="w--100 bp-md:max-width--600 mb-0 pb-0"
            name="noDocsOption"
            checked={noDocsOption === 2}
            onChange={() => setNoDocsOption(2)}
            hideAsterisk
          />
        </RadioGroup>
      )}
      <ButtonWrapper wrapperClass="flex--col-reverse mt-40 bp-md:mt-60">
        <ButtonBack buttonClass="bp-md:mr-24" />
        <ButtonSaveAndExit buttonClass="bp-md:mr-24 mb-24 bp-md:mb-0" />
        <Button
          label={t("shared.nextLabel", "Next")}
          buttonClass="bp-md:mr-24 mb-24 bp-md:mb-0"
          onClick={() => setNextButtonClick(true)}
          isLoading={navigating}
        />
      </ButtonWrapper>
    </div>}
    </RadvPageWrapper>
  );
}

export default Identity;