import {
  STATUS_IN_PROGRESS,
  STATUS_READY,
  STATUS_VERIFYING,
  STATUS_INCOMPLETE,
} from "@ca-dmv/core";
import { DOCUMENT_CATEGORY_IDENTITY, DOCUMENT_CATEGORY_MDL_IDENTITY, DOC_UPLOAD_STATUS_IDS } from "@ca-dmv-radv/data";

export default function getDocumentUploadStatusFlag(documentUpload, documentUploads, isMDLDocument) {
  if (!documentUpload) {
    return null;
  }

  const categoryID =isMDLDocument? DOCUMENT_CATEGORY_MDL_IDENTITY:DOCUMENT_CATEGORY_IDENTITY;
  if (documentUploads){
    switch (documentUploads[categoryID]?.[0].ATD){
      case "RSC":
        return STATUS_INCOMPLETE;

      case "SUC":
        return STATUS_READY;
    }
  }

  switch (documentUpload.statusId) {
    case DOC_UPLOAD_STATUS_IDS.PENDING:
      return STATUS_IN_PROGRESS;

    case DOC_UPLOAD_STATUS_IDS.PROCESSING:
      return "status--holding";

    case DOC_UPLOAD_STATUS_IDS.VERIFYING:
    case DOC_UPLOAD_STATUS_IDS.UNDER_REVIEW:
      return STATUS_VERIFYING;

    case DOC_UPLOAD_STATUS_IDS.VERIFIED:
      return STATUS_READY;

    case DOC_UPLOAD_STATUS_IDS.FAILED:
      return STATUS_INCOMPLETE;

    default:
      return null;
  }
}
