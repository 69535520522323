import { useCatalogs, useFileUpload, useApplication } from "@ca-dmv-radv/data";
import React, { useEffect } from "react";
import BirthCerificateForm from "./BirthCertificateForm";
import PassportForm from "./PassportForm";
import { isAbbyyFlow, isBirthCertificate, isPassPort, isPassportCard } from '../helpers/constants';

export default function VerificationFields({ documentUpload, docTypes }) {
  const { states, setShouldFetchStates, counties, setShouldFetchCounties } =
    useCatalogs();
  const {
    birthCertificateFormData,
    setBirthCertificateFormData,
  } = useFileUpload();
  useEffect(() => {
    if (isBirthCertificate(documentUpload.catDocId, docTypes)) {
      setShouldFetchStates(true);
      setShouldFetchCounties(true);
    }
  }, [documentUpload]);


  const handleBCFormDataChange = ({ key, value }) => {
    setBirthCertificateFormData({
      ...birthCertificateFormData,
      [key]: value,
    });
  };
  return (
    <>
      {isBirthCertificate(documentUpload.catDocId, docTypes) && (
        <BirthCerificateForm
          states={states}
          counties={counties}
          changeHandler={handleBCFormDataChange}
          data={birthCertificateFormData}
        />
      )}

      {(isPassPort(documentUpload.catDocId, docTypes) || isPassportCard(documentUpload.catDocId, docTypes)) && (
        <PassportForm
          catDocId={documentUpload.catDocId}
          isAbbyyFlow={isAbbyyFlow(documentUpload.ATD)}
          docTypes={docTypes}
        />
      )}
    </>
  );
}
