import { iProovAuthenticate, iProovRegister, iProovValidate, iProovLogErrorMessages, iProovLogCancelledEvent } from "@ca-dmv-radv/data";
import "@iproov/web-sdk";

export async function invokeIProov(application, applicationType) {
    const iProovSupport = new window.IProov.IProovSupport();
    const apiKey = process.env.REACT_APP_IPROOV_API_KEY;
    const publicKey = await iProovSupport.getPublicKey();
    const publicHash = await window.crypto.subtle.digest('SHA-256', new TextEncoder().encode(atob(publicKey)).buffer);
    const deviceId = [...new Uint8Array(publicHash)].map(b => b.toString(16).padStart(2, '0')).join('');

    const iProovDevice = localStorage.getItem('iProovDevice');

    if (!iProovDevice || iProovDevice !== deviceId) {
        document.body.className = 'loading'
        const registerRequestBody = {
            requestBody: JSON.stringify({
                api_key: apiKey,
                device_id: deviceId,
                platform: process.env.REACT_APP_IPROOV_PLATFORM_TYPE,
                manufacturer: navigator.vendor,
                name: process.env.REACT_APP_IPROOV_ACTION,
                model: navigator.platform,
                os_version: navigator.appVersion,
                app_version: process.env.REACT_APP_IPROOV_APP_VERSION,
                app_id: process.env.REACT_APP_IPROOV_APP_ID,
                public_key: publicKey
            }), signature: null,
            eventName: null
        };
        const register = await iProovRegister(applicationType, registerRequestBody).then((response) => {
            document.body.className = ''

            if (response.data.registration) {
                localStorage.setItem('iProovDevice', deviceId);
            }

        });
    }
    const requestBody = JSON.stringify({
        api_key: apiKey,
        device_id: deviceId,
        document_id: application.drivingLicense.dlNumber,
        action: process.env.REACT_APP_IPROOV_ACTION,
        os_version: navigator.appVersion,
        app_version: process.env.REACT_APP_IPROOV_APP_VERSION,
        dob: application.application.dob
    });
    const authRequest = {
        requestBody: requestBody,
        signature: await iProovSupport.sign(requestBody),
        eventName: null
    };

    const authBody = JSON.stringify(authRequest)

    document.body.className = 'loading'
    let authToken = '';
    const response = await iProovAuthenticate(applicationType, authRequest);
    if (response.success === true) {
        return response.data.authToken;
    } else {
        throw new Error(response.data.message);
    }
};

export async function invokeIProovValidate(authToken, eventName, application, applicationType) {
    const iProovSupport = new window.IProov.IProovSupport();
    const apiKey = process.env.REACT_APP_IPROOV_API_KEY;
    const publicKey = await iProovSupport.getPublicKey();
    const publicHash = await window.crypto.subtle.digest('SHA-256', new TextEncoder().encode(atob(publicKey)).buffer);
    const deviceId = [...new Uint8Array(publicHash)].map(b => b.toString(16).padStart(2, '0')).join('');

    const requestBody = JSON.stringify({
        api_key: apiKey,
        device_id: deviceId,
        document_id: application.drivingLicense.dlNumber,
        action: process.env.REACT_APP_IPROOV_ACTION,
        token: authToken
    });
    const validateBody = { requestBody, signature: await iProovSupport.sign(requestBody), eventName: eventName };
    const responseValidation = await iProovValidate(applicationType, validateBody);
    return responseValidation;
};

export async function invokeIProovLogErrorMessage(application, applicationType, eventName, errorMessage) {
    const requestBody = JSON.stringify({
        errorMessage: errorMessage,
        eventName: eventName,
        dlNumber: application.drivingLicense.dlNumber
    });
    const responseLogErrorMessage = await iProovLogErrorMessages(applicationType, requestBody);
    return responseLogErrorMessage;
};

export async function invokeIProovLogCancelledEvent(applicationType, cancelledObj) {
    const cancelledResponse = await iProovLogCancelledEvent(applicationType, cancelledObj);
    return cancelledResponse;
}