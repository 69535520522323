import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "@ca-dmv-radv/translation";
import { RadvPageWrapper } from "@ca-dmv-radv/components";
import { LocationsMap } from "@ca-dmv/locations-map";
import { useAppointments } from "@ca-dmv-radv/data";
import { useMounted } from "@ca-dmv-radv/utilities";

const PER_PAGE = 10;
const DISTANCE = 50;
const cachedRequests = {};

const getMatchedFieldOffices = (allFieldOffices, qmaticFieldOffices) => {
  return allFieldOffices.filter((fieldOffice) =>
    qmaticFieldOffices.find(
      (qmaticFieldOffice) =>
        qmaticFieldOffice.publicId ===
        fieldOffice.meta.dmv_field_office_public_id
    )
  );
};

function useMatchedLocations(fieldOffices, qmaticFieldOffices, page) {
  const [matchedLocations, setMatchedLocations] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    if (!fieldOffices) {
      setMatchedLocations([]);
      return;
    }
    if (!qmaticFieldOffices) {
      return;
    }

    const matchedFieldOffices = getMatchedFieldOffices(
      fieldOffices,
      qmaticFieldOffices
    );

    const start = (page - 1) * PER_PAGE;
    const results = matchedFieldOffices.map((fieldOffice, index) => ({
      ...fieldOffice,
      cardNum: index + 1,
    }));

    setTotalItems(results.length);
    setMatchedLocations(results.slice(start, start + PER_PAGE));
  }, [fieldOffices, qmaticFieldOffices, page]);

  return useMemo(
    () => ({ matchedLocations, totalItems }),
    [matchedLocations, totalItems]
  );
}

function useAdvanceOnFieldOfficeSelection({ fieldOfficeWasSelected }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (fieldOfficeWasSelected) {
      navigate("/appointments");
    }
  }, [fieldOfficeWasSelected, navigate]);
}

function FieldOffices({ title }) {
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [submittedSearchTerm, setSubmittedSearchTerm] = useState("");
  const [locateMe, setLocateMe] = useState(); // current location object { latitude, longitude }
  const [cities, setCities] = useState();
  const [fieldOffices, setFieldOffices] = useState();
  const [fetchingFieldOffices, setFetchingFieldOffices] = useState();
  const [currentUrl, setCurrentUrl] = useState(
    `${process.env.REACT_APP_WORDPRESS_API_URL}/dmv/v1/field-offices`
  );
  const [listActive, setListActive] = useState(true);
  const [qmaticFieldOffices, setQmaticFieldOffices] = useState();

  // Allows us to redirect to the next screen when a DMV office is first selected,
  // but when the user goes back via the back button, they can change their selection.
  const [didSelect, setDidSelect] = useState(false);

  const { t } = useTranslation();
  const { selectedFieldOffice, setSelectedFieldOffice } = useAppointments();

  const mounted = useMounted();

  useEffect(() => {
    const zipCode = submittedSearchTerm.match(/\d{5}/) || [];
    let query;
    if (locateMe) {
      query = `loc=${locateMe.latitude},${locateMe.longitude}`;
    } else {
      query = `q=${zipCode || submittedSearchTerm}`;
    }
    setCurrentUrl(
      `${process.env.REACT_APP_WORDPRESS_API_URL}/dmv/v1/field-offices?${query}&distance=${DISTANCE}`
    );
  }, [submittedSearchTerm, locateMe]);

  useEffect(() => {
    setFetchingFieldOffices(true);
    if (currentUrl in cachedRequests) {
      setFieldOffices(cachedRequests[currentUrl]);
      setFetchingFieldOffices(false);
      return;
    }

    (async () => {
      const fieldOfficesResponse = await fetch(currentUrl);
      const newFieldOffices = await fieldOfficesResponse.json();
      if (mounted.current) {
        setFieldOffices(newFieldOffices);
        cachedRequests[currentUrl] = newFieldOffices;
        setFetchingFieldOffices(false);
      }
    })();
  }, [currentUrl, locateMe]);

  useEffect(() => {
    (async () => {
      const qmaticResponse = await fetch(
        `${process.env.REACT_APP_WORDPRESS_API_URL}/dmv/v1/appointment/available/?services[]=DL!b94ae07d48f4d0cff89b6fc0e0c9aea5fa2a47d11728311b7adccdef2c728`
      );
      setQmaticFieldOffices(await qmaticResponse.json());
    })();
  }, []);

  useEffect(() => {
    setSelectedFieldOffice(null);
    (async () => {
      const citiesResponse = await fetch(
        `${process.env.REACT_APP_WORDPRESS_API_URL}/dmv/v1/cities`
      );
      setCities(await citiesResponse.json());
    })();
  }, []);

  const { matchedLocations, totalItems } = useMatchedLocations(
    fieldOffices,
    qmaticFieldOffices,
    page
  );

  useAdvanceOnFieldOfficeSelection({ fieldOfficeWasSelected: didSelect });

  const locationsMapText = useMemo(
    () => ({
      nextPageTxt: t("screens-fieldOffice-locationsMap-nextPageTxt", "Next"),
      prevPageTxt: t(
        "screens-fieldOffice-locationsMap-prevPageTxt",
        "Previous"
      ),
      pageTxt: t("screens-fieldOffice-locationsMap-pageTxt", "Page"),
      paginationLabel: t(
        "screens-fieldOffice-locationsMap-paginationLabel",
        "Pagination navigation"
      ),
      locateBtnTxt: t(
        "screens-fieldOffice-locationsMap-locateBtnTxt",
        "Locate Me"
      ),
      locateBtnPurpose: t(
        "screens-fieldOffice-locationsMap-locateBtnPurpose",
        "locate and display locations near you"
      ),
      locateMeLabel: t(
        "screens-fieldOffice-locationsMap-locateMeLabel",
        "Find a location near me"
      ),
      locateMeHint: t(
        "screens-fieldOffice-locationsMap-locateMeHint",
        "Results based on detected device location"
      ),
      locateErrorTxt: t(
        "screens-fieldOffice-locationsMap-locateErrorTxt",
        "Sorry, we were not able to locate you."
      ),
      orText: t("screens-fieldOffice-locationsMap-orText", "or"),
      showingText: t("screens-fieldOffice-locationsMap-showingText", "Showing"),
      ofText: t("screens-fieldOffice-locationsMap-ofText", "of"),
      forText: t("screens-fieldOffice-locationsMap-forText", "for"),
      resultsText: t("screens-fieldOffice-locationsMap-resultsText", "results"),
      noResultsText: t(
        "screens-fieldOffice-locationsMap-noResultsText",
        "No results found, please try again, or use Locate Me to find results based on your current location."
      ),
      noResults: t(
        "screens-fieldOffice-locationsMap-noResults",
        "No Locations found."
      ),
      searchFieldLabel: t(
        "screens-fieldOffice-locationsMap-searchFieldLabel",
        "Search by Zip Code or City Name"
      ),
      searchPlaceholder: t(
        "screens-fieldOffice-locationsMap-searchPlaceholder",
        "Enter a zip code or city name."
      ),
      submitBtnTxt: t(
        "screens-fieldOffice-locationsMap-submitBtnTxt",
        "Search"
      ),
      resultBarShow: t(
        "screens-fieldOffice-locationsMap-resultBarShow",
        "Showing"
      ),
      resultBarResults: t(
        "screens-fieldOffice-locationsMap-resultBarResults",
        "results for"
      ),
      clearBtnTxt: t(
        "screens-fieldOffice-locationsMap-clearBtnTxt",
        "Clear search"
      ),
      selectedHeadline: t(
        "screens-fieldOffice-locationsMap-selectedHeadline",
        "Selected Location"
      ),
      btnTogglePrimary: t(
        "screens-fieldOffice-locationsMap-btnTogglePrimary",
        "List"
      ),
      btnToggleSecondary: t(
        "screens-fieldOffice-locationsMap-btnToggleSecondary",
        "Map"
      ),
      moreTxt: t("screens-fieldOffice-locationsMap-moreTxt", "More Details"),
      moreDetailsAriaDescribedBy: t(
        "screens-fieldOffice-locationsMap-moreDetailsAriaDescribedBy",
        "More details about location"
      ),
      selectLocation: t(
        "screens-fieldOffice-locationsMap-selectLocation",
        "Select Location"
      ),
      selectPurpose: t(
        "screens-fieldOffice-locationsMap-selectPurpose",
        "locate and display locations near you"
      ),
      locatedSuccess: t(
        "screens-fieldOffice-locationsMap-locatedSuccess",
        "Detected device location successful"
      ),
      loading: t("screens-fieldOffice-locationsMap-loading", "Loading"),
      loadingAnimation: t(
        "screens-fieldOffice-locationsMap-loadingAnimation",
        "loading spinner"
      ),
      yourLocation: t(
        "screens-fieldOffice-locationsMap-yourLocation",
        "your location"
      ),
      milesAway: t(
        "screens-fieldOffice-locationsMap-milesAway",
        "%d miles away"
      ),
    }),
    [t]
  );

  return (
    <RadvPageWrapper
      formProps={{ isForm: false }}
      pageHeading={t(
        "screens-FieldOffice-pageHeading",
        "Select a DMV Office for Your Appointment"
      )}
      fullWidth
      pageTitle={title}
    >
      <LocationsMap
        locations={matchedLocations}
        setLocations={setFieldOffices}
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        translations={locationsMapText}
        page={page}
        setPage={setPage}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        submittedSearchTerm={submittedSearchTerm}
        setSubmittedSearchTerm={setSubmittedSearchTerm}
        totalPages={Math.ceil(totalItems / PER_PAGE)}
        perPage={PER_PAGE}
        options={cities}
        selectedLocation={selectedFieldOffice}
        setSelectedLocation={(newFieldOffice) => {
          setSelectedFieldOffice(newFieldOffice);
          setDidSelect(true);
        }}
        isLoading={matchedLocations && fetchingFieldOffices}
        onLocateMe={setLocateMe}
        listActive={listActive}
        setListActive={setListActive}
      />
    </RadvPageWrapper>
  );
}

export default FieldOffices;
