import React, { useState } from "react";
import { Modal } from "@ca-dmv/modal";
import { isValidEmail } from "@ca-dmv-radv/utilities";
import { useTranslation } from "@ca-dmv-radv/translation";
import {
  updateApplicantEmail,
  FETCH_STATUS_IDLE,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_SENDING,
  FETCH_STATUS_ERROR,
  useApplication,
} from "@ca-dmv-radv/data";
import {
  InputText,
  Button,
  BUTTON_STYLE_SECONDARY,
  Alert,
  ALERT_SUCCESS,
  ALERT_WARNING,
  ICON_CHECK,
  ICON_WARNING,
} from "@ca-dmv/core";

export default function UpdateEmail({ onClose, modalTitle }) {
  const { t } = useTranslation();
  const { fetchApplication, application } = useApplication();
  const [email, setEmail] = useState(application.email || "");
  const [updatingEmail, setUpdatingEmail] = useState(FETCH_STATUS_IDLE);

  const postUpdatedEmail = async () => {
    let response;
    try {
      response = await updateApplicantEmail(email.trim());
    } catch (error) {
      setUpdatingEmail(FETCH_STATUS_ERROR);
    }

    if (response && response.success) {
      setUpdatingEmail(FETCH_STATUS_SUCCESS);
    } else {
      setUpdatingEmail(FETCH_STATUS_ERROR);
    }
  };

  const onClick = async () => {
    setUpdatingEmail(FETCH_STATUS_SENDING);
    await postUpdatedEmail();
    await fetchApplication();
  };

  const updateLabel = `${t("shared.updateLabel", "Update")} ${
    updatingEmail === FETCH_STATUS_SENDING ? "..." : ""
  }`;

  return (
    <Modal
      onClose={onClose}
      modalTitle={modalTitle}
      contentContainerClass="bp-lg:pl-30 bp-lg:pr-30 bp-lg:pb-30"
    >
      {FETCH_STATUS_ERROR === updatingEmail && (
        <Alert
          alertStyle={ALERT_WARNING}
          alertIcon={ICON_WARNING}
          iconClass="mr-8 min-width--30"
          containerClass="mt-24 "
          content={t(
            "errorMessages.updatingEmail",
            "There was an error updating your email address. Please try again."
          )}
          contentClass="text--xsm"
          alertAlt={t("shared.warning", "warning")}
        />
      )}

      {FETCH_STATUS_SUCCESS === updatingEmail && (
        <Alert
          alertStyle={ALERT_SUCCESS}
          alertIcon={ICON_CHECK}
          iconClass="mr-8 min-width--30"
          className="mr-8"
          content={t(
            "modal-updateEmail-success",
            "Your email address was successfully updated."
          )}
          alertAlt={t("shared.success", "successs")}
        />
      )}

      <p className="text--xmd text--lh-sm text--blue-dark-2">
        {t(
          "modal-updateEmail-subtitle",
          "This email address will be used to send you notifications regarding your application."
        )}
      </p>

      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <InputText
          containerClass="w--100 bp-md:max-width--350 mr-0 bp-md:mr-16 mb-16"
          inputClass="w--100 bp-md:max-width--400"
          label={t("modal-updateEmail-emailAddressLabel", "Your Email Address")}
          required
          requiredAriaLabel={t("shared.requiredLabel", "Required")}
          value={email}
          onChange={setEmail}
        />

        <div className="flex flex--align-center flex--justify-between mt-40">
          <Button
            label={t("shared.cancelLabel", "Cancel")}
            btnStyle={BUTTON_STYLE_SECONDARY}
            onClick={onClose}
          />
          {FETCH_STATUS_SUCCESS === updatingEmail ? (
            <Button
              label={t("shared.continueLabel", "Continue")}
              onClick={onClose}
            />
          ) : (
            <Button
              label={updateLabel}
              disabled={
                !isValidEmail(email) || FETCH_STATUS_SENDING === updatingEmail
              }
              onClick={onClick}
              isSubmit
            />
          )}
        </div>
      </form>
    </Modal>
  );
}
