import React, { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "@ca-dmv-radv/translation";
import { useApplication } from "../application-context-provider";
import {
  refreshDocumentUploads,
  updateConfirmResDocName,
  updateConnectionDoc,
  updateResidencyDocuments,
} from "../api";
import getResidencyStatus from "./getResidencyStatus";
import { useDocumentUploads } from "../document-uploads-context-provider";
import { useThrowErrorSavingData } from "../error-context-provider";

const ResidencyDocumentContext = React.createContext();

const defaultResidencyDocDetails = {
  catDocId: null,
  complete: false,
  nameOption: null,
  documentName: {
    suffix: null,
    lastName: null,
    firstName: null,
    middleName: null,
  },
  relatedCatDocId: null,
};

export function ResidencyDocumentContextProvider({
  children,
  initialResidencyDocuments: residencyDocuments = [],
  initialRelationshipDocuments: relationshipDocuments = [],
}) {
  const { t } = useTranslation();
  const { application, submitting } = useApplication();
  const { documentUploads } = useDocumentUploads();
  const throwErrorSavingData = useThrowErrorSavingData();
  const [isNavigating, setIsNavigating] = useState(false);
  const storedShowConnectingDocOptions = JSON.parse(localStorage.getItem('showConnectingDocOptions'));
  const [showConnectingDocOptions, setShowConnectingDocOptions] = useState(storedShowConnectingDocOptions || false);

  const [selectedResidencyDocuments, setSelectedResidencyDocuments] = useState([
    null,
    residencyDocuments.find(
      ({ id }) => id === application.documents.residency.doc1.catDocId
    ),
    residencyDocuments.find(
      ({ id }) => id === application.documents.residency.doc2.catDocId
    ),
  ]);

  const [residencyDocDetails, setResidencyDocDetails] = useState([
    null,
    application?.documents.residency.doc1 || defaultResidencyDocDetails,
    application?.documents.residency.doc2 || defaultResidencyDocDetails,
  ]);

  const [selectedRelationshipDocuments, setSelectedRelationshipDocuments] =
    useState([
      null,
      relationshipDocuments.find(
        ({ documentId }) =>
          documentId === application.documents.residency.doc1.relatedCatDocId
      ) || null,
      relationshipDocuments.find(
        ({ documentId }) =>
          documentId === application.documents.residency.doc2.relatedCatDocId
      ) || null,
    ]);

  const saveResidencyDocument = useCallback(
    async (docNumber, categorizedDocumentId) => {
      let success;
      try {
        ({ success } = await updateResidencyDocuments(
          { categorizedDocumentId },
          docNumber
        ));

        if (!success) {
          throw new Error();
        }
      } catch (error) {
        throwErrorSavingData({
          error,
          message: t(
            "app-error-savingData-residencyDocument",
            "There was an error saving your residency document selection. Try again."
          ),
        });
      }

      return success;
    },
    [throwErrorSavingData]
  );

  const postConnectionDoc = useCallback(
    async (docNumber, categorizedDocumentId) => {
      let success;
      try {
        ({ success } = await updateConnectionDoc(
          categorizedDocumentId,
          docNumber
        ));

        if (!success) {
          throw new Error();
        }
      } catch (error) {
        throwErrorSavingData({
          error,
          message: t(
            "app-error-savingData-document",
            "There was an error saving your document. Try again."
          ),
        });
      }

      return success;
    },
    [throwErrorSavingData]
  );

  const postDocumentDetails = useCallback(
    async (docNumber) => {
      let success;
      try {
        ({ success } = await updateConfirmResDocName({
          doc: docNumber,
          documentName: { ...residencyDocDetails[docNumber].documentName },
          nameOption: residencyDocDetails[docNumber].nameOption,
        }));

        if (!success) {
          throw new Error();
        } else {
          await refreshDocumentUploads();
        }
      } catch (error) {
        throwErrorSavingData({
          error,
          message: t(
            "app-error-savingData-document",
            "There was an error saving your document data. Try again."
          ),
        });
      }

      return success;
    },
    [residencyDocDetails, throwErrorSavingData]
  );

  const updateShowConnectingDocOptions = (value) => {
    setShowConnectingDocOptions(value);
    localStorage.setItem("showConnectingDocOptions", value);
  }

  return (
    <ResidencyDocumentContext.Provider
      value={useMemo(
        () => ({
          postConnectionDoc,
          postDocumentDetails,
          relationshipDocuments,
          residencyDocuments,
          residencyDocDetails,
          residencyStatus: getResidencyStatus(
            documentUploads,
            selectedResidencyDocuments,
            application,
            submitting
          ),
          saveResidencyDocument,
          selectedRelationshipDocuments,
          selectedResidencyDocuments,
          setSelectedRelationshipDocument: (
            docNumber,
            selectedRelationshipDocument
          ) => {
            setSelectedRelationshipDocuments(
              (prevSelectedRelationshipDocuments) => {
                const newSelectedRelationshipDocuments = [
                  ...prevSelectedRelationshipDocuments,
                ];
                newSelectedRelationshipDocuments[docNumber] =
                  selectedRelationshipDocument;
                return newSelectedRelationshipDocuments;
              }
            );
          },
          setSelectedResidencyDocument: (
            docNumber,
            selectedResidencyDocument
          ) => {
            setSelectedResidencyDocuments((prevSelectedResidencyDocuments) => {
              const newSelectedResidencyDocuments = [
                ...prevSelectedResidencyDocuments,
              ];
              newSelectedResidencyDocuments[docNumber] =
                selectedResidencyDocument;

              return newSelectedResidencyDocuments;
            });

            // Reset doc details when document changes.
            setResidencyDocDetails((prevResidencyDocDetails) => {
              const newResidencyDocDetails = [...prevResidencyDocDetails];
              newResidencyDocDetails[docNumber] = defaultResidencyDocDetails;

              return newResidencyDocDetails;
            });
          },
          setResidencyDocDetails: (docNumber, data) => {
            setResidencyDocDetails((docs) => {
              const newDocs = [...docs];
              newDocs[docNumber] = { ...newDocs[docNumber], ...data };
              return newDocs;
            });
          },
          isNavigating,
          setIsNavigating,
          showConnectingDocOptions,
          updateShowConnectingDocOptions,
        }),
        [
          application,
          documentUploads,
          isNavigating,
          showConnectingDocOptions,
          postDocumentDetails,
          relationshipDocuments,
          residencyDocDetails,
          saveResidencyDocument,
          selectedRelationshipDocuments,
          selectedResidencyDocuments,
          submitting,
        ]
      )}
    >
      {children}
    </ResidencyDocumentContext.Provider>
  );
}

export function useResidencyDocuments() {
  return useContext(ResidencyDocumentContext);
}
