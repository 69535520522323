import React from "react";
import { Button, BUTTON_STYLE_LINK } from "@ca-dmv/core";
import { useTranslation } from "@ca-dmv-radv/translation";
import { useNavigate } from "react-router-dom";

export default function ButtonBack({ buttonClass, callback }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goBack = () => {
    callback && callback();
    navigate(-1);
  };
  return (
    <Button
      label={t("shared.backLabel", "Back")}
      btnStyle={BUTTON_STYLE_LINK}
      buttonClass={buttonClass}
      onClick={goBack}
    />
  );
}
