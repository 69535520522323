import {
  useApplication,
  useDocumentUploads,
  useGoogleAnalytics,
  useNameChanges,
  useIdentityDocument,
} from "@ca-dmv-radv/data";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function useNameChangeNavigation({ setShowErrors, hasErrors }) {
  const { fetchApplication } = useApplication();
  const { fetchDocumentUploads } = useDocumentUploads();
  const { showIdentityNameOptions } = useIdentityDocument();
  const navigate = useNavigate();
  const { setAnalyticsAction } = useGoogleAnalytics();
  const { postNameChanges } = useNameChanges();

  const [navigating, setNavigating] = useState(false);

  const requestNavigation = useCallback(async () => {
    if (navigating) {
      return;
    }

    if (!showIdentityNameOptions) {
      navigate("/");
      return;
    }

    setShowErrors(hasErrors);

    if (hasErrors) {
      return;
    }

    setNavigating(true);

    // postNameChanges returns a callback that makes changes to application state that would
    // cause unwanted UI changes on this screen before the subsequent fetch functions are
    // called.
    const preNavigationCallback = await postNameChanges();

    const [nextApplication] = await Promise.all([
      fetchApplication(),
      fetchDocumentUploads(),
    ]);

    const success = await preNavigationCallback({
      application: nextApplication,
    });

    if (!success) {
      setNavigating(false);
      return;
    }

    setAnalyticsAction("Submit");
    navigate("/");
  }, [
    navigating,
    hasErrors,
    fetchApplication,
    fetchDocumentUploads,
    navigate,
    postNameChanges,
  ]);

  return useMemo(
    () => ({
      requestNavigation,
      navigating,
    }),
    [requestNavigation, navigating]
  );
}
