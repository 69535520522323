import React from "react";
import { NameFields } from "@ca-dmv-radv/components";
import { useTranslation } from "@ca-dmv-radv/translation";
import SelectDocument from "./SelectDocument";
import { Alert, ALERT_ERROR } from "@ca-dmv/core";
import { ICON_WARNING_RED } from "@ca-dmv/core/dist/js/icon";

export default function FormFields({
  firstName,
  setFirstName,
  middleName,
  setMiddleName,
  suffix,
  setSuffix,
  lastName,
  setLastName,
  errors,
  showErrors,
  focusedElementKey,
  setCatDocId,
  catDocId,
  nameChangeDocuments,
}) {
  const { t } = useTranslation();

  return (
    <>
      <NameFields
        namePrompt={t(
          "screens-confirm-name-whatNameWouldYouLikeToAdd",
          "What name would you like to add?"
        )}
        firstName={firstName}
        middleName={middleName}
        lastName={lastName}
        suffix={suffix}
        onChangeFirstName={setFirstName}
        onChangeMiddleName={setMiddleName}
        onChangeLastName={setLastName}
        onChangeSuffix={setSuffix}
        translatedErrors={errors}
        showErrors={showErrors}
        focusedElementKey={focusedElementKey}
      />
      <SelectDocument
        legend={t(
          "screens-confirm-name-certifyingDocumentLegend",
          "Which document certifies this name change?"
        )}
        documentList={nameChangeDocuments}
        onChange={(nextValue) => {
          setCatDocId(Number(nextValue));
        }}
        error={errors && showErrors && errors.certifyingDoc}
        documentId={catDocId}
        name="certifyingDoc"
        selectLabel={t(
          "screens-confirm-namme-selectDocument",
          "Select one of the documents below"
        )}
        required
        idField="documentId"
      />
    </>
  );
}