import { RadvPageWrapper } from '@ca-dmv-radv/components'
import { useTranslation } from '@ca-dmv-radv/translation'
import { Button } from '@ca-dmv/core'
import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'

export default function MobileSessionMdl ({ title }) {
  const { t } = useTranslation()
  const formProps = { isForm: false }
  const params = useParams()
  const navigate = useNavigate()
  return (
    <RadvPageWrapper
      fullWidth
      formProps={formProps}
      showRequiredFieldSeparator={false}
      pageTitle={title}
      pageHeading={t("mdl-landing-page-heading","Request Mobile Driver's License")}
    >
     
      <div className='container container--full'>
        <div className='flex flex--col max-width--300 '>
          {/* <h1>Request Mobile Driver's License</h1> */}
        </div>
        <div className='w--100 bp-md:flex bp-md:flex--row'>
          <div className='w--33 flex flex--col p-12'>
            <p className='has-large-font-size p-30'>
              {t("mdl-landing-page-sub-heading","Complete the process to obtain your Mobile Driver's License.")}
            </p>
            <Button
              buttonClass='mb-20 bp-md:mb-0'
              label='Start'
              onClick={() => {
                navigate(`/ms/mdl/${params?.token}`)
              }}
            />
          </div>
          <div className='w--66 flex flex--col pt-16 bp-md:pl-30'>
            <div className='container container--lg  pt-16 pb-16 pl-30 pr-16 bg--white border-radius--lg' style={{boxShadow: '0 4px 10px var(--c-gray)'}}>
              <h2>{t("mdl-landing-subtext","Before you begin:")}</h2>
              <p>
              {t("mdl-landing-paragraph-text1",`A link will be provided to you to complete your application.
                Please access this link from a mobile device.`)}
                
              </p>
              <div className='flex flex--row flex--nowrap'>
                <span className='h--100 text--white bg--blue-dark-1 pl-16 pr-16 pt-5 pb-5 mr-20 border-radius--full'>
                  1
                </span>
                <p>
                  {t("mdl-landing-paragraph-text2", `Gather your documents with help from the checklist before you
                  access the link.`)}
                  <p className='mt-5'>
                    {t("mdl-landing-paragraph-text3", `If you do not complete your application in one try, you will
                    be required to request a new link.`)}
                  </p>
                </p>
              </div>
              <div className='flex flex--row flex--nowrap'>
                <span className='h--100 text--white bg--blue-dark-1 pl-16 pr-16 pt-5 pb-5 mr-20 border-radius--full'>
                  2
                </span>
                <p>
                  {t("mdl-landing-paragraph-text4", `When ready, access the link via a mobile device and capture
                  the requested images. You will be required to capture 2 images
                  of yourself for verification.`)}
                </p>
              </div>
              <div className='flex flex--row flex--nowrap'>
                <span className='h--100 text--white bg--blue-dark-1 pl-16 pr-16 pt-5 pb-5 mr-20 border-radius--full'>
                  3
                </span>
                <p>{t("mdl-landing-paragraph-text5", `Complete the application online and upload your document.`)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </RadvPageWrapper>
  )
}
