import React from "react";
import { useTranslation } from "@ca-dmv-radv/translation";
import {
  Icon,
  ICON_UNDER_CONSTRUCTION,
  Button,
  BUTTON_STYLE_PRIMARY,
  ButtonWrapper,
} from "@ca-dmv/core";
import { RadvPageWrapper } from "@ca-dmv-radv/components";

export default function UnderMaintenance({ title }) {
  const { t } = useTranslation();
  const formProps = { isForm: false };

  return (
    <RadvPageWrapper
      fullWidth
      formProps={formProps}
      showRequiredFieldSeparator={false}
      pageTitle={title}
    >
      <div className="flex flex--col flex--align-center max-width--700 ml-auto mr-auto">
        <div className="mb-40">
          <Icon
            className="width--100"
            icon={ICON_UNDER_CONSTRUCTION}
            alt={t("screens-email-notification-img-alt", "Email Notification")}
          />
        </div>
        <h2 className="h3 text--med bp-md:text--lg text--700 text--center mb-24 bp-md:mb-48">
          {t(
            "screens-UnderMaintenance-heading",
            "We are performing maintenance"
          )}
        </h2>
        <div className="w--100 max-width--600 text--center">
          <p className="text--sm mb-0 text--center text--blue-dark-2 text--700">
            {t(
              "screens-UnderMaintenance-message",
              "Sorry for the inconvenience, and hope to see you again after we have completed our updates."
            )}
          </p>
        </div>
        <ButtonWrapper wrapperClass="flex--col-reverse mt-40 bp-md:mt-60">
          <Button
            label={t(
              "screens-Timeout-buttonLabel-ReturnToDMVHomePage",
              "Return to the DMV Homepage"
            )}
            btnStyle={BUTTON_STYLE_PRIMARY}
            buttonClass="mb-40 bp-md:mb-0 bp-md:mr-24"
            onClick={() => {
              window.location = process.env.REACT_APP_BACK_TO_DMV_LINK;
            }}
          />
        </ButtonWrapper>
      </div>
    </RadvPageWrapper>
  );
}
