import { useEffect, useRef, useState } from "react";
import { useNameChanges } from "@ca-dmv-radv/data";

const scrollToOffset = 15;

export default function useFocusableElement() {
  const [focusableElement, setFocusableElement] = useState(null);
  const [scrollToElement, setScrollToElement] = useState(null);

  const { nameChanges } = useNameChanges();

  const addAnotherNameChangeRef = useRef();

  // Focus focusable element.
  useEffect(() => {
    if (focusableElement) {
      focusableElement.focus();
    }
  }, [focusableElement]);

  // Scroll to scrollToElement.
  useEffect(() => {
    if (scrollToElement) {
      const elementPosition = scrollToElement.getBoundingClientRect().top;
      window.scrollTo({
        top: elementPosition + window.pageYOffset - scrollToOffset,
        behavior: "auto",
      });
      setScrollToElement(null);
    }
  }, [scrollToElement]);

  useEffect(() => {
    // trigger for "Have you had other legal names?"
    const addAnotherNameChange = addAnotherNameChangeRef.current;

    if (!addAnotherNameChange) {
      return;
    }

    const nextFocusableElement =
      addAnotherNameChange?.querySelector("fieldset") ||
      addAnotherNameChange ||
      null;
    const nextScrollToElement = addAnotherNameChange || null;

    if (nextFocusableElement) {
      nextFocusableElement.setAttribute("tabindex", "-1");
      setFocusableElement(nextFocusableElement);
    }

    if (nextScrollToElement) {
      setScrollToElement(nextScrollToElement);
    }
  }, [nameChanges]);

  return addAnotherNameChangeRef;
}
