import { useIdentityDocument, useResidencyDocuments } from "@ca-dmv-radv/data";
import {
  STATUS_READY,
  STATUS_REVIEW,
  STATUS_PREAPPROVED,
  STATUS_VERIFYING,
  STATUS_IN_PROGRESS,
  STATUS_SUBMITTING,
  STATUS_ERROR,
} from "@ca-dmv/core";

export const DASHBOARD_STATUS_DONE = "done";
export const DASHBOARD_STATUS_UNDER_REVIEW = "under-review";
export const DASHBOARD_STATUS_READY_TO_SUBMIT = "ready-to-submit";
export const DASHBOARD_STATUS_VERIFYING = "verifying";
export const DASHBOARD_STATUS_SUBMITTING = "submitting";

export default function useDashboardStatus() {
  const { identityStatus } = useIdentityDocument();
  const { residencyStatus } = useResidencyDocuments();

  if (identityStatus === "" || residencyStatus === "") {
    return null;
  }

  if (
    identityStatus === STATUS_IN_PROGRESS ||
    residencyStatus === STATUS_IN_PROGRESS
  ) {
    return null;
  }

  if (identityStatus === STATUS_ERROR || residencyStatus === STATUS_ERROR) {
    return null;
  }

  if (
    identityStatus === STATUS_VERIFYING ||
    residencyStatus === STATUS_VERIFYING
  ) {
    return DASHBOARD_STATUS_VERIFYING;
  }

  if (
    identityStatus === STATUS_PREAPPROVED ||
    residencyStatus === STATUS_PREAPPROVED
  ) {
    return DASHBOARD_STATUS_DONE;
  }

  if (identityStatus === STATUS_REVIEW || residencyStatus === STATUS_REVIEW) {
    return DASHBOARD_STATUS_UNDER_REVIEW;
  }

  if (identityStatus === STATUS_READY || residencyStatus === STATUS_READY) {
    return DASHBOARD_STATUS_READY_TO_SUBMIT;
  }

  if (
    identityStatus === STATUS_SUBMITTING ||
    residencyStatus === STATUS_SUBMITTING
  ) {
    return DASHBOARD_STATUS_SUBMITTING;
  }

  return null;
}
