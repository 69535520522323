import {
  STATUS_IN_PROGRESS,
  STATUS_VERIFYING,
  STATUS_ERROR,
  STATUS_READY,
  STATUS_REVIEW,
  STATUS_INCOMPLETE
} from "@ca-dmv/core";
import { isPendingConfirmationStatus } from "../dashboard/PresenceVerification/constants";

const checkStatusID = {
  7: STATUS_VERIFYING,
  1: STATUS_IN_PROGRESS,
  4: STATUS_ERROR,
  3:STATUS_REVIEW,
  6: STATUS_VERIFYING,
  2: STATUS_VERIFYING

}
export const showStatus = (documentUpload) => {
  if (documentUpload?.statusId === 7 && isPendingConfirmationStatus(documentUpload?.ATD)) {
    return STATUS_INCOMPLETE;
  }

  return checkStatusID[documentUpload?.statusId]
}