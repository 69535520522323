import { useEffect } from "react";

function PageNotFound() {
  useEffect(() => {
    const re = /^\/mdl/;
    re.test(window.location.pathname) ? window.location.replace("/mdl") : window.location.replace("/dv");
  }, []);

  return null;
}

export default PageNotFound;
