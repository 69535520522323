import React, { useState } from "react";
import { useTranslation } from "@ca-dmv-radv/translation";
import {
  Icon,
  ICON_EMAIL_NOTIFICATION,
  Button,
  BUTTON_STYLE_SECONDARY,
  ButtonWrapper,
  BUTTON_STYLE_LINK,
} from "@ca-dmv/core";
import { RadvPageWrapper } from "@ca-dmv-radv/components";
import { useApplication } from "@ca-dmv-radv/data";
import EmailDocument from "../dashboard/EmailDocument";

export default function UnderReview({ title, autoSubmit }) {
  const { t } = useTranslation();
  const formProps = { isForm: false };
  const { application } = useApplication();
  const [addEmail, setAddEmail] = useState(false);

  return (
    <RadvPageWrapper
      fullWidth
      formProps={formProps}
      showRequiredFieldSeparator={false}
      pageTitle={title}
    >
      <div className="flex flex--col flex--align-center max-width--700 ml-auto mr-auto">
        <div className="mb-40">
          <Icon
            icon={ICON_EMAIL_NOTIFICATION}
            alt={t("screens-email-notification-img-alt", "Email Notification")}
          />
        </div>
        {!autoSubmit && (
          <h2 className="h3 text--med bp-md:text--lg text--700 text--center mb-24 bp-md:mb-48">
            {t("screens-UnderReview-heading", "Documents submitted under review")}
          </h2>
        )}
        {autoSubmit && (
          <h2 className="h3 text--med bp-md:text--lg text--700 text--center mb-24 bp-md:mb-48">
            {t("screens-UnderReview-heading-autoSubmit", "Documents under review")}
          </h2>
        )}
        <div className="w--100 max-width--500 p-20 bp-md:p-30 bg--yellow-light-2 text--center mb-24 bp-md:mb-48">
          <p className="text--sm mb-0 text--center text--blue-dark-2 text--700">
            {t(
              "screens-UnderReview-confirmationCode",
              "This is your confirmation code:"
            )}
          </p>
          <p className="text--md text--700 text--blue-dark-1 mb-0">
            {application?.confirmationCode}
          </p>
        </div>
        {(application?.email && autoSubmit) && (
          <>
            <p className="text--center text--blue-dark-2">
              {t(
                "screens-FinalConfirmation-emailCopy-autoSubmit",
                "The confirmation code has been sent to:"
              )}
            </p>
            <p className="text--700 text--blue-dark-2">
              <strong>{application.email}</strong>
            </p>
          </>
        )}
        {(application?.email && !autoSubmit) && (
          <>
            <p className="text--center text--blue-dark-2">
              {t(
                "screens-FinalConfirmation-emailCopy",
                "For your convenience, an email with a copy of this page has been sent to:"
              )}
            </p>
            <p className="text--700 text--blue-dark-2">
              <strong>{application.email}</strong>
            </p>
          </>
        )}
        <ButtonWrapper wrapperClass="flex--col-reverse mt-40 bp-md:mt-60">
          <Button
            label={t("modal-Share-title", "Share a copy of this page")}
            btnStyle={BUTTON_STYLE_SECONDARY}
            buttonClass="mb-0 bp-md:mr-24"
            onClick={() => {
              setAddEmail(true);
            }}
          />
          <Button
            label={t(
              "screens-Timeout-buttonLabel-ReturnToDMVHomePage",
              "Return to the DMV Homepage"
            )}
            btnStyle={BUTTON_STYLE_LINK}
            buttonClass="mb-40 bp-md:mb-0 bp-md:mr-24"
            onClick={() => {
              setAddEmail(false);
              window.location = process.env.REACT_APP_BACK_TO_DMV_LINK;
            }}
          />
        </ButtonWrapper>
      </div>
      {addEmail && (
        <EmailDocument
          type="under-review"
          errorAlert={t(
            "errorMessages.addingEmail",
            "There was an error adding your email address. Please try again."
          )}
          onClose={() => {
            setAddEmail(false);
          }}
          actionButtonText={t("shared.continueLabel", "Continue")}
        />
      )}
    </RadvPageWrapper>
  );
}
