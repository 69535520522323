import React, { Fragment } from "react";
import { useTranslation } from "@ca-dmv-radv/translation";
import inlineDashedBorder from "./inlineDashedBorder";
import PDF from "./PDF";

function PreviewType({ type, dataUrl, name, index }) {
  const { t } = useTranslation();

  switch (type) {
    case "application/pdf":
      return <PDF dataUrl={dataUrl} />;
    case "image/heic":
      return (
        <span className="text-md">
          {t(
            "screens-ProofOfIdentity-Modal-Upload-heicNotSupported",
            "The uploaded .heic file cannot be previewed. Click Next to continue with upload."
          )}
        </span>
      );
    case "image/tiff":
    case "image/tif":
      return (
        <span className="text-md">
          {t(
            "screens-ProofOfIdentity-Modal-Upload-tiffNotSupported",
            "The uploaded .tif file cannot be previewed. Click Next to continue with upload."
          )}
        </span>
      );
    default:
      return <img src={dataUrl} alt={`${name} ${index + 1}`} />;
  }
}

export default function UploadPreviews({
  documentUploadFiles,
  documentUpload,
}) {
  return documentUploadFiles.map(({ dataUrl, error, type }, index) => (
    <Fragment key={dataUrl || `${documentUpload.id}-error-${index}`}>
      {error && <div>{error.message}</div>}
      {dataUrl && !error && (
        <figure
          className="flex p-10 pb-24 mb-16 bg--blue-light-3 border-radius--sm"
          style={inlineDashedBorder}
        >
          <PreviewType
            type={type}
            dataUrl={dataUrl}
            name={documentUpload.name}
            index={index}
          />
        </figure>
      )}
    </Fragment>
  ));
}
