import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf";
import React, { useEffect } from "react";
import { Trans } from "@ca-dmv-radv/translation";

export const ALLOWED_FILE_TYPES = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/tif",
  "image/tiff",
  "image/heic",
  "application/pdf",
];
const UNABLE_TO_PREVIEW = ["image/tif", "image/tiff", "image/heic"];

export const ONE_MEGABYTE = 1024;
export const MAX_FILE_SIZE_IN_MB = 5;
export const ASSEMBLE = 1024;
export const COPY = 16;
export const COPY_FOR_ACCESSIBILITY = 512;
export const FILL_INTERACTIVE_FORMS = 256;
export const MODIFY_ANNOTATIONS = 32;
export const MODIFY_CONTENTS = 8;
export const PRINT = 4;
export const PRINT_HIGH_QUALITY = 2048;

const PDF_PERMISSIONS = [
  ASSEMBLE,
  COPY,
  COPY_FOR_ACCESSIBILITY,
  FILL_INTERACTIVE_FORMS,
  MODIFY_ANNOTATIONS,
  MODIFY_CONTENTS,
  PRINT_HIGH_QUALITY,
  PRINT,
];

const fileReader = new FileReader();

const processBase64Content = (dataURI) => {
  const base64Marker = ";base64,";
  const base64Index = dataURI.indexOf(base64Marker) + base64Marker.length;

  return atob(dataURI.substring(base64Index));
};

const validateImageUpload = (file, content, callback, reject) => {
  const URL = window.URL || window.webkitURL;

  if (file) {
    // heic/tif/tiff images won't preview in the browser, so bail on validation
    if (UNABLE_TO_PREVIEW.includes(file.type)) {
      callback({
        name: file.name,
        size: file.size,
        type: file.type,
        dataUrl: content,
        original: file,
        numPages: null,
      });
      return;
    }

    const image = new Image();

    image.onload = () => {
      callback({
        name: file.name,
        size: file.size,
        type: file.type,
        dataUrl: content,
        original: file,
        numPages: null,
      });
    };

    image.onerror = () => {
      reject(new Error(`File ${file.name} is invalid.`));
    };

    image.src = URL.createObjectURL(file);
    return;
  }
  reject(new Error(`File upload failed. Please try again.`));
};

const onLoadEnd = ({ file, t }) =>
  new Promise((resolve, reject) => {
    const content = fileReader.result;

    if (file.type === "application/pdf") {
      pdfjsLib
        .getDocument({ data: processBase64Content(content) })
        .promise.then((pdf) => {
          pdf.getPermissions().then((permissions) => {
            if (
              permissions &&
              ((permissions.length > 0 &&
                JSON.stringify(PDF_PERMISSIONS.sort()) !==
                  JSON.stringify(permissions.sort())) ||
                permissions.length === 0)
            ) {
              reject(
                t(
                  "UploadManagement-pdf-error-restrictions-msg",
                  "We were not able to process the PDF file due to the restrictions. Please make sure you are selecting an unrestricted file and try again."
                )
              );
            } else {
              resolve({
                name: file.name,
                size: file.size,
                type: file.type,
                dataUrl: content,
                original: file,
                numPages: pdf.numPages,
              });
            }
          });
        })
        .catch((exc) => {
          let error;
          switch (exc.name) {
            case "PasswordException":
              error = (
                <Trans
                  i18nKey="UploadManagement-pdf-error-password-msg"
                  defaults="We were not able to process the protected PDF file you provided. Please make sure you are selecting an unprotected file and try again."
                />
              );
              reject(error);
              break;

            case "InvalidPDFException":
              error = (
                <Trans
                  i18nKey="UploadManagement-pdf-error-invalid-msg"
                  defaults="The PDF file you provided has an invalid or unsupported format. Please try again using a different file."
                />
              );
              reject(error);
              break;

            default:
              error = (
                <Trans
                  i18nKey="UploadManagement-pdf-error-generic-msg"
                  defaults="We were not able to process the PDF file you provided. Please make sure you are selecting an unprotected file and try again."
                />
              );
              reject(error);
              break;
          }
        });
    } else {
      validateImageUpload(file, content, resolve, reject);
    }
  });

/**
 * Converts uploaded files to dataURLs.
 *
 * @param {File[]} files
 * @returns {Promise<{}[]>}
 */
export const processFiles = ({ files, t }) =>
  new Promise((resolve, reject) => {
    const result = [];

    // Reject if any of the files does not have an allowed file type.
    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        reject(
          new Error(
            t(
              "screens-error-fileType",
              "The file type [{{fileType}}] you selected is invalid. Please make sure the file type matches one listed below.",
              {
                fileType: file.type,
              }
            )
          )
        );
        return;
      }
    }

    // Reject if any of the files is too large or too 0.
    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];
      if (file.size === 0) {
        reject(new Error(`File ${file.name} is too small.`));
        return;
      }

      const { size } = file;
      const fileMaximumSize =
        size && size > 0 ? size / ONE_MEGABYTE / ONE_MEGABYTE : size;
      if (fileMaximumSize > MAX_FILE_SIZE_IN_MB) {
        reject(
          new Error(`File size ${file.size} of ${file.name} is too large.`)
        );
        return;
      }
    }

    const processFile = (nextFiles, nextResult) => {
      if (nextFiles.length === 0) {
        resolve(nextResult);
        return;
      }

      const file = nextFiles.splice(0, 1)[0];

      fileReader.onloadend = async () => {
        try {
          result.push(await onLoadEnd({ file }));

          processFile(nextFiles, nextResult);
        } catch (e) {
          reject(e);
        }
      };

      fileReader.readAsDataURL(file);
    };

    processFile(files, result);
  });

export function usePDFWorker() {
  useEffect(() => {
    // PDF.js worker
    pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;
  }, []);
}

export { default as useABBYY, CAPTURING_PHOTOS_IDLE } from "./useABBYY";
export * from "./useABBYYCapture";
