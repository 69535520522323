import { useEffect, useMemo, useRef, useState } from "react";
import { useDocumentUploads } from "@ca-dmv-radv/data";
import { useMounted } from "@ca-dmv-radv/utilities";

const scrollToOffset = 27;

export default function useFocusableElement() {
  const mounted = useMounted();
  const {
    identityDocumentUploads,
    residencyDocumentUploads,
    currentFocusedDoc,
    hasStartedPoR,
    hasStartedPoI,
  } = useDocumentUploads();
  const [focusableElement, setFocusableElement] = useState(null);
  const [scrollToElement, setScrollToElement] = useState(null);

  const proofOfIdentityRef = useRef();
  const proofOfResidencyRef = useRef();
  const statusAlertRef = useRef();

  const getNextElement = (ref) => {
    const foundElement = ref.querySelector(
      ".js-doc-wrap .btn--cta, .js-doc-wrap .btn--link, .js-doc-wrap label"
    );
    return foundElement?.closest("fieldset") || foundElement || null;
  };

  // Focus focusable element.
  useEffect(() => {
    if (focusableElement) {
      focusableElement.focus();
    }
  }, [focusableElement]);

  // Scroll to scrollToElement with offset.
  useEffect(() => {
    if (scrollToElement) {
      const elementPosition = scrollToElement.getBoundingClientRect().top;
      window.scrollTo({
        top: elementPosition + window.pageYOffset - scrollToOffset,
        behavior: "auto",
      });
      // reset element to scroll to same element a 2nd time, if necessary
      setScrollToElement(null);
    }
  }, [scrollToElement]);

  useEffect(() => {
    const identityCount = identityDocumentUploads.length;
    const residencyCount = residencyDocumentUploads.length;
    const hasStartedUploadingDocs = identityCount > 0 || residencyCount > 0;

    let nextFocusableElement;
    let nextScrollToElement;

    if (!hasStartedUploadingDocs || !mounted.current) {
      return;
    }

    // keep focus in the active Proof Of section
    if (currentFocusedDoc) {
      const [id, index = 1] = currentFocusedDoc.split("|");
      const relatedDocuments = document
        .getElementById(id)
        .querySelectorAll(".js-doc-wrap");
      const currentFocusableElement = relatedDocuments[index - 1];

      // the focusable element needs to be the "document", not the button
      nextFocusableElement =
        currentFocusableElement?.querySelector(".btn--cta, .btn--link") &&
        currentFocusableElement;

      // check if related docs need to be uploaded
      if (!nextFocusableElement && relatedDocuments) {
        [...relatedDocuments]?.some((doc) => {
          const docHasActions = doc?.querySelector(".btn--cta, .btn--link");
          nextFocusableElement = docHasActions && doc;
          return nextFocusableElement;
        });
      }

      // check if related doc needs to be selected
      // this is only relevant for PoR
      if (!nextFocusableElement && relatedDocuments) {
        [...relatedDocuments]?.some((doc) => {
          const docHasSelect = doc?.querySelector("select");
          nextFocusableElement = docHasSelect && doc;
          return nextFocusableElement;
        });
      }

      if (nextFocusableElement) {
        nextScrollToElement = nextFocusableElement;
      }
    }

    // Check for focusable elements still in Proof of Identity
    if (identityCount >= 0 && !nextFocusableElement) {
      // if user has not viewed the PoI section, scroll there first
      if (hasStartedPoI) {
        nextFocusableElement = getNextElement(proofOfIdentityRef.current);
      } else {
        nextFocusableElement = document.getElementById("proof-of-identity");
      }

      if (nextFocusableElement) {
        nextScrollToElement = nextFocusableElement;
      }
    }

    // Check for focusable elements still in Proof of Residencey
    if (!nextFocusableElement) {
      // if user has not viewed the PoR section, scroll there first
      if (hasStartedPoR) {
        nextFocusableElement = getNextElement(proofOfResidencyRef.current);
      } else {
        nextFocusableElement = document.getElementById("proof-of-residency");
      }

      if (nextFocusableElement) {
        nextScrollToElement = nextFocusableElement;
      }
    }

    // Everything must be done, go look for the Status Alert and focus on that
    if (!nextFocusableElement && statusAlertRef.current) {
      nextFocusableElement = statusAlertRef.current.querySelector(".alert");

      if (nextFocusableElement) {
        nextScrollToElement = statusAlertRef.current;
      }
    }

    if (nextFocusableElement) {
      setFocusableElement(nextFocusableElement);
    }

    if (nextScrollToElement) {
      setScrollToElement(nextScrollToElement);
    }
  }, [identityDocumentUploads, residencyDocumentUploads]);

  return useMemo(
    () => ({
      statusAlertRef,
      proofOfIdentityRef,
      proofOfResidencyRef,
    }),
    []
  );
}
