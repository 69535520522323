import React, { useState, useEffect } from "react";
import {
  InputGroup,
  DatePicker,
  InputText,
  PATTERN_US_PASSPORT,
  PATTERN_US_PASSPORT_CARD,
} from "@ca-dmv/core";
import { useTranslation } from "@ca-dmv-radv/translation";
import { useFileUpload } from "@ca-dmv-radv/data";
import useVerificationFieldsValidation from "./useVerificationFieldsValidation";
import { isMobile } from "react-device-detect";
import { isPassPort, isPassportCard } from '../helpers/constants';

export default function PassportForm({ catDocId, isAbbyyFlow, docTypes }) {
  const { setShowInputError, setPassportFormData, passportFormData } = useFileUpload();
  const { t } = useTranslation();
  const { validateExpirationDate, isValidInputLength, isValidPassportForm } =
    useVerificationFieldsValidation();
  const [month, setMonth] = useState(passportFormData?.Passport_Expiration_Date?.split("-")?.[1] || '');
  const [day, setDay] = useState(passportFormData?.Passport_Expiration_Date?.split("-")?.[2] || '');
  const [year, setYear] = useState(passportFormData?.Passport_Expiration_Date?.split("-")?.[0]) || '';
  const [expDateErrors, setExpDateErrors] = useState({});
  const [numMismatchError, setNumMismatchError] = useState(false);
  const [passportNumberError, setPassportNumberError] = useState(false)

  const setData=({key, value}) => setPassportFormData({
    ...passportFormData,
    [key]: value,
  });

  useEffect(() => {
    if (!isAbbyyFlow && passportFormData) {
      changeHandler({key: "Passport_Number", value: passportFormData?.Passport_Number});
    }
  }, []);

  useEffect(() => {
    setData({ key: "Passport_Expiration_Date", value: `${year}-${month}-${day}`})
    if (isAbbyyFlow) {
      setExpDateErrors({});
      const isValid = validateExpirationDate(`${year}-${month}-${day}`);
      if (!isValid && year) {
        setExpDateErrors({
          year: t(
            "Passport-form-Passport-Expiration-invalid-date",
            "Passport expiration date must be at least 30 days from today and not more than 10 years from today."
          ),
        });
      }
    }
  }, [month, day, year]);

useEffect(() => {
  if(passportNumberError || expDateErrors.year){
    setShowInputError(true)
  }else if(isAbbyyFlow && (!isValidPassportForm(catDocId))){
    setShowInputError(true)
  } else {
    setShowInputError(false)
  }

}, [passportNumberError, numMismatchError, expDateErrors])

    const changeHandler = ({ key, value }) => {
        if(pattern.test(value) && isValidInputLength(value, catDocId)){
          setPassportNumberError(false)
        } else {
          setPassportNumberError(true)
        } 
          setData({key, value});
  };

  let formTitle;
  let label;
  let confirmLabel;
  let expLabel;
  let pattern;
  let minLength;
  let maxLength;
  let passportHint;
  let hint;
  if (isPassPort(catDocId, docTypes)) {
    formTitle = isAbbyyFlow ? 
    t(
      "screens-Dashboard-Passport-Legend",
      "Please provide your U.S. Passport Number and Expiration Date."
    ) :
    t(
      "screens-Dashboard-AIDPassport-Legend",
      "Please confirm your U.S. Passport Number and Expiration Date."
    ); 
    label = t(
      "screens-Dashboard-Passport-PassportNumberLabel",
      "Passport Number"
    );
    confirmLabel = t(
      "screens-Dashboard-Passport-ConfirmPassportNumberLabel",
      "Confirm Passport Number"
    );
    expLabel = t(
      "screens-Dashboard-Passport-PassportExpirationLabel",
      "Passport Expiration Date"
    );
    passportHint = t(
      "screens-Dashboard-Passport-PassportNumHint",
      "Passport numbers are between 6-9 characters"
    );
    pattern = PATTERN_US_PASSPORT;
    minLength = 6;
    maxLength = 9;
    hint = t(
      "screens-Dashboard-Passport-NumHint",
      "Passport numbers must match"
    );
  }
  if (isPassportCard(catDocId, docTypes)) {
    formTitle = t(
      "screens-Dashboard-PassportCard-Legend",
      "Please provide your U.S. Passport Card Number and Expiration Date."
    );
    label = t(
      "screens-Dashboard-PassportCard-PassportNumberLabel",
      "Passport Card Number"
    );
    confirmLabel = t(
      "screens-Dashboard-PassportCard-ConfirmPassportNumberLabel",
      "Confirm Passport Card Number"
    );
    expLabel = t(
      "screens-Dashboard-PassportCard-PassportExpirationLabel",
      "Passport Card Expiration Date"
    );
    passportHint = t(
      "screens-Dashboard-PassportCard-PassportNumHint",
      "Passport Card numbers begin with the letter C followed by 8 numbers"
    );
    pattern = PATTERN_US_PASSPORT_CARD
    minLength = 9;
    maxLength = 9;
    hint = t(
      "screens-Dashboard-PassportCard-NumHint",
      "Passport Card numbers must match"
    );
  }

  // Set numMisMatchError to true or false based on whether Passport_Number and Confirm_Passport_Number match if Passport Form has Confirm_Passport_Number field
  useEffect(() => {
    if(isAbbyyFlow) {
      setNumMismatchError(passportFormData.Passport_Number !== passportFormData.Confirm_Passport_Number);
    }
  }, [passportFormData.Passport_Number, passportFormData.Confirm_Passport_Number]);
  
  return (
    <InputGroup
      containerClass="mt-48"
      inputGroupClass="border p-24"
      legend={formTitle}
      legendClass="text--blue-dark-2 text--md text--700 text--lh-1 w--100"
      title={t(
        "screens-Dashboard-Passport-Title",
        "This information will be used for document verification"
      )}
      titleClass="text--blue-dark-2 mt-8 mb-16"
    >
      <InputText
        containerClass="w--100 mb-24"
        inputClass="text--uppercase"
        label={label}
        value={passportFormData.Passport_Number}
        onChange={(value) => {
          changeHandler({ key: "Passport_Number", value: value.toUpperCase() });
        }}
        pattern={pattern}
        minLength={minLength}
        maxLength={maxLength}
        hint={passportHint}
        hintClass="text--xxsm mb-0 mt-2"
        error={passportNumberError ? passportHint : null}
      />
      {isAbbyyFlow && (<InputText
        containerClass="w--100 mb-24"
        inputClass="text--uppercase"
        label={confirmLabel}
        value={passportFormData.Confirm_Passport_Number}
        onChange={(value) => {
          setData({ key: "Confirm_Passport_Number", value: value.toUpperCase() });
        }}
        minLength={minLength}
        pattern={pattern}
        maxLength={maxLength}
        hint={hint}
        onPaste={(e) => {
          e.preventDefault();
          return false;
        }}
        hintClass="text--xxsm mb-0 mt-2"
        error={numMismatchError && hint}
      />)}
      <DatePicker
        containerClass="w--100 mb-0 pb-0"
        legend={expLabel}
        legendClass="text--uppercase text--xxxsm"
        monthAsSelect={false}
        required
        monthLabel={t("screens-Dashboard-Passport-MonthLabel", "Month")}
        dayLabel={t("screens-Dashboard-Passport-DayLabel", "Day")}
        yearLabel={t("screens-Dashboard-Passport-YearLabel", "Year")}
        month={month}
        day={day}
        year={year}
        onMonthChange={(newMonth) => {
          setMonth(newMonth);
        }}
        onDayChange={(newDay) => {
          setDay(newDay);
        }}
        onYearChange={(newYear) => {
          setYear(newYear);
        }}
        showErrors={expDateErrors?.year}
        errorText={expDateErrors?.year}
        errors={expDateErrors}
      />
    </InputGroup>
  );
}
