import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "@ca-dmv-radv/translation";
import { Timeout as DesignSystemTimeout } from "@ca-dmv/core";

export default function Timeout({ onTimeout, timeoutInMS }) {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <DesignSystemTimeout
      timeoutInMS={timeoutInMS}
      youHaveThisMuchTimeMessage={t(
        "screens-timeout-youHaveThisMuchTimeMessage",
        "You have %s minutes remaining"
      )}
      youHaveThisMuchTimeMessageAlt={t(
        "screens-timeout-youHaveThisMuchTimeMessageAlt",
        "You have %s minutes remaining to complete this form"
      )}
      needMoreTimeText={t(
        "screens-timeout-needMoreTimeText",
        "I need more time"
      )}
      onTimeout={onTimeout}
      currentPage={pathname}
    />
  );
}
