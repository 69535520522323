import {
  STATUS_INCOMPLETE,
  STATUS_PREAPPROVED,
  STATUS_SUBMITTING,
  STATUS_IN_PROGRESS,
  STATUS_ERROR,
  STATUS_READY,
} from "@ca-dmv/core";
import {
  DOCUMENT_CATEGORY_IDENTITY,
  DOCUMENT_CATEGORY_NAME_CHANGE,
  APPLICATION_TYPE,
  DOCUMENT_CATEGORY_MDL_IDENTITY
} from "../constants";
import { getDocumentSectionStatus } from "../utils";
import { isRetryStatus } from "@ca-dmv-radv/screens/src/dashboard/PresenceVerification/constants";

export default function getIdentityStatus(
  documentUploads,
  selectedDocument,
  application,
  submitting,
  applicationType,
) {

  if(documentUploads !== undefined){
    if (isRetryStatus(documentUploads[1]?.[0].ATD)){
      return STATUS_ERROR;
      //make this status 4 "VERIFICATION FAILED"
    }
  
    // if (documentUploads[1]?.[0].ATD === "CNT"){
    //   return STATUS_READY;
    // }  
  }
  
  if (submitting) {
    return STATUS_SUBMITTING;
  }

  if (!selectedDocument) {
    return "";
  }

  const identityDocs = documentUploads[
    applicationType === APPLICATION_TYPE.MDL ? DOCUMENT_CATEGORY_MDL_IDENTITY : 
      DOCUMENT_CATEGORY_IDENTITY] || [];

  if (identityDocs[0]?.catDocId !== selectedDocument.id) {
    return STATUS_IN_PROGRESS;
  }

  if (application.status.IDENTITY === false) {
    return STATUS_INCOMPLETE;
  }

  if (application.status.DOCS_VERIFIED_DT && application.status.FINISHED) {
    return STATUS_PREAPPROVED;
  }

  const allDocs = [
    ...identityDocs,
    ...(documentUploads[DOCUMENT_CATEGORY_NAME_CHANGE] || []),
  ];

  return getDocumentSectionStatus(allDocs);
}
