import { useTranslation } from '@ca-dmv-radv/translation';
import { Alert, ALERT_WARNING, ICON_WARNING, Button, BUTTON_ICON_LEFT, BUTTON_STYLE_CTA, BUTTON_STYLE_SECONDARY, ICON_TAKE_PHOTO } from '@ca-dmv/core';
import { Modal } from '@ca-dmv/modal';
import { React } from 'react';
import { Markup } from "react-render-markup";
import { isIOS } from 'react-device-detect';

const imageStyle = {
  height: "150px",
};


export default function AIDDocumentCapture({
  documentName,
  onClose,
  onCapture,
  onConfirm,
  requiredPages,
  document,
}) {
  const { t } = useTranslation();

  const buttonLabel = (page) => {
    if (page === "front") {
      if (document.isFront) {
        return "Retake Front Photo";
      } else {
        return "Take Front Photo";
      }
    } else {
      if (document.isBoth) {
        return "Retake Photos";
      } else {
        return "Take Photos";
      }
    }
  };

  const warning = t(
    "screens-Dashboard-Modal-background-warning",
    "Important: Minimize glare by taking photos on a solid, contrasting background, and avoid low light. <b>Do not use flash.</b>"
  );

  const takePhoto = (page) => {
    document.page = page;
    document.accessCamera = true;
    document.retakeFront = false;
    document.retakeBoth = false;

    if (page === "front" && document.isFront) {
      document.isFront = false;
      document.frontImage = null;
      document.retakeFront = true;
    }
    if (page === "both" && document.isBoth) {
      document.isBoth = false;
      document.frontImage = null;
      document.backImage = null;
      document.retakeBoth = true;
    }

    return onCapture();
  };

  const confirmPhoto = () => {
    onConfirm()
  }
  return (
    <Modal
      onClose={onClose}
      modalTitle={t(
        "screens-Dashboard-Modal-RequiredPhotos-modalTitle",
        "Required Photos"
      )}
      contentContainerClass="bp-lg:pl-30 bp-lg:pr-30 bp-lg:pb-30"
    >
      <p className="text--xmd text--lh-sm text--blue-dark-2">
        {!document.multiplePages &&
          t(
            "screens-Dashboard-Modal-RequirePhotos-subtitle",
            "Please take a photo of your {{documentName}}",
            {
              documentName,
            }
          )}
        {document.multiplePages &&
          t(
            "screens-Dashboard-Modal-RequirePhotos-subtitle-both",
            "Please provide photos for both the front and back (in that order) of your {{documentName}}",
            {
              documentName,
            }
          )}
      </p>
      {(warning !== null && warning.trim()) && <Alert
        alertStyle={ALERT_WARNING}
        alertIcon={ICON_WARNING}
        alertAlt="warning"
      >
        <div>
          <Markup markup={warning} />
        </div>
      </Alert>}
      {isIOS && (
          <div>
              <Alert
                  alertStyle={ALERT_WARNING}
                  ariaLive="off"
                  containerClass="mt-24 mb-24"
                  content={t(
                      "screen-modal-presence-verification-ios-device-warning-documentCapture",
                      "Users running iOS 17.4.1 may experience issues capturing their photo. If you encounter this issue, please select the 'X' option and then try again."
                  )}
                  contentClass="text--xsm"
                  alertAlt="ios-warning"
              />
          </div>
        )}
      <ul className="list--clean p-0 m-0">
        {!document.multiplePages && (
          <li className="mt-16">
            <div className="flex flex--align-center flex--justify-center">
              <img
                className="mb-16"
                style={imageStyle}
                src={
                  document.isFront
                    ? document.frontImage?.dataUrl
                    : process.env.REACT_APP_IMAGE_PATHS +
                    "/img/icons/aid_camera_icon.svg"
                }
              />
            </div>

            <div className="flex flex--align-center flex--justify-center">
              <Button
                label={document.isFront ? "Retake Photo" : "Take Photo"}
                icon={ICON_TAKE_PHOTO}
                iconPosition={BUTTON_ICON_LEFT}
                btnStyle={BUTTON_STYLE_CTA}
                onClick={() => takePhoto("front")}
                iconAlt="Capture"
              />
            </div>
          </li>
        )}
        {document.multiplePages && (
          <>
            {requiredPages.map((page) => (
              <li key={page} className="mb-16">
                <div className="flex flex--align-center flex--justify-center">
                  {page === "front" && (
                    <img
                      className="mb-16"
                      style={imageStyle}
                      src={
                        document.isBoth
                          ? document.frontImage?.dataUrl
                          : process.env.REACT_APP_IMAGE_PATHS +
                          "/img/icons/aid_camera_icon.svg"
                      }
                    />
                  )}
                  {page === "back" && (
                    <img
                      className="mb-16"
                      style={imageStyle}
                      src={
                        document.isBoth
                          ? document.backImage?.dataUrl
                          : process.env.REACT_APP_IMAGE_PATHS +
                          "/img/icons/aid_camera_icon.svg"
                      }
                    />
                  )}
                </div>
                <div className="flex flex--align-center flex--justify-center">
                  {page.charAt(0).toUpperCase() + page.slice(1,) + " Photo"}
                </div>
              </li>
            ))}
            <div className="flex flex--align-center flex--justify-center">
              <Button
                label={buttonLabel("both")}
                icon={ICON_TAKE_PHOTO}
                iconPosition={BUTTON_ICON_LEFT}
                btnStyle={BUTTON_STYLE_CTA}
                onClick={() => takePhoto("both")}
                iconAlt="Capture"
              />
            </div>
        </>
      )}
      </ul>

      <div className="flex flex--align-center flex--justify-between mt-40">
        <Button
          label={t(
            "screens-Dashboard-Modal-TakePhotoWaiting-buttonCancelLabel",
            "Cancel"
          )}
          btnStyle={BUTTON_STYLE_SECONDARY}
          onClick={onClose}
        />
        <Button
          label={t(
            "screens-Dashboard-Modal-TakePhotoWaiting-buttonOkLabel",
            "Submit"
          )}
          onClick={() => confirmPhoto()}
          disabled={
            (document.multiplePages &&
              !document.isBoth) ||
            (!document.multiplePages && !document.isFront)
          }
        />
      </div>
    </Modal>
  )
}
