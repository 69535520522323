import axios from "axios";

export const defaultHeaders = {
  "Content-Type": "application/json",
  "accept-language": "en",
};

const HTTP = axios.create({
  baseURL: window.REACT_APP_API_URL,
  headers: defaultHeaders,
  withCredentials: true,
});

export default HTTP;
