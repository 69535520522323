import React from "react";
import { Trans, useTranslation } from "@ca-dmv-radv/translation";
import { RadvPageWrapper } from "@ca-dmv-radv/components";

export default function MDLNoVerification({ title }) {
  const { t } = useTranslation();
  const formProps = { isForm: false };

  return (
    <RadvPageWrapper
      fullWidth
      formProps={formProps}
      showRequiredFieldSeparator={false}
      pageTitle={title}
    >
      <div className="max-width--800">
        <h3 className="text--primary text--md text--blue-dark-2 text--700 mb-24">
            {t(
              "screens-MDL-NoVerification-subtitle",
              "No verification needed."
            )}
        </h3>
      </div>
    </RadvPageWrapper>
  );
}