import React from "react";
import { formatPhoneNumber } from "@ca-dmv-radv/utilities";
import { useTranslation } from "@ca-dmv-radv/translation";

export default function CustomerInfo({
  legalName,
  notificationMethod,
  telNumber,
}) {
  const { t } = useTranslation();

  return (
    <div className="w-100 bp-md:max-width--600 bg--blue-light-5 p-16 mb-24 print--pb-0 print--mb-10">
      <p className="text--gray-dark-2 text--700 text--xxsm text--uppercase mb-8">
        {t(
          "screens-FinalConfirmation-customerInformation",
          "Customer Information"
        )}
      </p>
      <p className="text--blue-dark-2 text--md text--700 mb-8">{legalName}</p>
      <div className="flex flex--col bp-sm:flex--row mb-0">
        <p className="text--blue-dark-2 text--sm mr-24 mb-0">
          {t("screens-FinalConfirmation-notify", "Notify via")}:{" "}
          {notificationMethod === "SMS" ? "Text Message" : "Phone Call"}
        </p>
        <p className="text--blue-dark-2 text--sm mb-0">
          {telNumber && telNumber}
        </p>
      </div>
    </div>
  );
}
