import React, { useState } from "react";
import { Modal } from "@ca-dmv/modal";
import { isValidEmail } from "@ca-dmv-radv/utilities";
import { useTranslation } from "@ca-dmv-radv/translation";
import {
  sendEmailSessionSave,
  sendEmailUnderReview,
  submitFinalEmail,
  FETCH_STATUS_IDLE,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_SENDING,
  FETCH_STATUS_ERROR,
} from "@ca-dmv-radv/data";
import {
  InputText,
  Button,
  BUTTON_STYLE_SECONDARY,
  Alert,
  ALERT_SUCCESS,
  ALERT_WARNING,
  ICON_CHECK,
  ICON_WARNING,
} from "@ca-dmv/core";

export default function EmailDocument({ type, onClose }) {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [sendingEmail, setSendingEmail] = useState(FETCH_STATUS_IDLE);

  const sendEmail = async () => {
    let response;
    if (FETCH_STATUS_IDLE !== sendingEmail) {
      return;
    }

    try {
      setSendingEmail(FETCH_STATUS_SENDING);

      switch (type) {
        case "under-review":
          response = await sendEmailUnderReview(email);
          break;
        default:
          response = await sendEmailSessionSave(email);
      }

      if (response.success) {
        setSendingEmail(FETCH_STATUS_SUCCESS);
      } else {
        setSendingEmail(FETCH_STATUS_ERROR);
      }
    } catch (e) {
      setSendingEmail(FETCH_STATUS_ERROR);
    }
  };

  // Reset modal state before closing
  const handleOnClose = () => {
    setSendingEmail(FETCH_STATUS_IDLE);
    onClose();
  };

  const continueLabel = `${t("shared.continueLabel", "Continue")} ${
    sendingEmail === FETCH_STATUS_SENDING ? "..." : ""
  }`;

  return (
    <Modal
      onClose={handleOnClose}
      modalTitle={t("modal-Share-title", "Share a copy of this page")}
      contentContainerClass="bp-lg:pl-30 bp-lg:pr-30 bp-lg:pb-30"
    >
      {FETCH_STATUS_ERROR === sendingEmail && (
        <Alert
          alertStyle={ALERT_WARNING}
          alertIcon={ICON_WARNING}
          iconClass="mr-8 min-width--30"
          containerClass="mt-24"
          content={t(
            "app-error-send-email",
            "There was an error sending to the email address provided. Please try again."
          )}
          contentClass="text--xsm"
          alertAlt="warning"
        />
      )}
      {FETCH_STATUS_SUCCESS === sendingEmail && (
        <Alert
          alertStyle={ALERT_SUCCESS}
          alertIcon={ICON_CHECK}
          iconClass="mr-8 min-width--30"
          className="mr-8"
          content={t(
            "screens-Dashboard-Modal-EmailDocument-success",
            "An email will be sent to {{email}}",
            { email }
          )}
          alertAlt={t("shared.success", "success")}
        />
      )}

      {FETCH_STATUS_SUCCESS !== sendingEmail && (
        <fieldset
          className="mb-0 pb-0"
          disabled={FETCH_STATUS_SENDING === sendingEmail}
        >
          <legend className="text--xmd text--400 text--lh-sm text--blue-dark-2">
            {t(
              "modal-emailDocument-enterEmailAddressLegend",
              "Enter an email address to share a copy of this page."
            )}
          </legend>
          <InputText
            containerClass="w--100"
            inputClass="w--100 bp-md:max-width--400 disabled:"
            label={t("shared.emaiAaddressLabel", "Email Address")}
            required
            requiredAriaLabel={t("shared.requiredLabel", "Required")}
            value={email}
            onChange={(newValue) => setEmail(newValue)}
          />
        </fieldset>
      )}

      <div className="flex flex--align-center flex--justify-between mt-40">
        <Button
          label={t("shared.cancelLabel", "Cancel")}
          btnStyle={BUTTON_STYLE_SECONDARY}
          onClick={handleOnClose}
        />
        {FETCH_STATUS_SUCCESS === sendingEmail ? (
          <Button
            label={t("shared.continueLabel", "Continue")}
            onClick={handleOnClose}
          />
        ) : (
          <Button
            label={continueLabel}
            disabled={
              !isValidEmail(email) || FETCH_STATUS_SENDING === sendingEmail
            }
            onClick={() => {
              sendEmail();
            }}
            isSubmit
          />
        )}
      </div>
    </Modal>
  );
}
