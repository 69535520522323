import { DOC_UPLOAD_STATUS_IDS } from "@ca-dmv-radv/data";
import { useTranslation } from "@ca-dmv-radv/translation";
import React from "react";

export default function ErrorExplanation({ documentUpload }) {
  const { t } = useTranslation();

  if (
    documentUpload.statusId !== DOC_UPLOAD_STATUS_IDS.FAILED &&
    documentUpload.statusId !== DOC_UPLOAD_STATUS_IDS.UNDER_REVIEW
  ) {
    return null;
  }

  if (documentUpload.statusId === DOC_UPLOAD_STATUS_IDS.FAILED) {
    const errorCodes = documentUpload.validationData?.errorCodes || [];
    // remove duplicate codes
    const uniqueErrorCodes = errorCodes.filter(
      (code, index) => errorCodes.indexOf(code) === index
    );
    return [
      ...uniqueErrorCodes.map((errorCode) => errorCode.includes("AID") ? t(`${errorCode}`) : t(`ABBYY-errorCode-${errorCode}`)),
      documentUpload.validationData?.freeFormMessage,
    ]
      .filter((message) => message)
      .map((error) => <span key={error} className="show">{error}</span>);
  }

  return [
    <>
      <span className="text--blue-dark-2 mb-16 show">
        {t(
          "screens-Dashboard-PendingManualReview",
          "Pending manual review (this could take up to 2 business days)."
        )}
      </span>
      <span className="text--blue-dark-2 mb-0">
        {t(
          "screens-Dashboard-PendingManualReviewExplanation",
          "After review, if there is a problem with the document you uploaded, you will be able to make changes here before visiting a DMV location."
        )}
      </span>
    </>,
  ];
}
