import { useTranslation } from "@ca-dmv-radv/translation";
import { useMemo } from "react";

export default function useNameChangeErrors({
  needAnotherNameChange,
  firstName,
  lastName,
  nameChanges,
  catDocId,
}) {
  const { t } = useTranslation();

  return useMemo(() => {
    const validationErrors = {};

    if (needAnotherNameChange !== true && needAnotherNameChange !== false) {
      validationErrors.needAnotherNameChange = t(
        "errorMessages.needAnotherNameChangeMissing",
        "Please select if you need to add another name change."
      );
    }
    
    if (needAnotherNameChange) {
      if (nameChanges.length >= 2 && nameChanges[1].catDocId !== null) {
        // No name entries are needed for the initial name change doc which certifies the application name.

        if (!(lastName.trim())) {
          validationErrors.lastName = t(
            "errorMessages.certifyingDocumentMissing",
            "Please enter your last name."
          );
        }
      }

      if (!catDocId) {
        validationErrors.certifyingDoc = t(
          "errorMessages.certifyingDocumentMissing",
          "Please select which document certifies this name."
        );
      }
    }

    return validationErrors;
  }, [firstName, lastName, catDocId, needAnotherNameChange, t, nameChanges]);
}
