import React, { useState, useMemo } from "react";
import {
  useDocumentUploads,
  useGoogleAnalytics,
  useResidencyDocuments,
} from "@ca-dmv-radv/data";
import { useTranslation } from "@ca-dmv-radv/translation";
import { useNavigate } from "react-router-dom";
import { useSortedDocuments } from "@ca-dmv-radv/utilities";
import { SelectMenu, Button, Separator } from "@ca-dmv/core";
import Document from "./Document";
import getDocumentUploadStatusFlag from "./helpers/getDocumentUploadStatusFlag";

// Change of Address, DL 933 Form can only be used once
const singleUseResidencyDocumentIds = [29, 34];

export default function ResidencyDoc({
  isAwaitingIdentityDocUpload,
  index,
  errors,
  showErrors,
  focusedElementKey,
  residencyCatDocData,
  connectingResidencyCatDocData,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setAnalyticsAction } = useGoogleAnalytics();

  const {
    setSelectedResidencyDocument,
    residencyDocDetails,
    selectedResidencyDocuments,
    saveResidencyDocument,
    residencyDocuments,
    isNavigating,
    setIsNavigating,
    updateShowConnectingDocOptions,
  } = useResidencyDocuments();
  const {
    relationshipDocumentUploads,
    residencyDocumentUploads,
    setShouldPingDocumentUploads,
    setCurrentFocusedDoc,
    setHasStartedPoR,
  } = useDocumentUploads();

  const [navigating, setNavigating] = useState(false);

  const residencyDocumentUpload = residencyDocumentUploads.find(
    ({ doc }) => doc === index
  );
  const selectedDocument = selectedResidencyDocuments[index];
  const documentDetails = residencyDocDetails[index];
  const relationshipDocs =
    relationshipDocumentUploads.filter(({ doc }) => doc === index) || [];

  const determineShouldShowSelect = () => {
    const noSelectedNameOption = documentDetails.nameOption === null;
    const docDoesNotMatch = residencyDocumentUpload?.catDocId !== selectedDocument?.id;
    const nameOptionIncomplete = !documentDetails.complete;
    return noSelectedNameOption || !selectedDocument || docDoesNotMatch || nameOptionIncomplete; 
  };
  const shouldShowSelect = determineShouldShowSelect();

  const filteredResidencyDocuments = useMemo(() => {
    // prevents selecting the single use documents more than once
    const restrictedResidencyDocumentIds = selectedResidencyDocuments
      .map((doc) => {
        // prevents the document from disappearing as you select it
        if (selectedDocument) {
          return singleUseResidencyDocumentIds.includes(doc?.id) &&
            selectedDocument.id !== doc?.id
            ? doc?.id
            : null;
        }
        return singleUseResidencyDocumentIds.includes(doc?.id) ? doc?.id : null;
      })
      .filter(Boolean);

    return residencyDocuments.filter(
      (doc) => !restrictedResidencyDocumentIds.includes(doc.id)
    );
  }, [
    selectedDocument,
    selectedResidencyDocuments,
    residencyDocuments,
    singleUseResidencyDocumentIds,
    t,
  ]);

  const selectResidencyDocumentOptions = useSortedDocuments({
    documents: filteredResidencyDocuments,
    t,
  });

  const documentLabel = t(
    `screens-ProofOfResidency-${index === 1 ? "first" : "second"}DocumentLabel`,
    `${index === 1 ? "First" : "Second"} Document`
  );
  return (shouldShowSelect) ? (
    <div className="js-doc-wrap flex flex--col bp-md:flex--row  flex--nowrap flex--justify-center flex--align-center bp-sm:flex--align-start bp-md:flex--align-end bp-md:flex--justify-start">
      <SelectMenu
        containerClass="bp-md:mb-5 w--100 bp-md:w--auto max-width--400"
        selectClass="w--100 bp-md:max-width--400"
        label={documentLabel}
        selectPlaceholder={t(
          "screens-ProofOfResidency-selectResidencyPlaceholder",
          "Select Proof of Residency Document"
        )}
        disabled={isAwaitingIdentityDocUpload}
        options={selectResidencyDocumentOptions}
        selectedValue={selectedDocument?.documentId}
        onChange={(newValue) => {
          setSelectedResidencyDocument(
            index,
            residencyDocuments.find(
              (doc) => doc.documentId === Number(newValue)
            )
          );
        }}
        error={errors && showErrors && errors[`relationshipDocs${index}`]}
        hasFocus={
          focusedElementKey && focusedElementKey === `relationshipDocs${index}`
        }
      />

      {Boolean(selectedDocument) && (
        <div>
          <Button
            buttonClass="mt-24 bp-md:ml-24"
            label={t("shared.continueLabel", "Continue")}
            isLoading={navigating}
            disabled={isNavigating}
            onClick={async () => {
              if (navigating) {
                return;
              }

              setIsNavigating(true);
              setNavigating(true);
              setShouldPingDocumentUploads(false);
              setAnalyticsAction(`Submit Residency Document ${index}`);

              const success = await saveResidencyDocument(
                index,
                selectedDocument.id
              );

              setCurrentFocusedDoc(`proof-of-residency|${index}`);
              setHasStartedPoR(true);

              if (success) {
                updateShowConnectingDocOptions(true);
                setIsNavigating(false);
                navigate(`/residency/doc${index}`);
              } else {
                setShouldPingDocumentUploads(true);
                setIsNavigating(false);
                setNavigating(false);
              }
            }}
          />
        </div>
      )}
    </div>
  ) : (
    <>
      <p className="mb-24 text--gray-dark-2 text--xxxsm text--700 text--uppercase">
        {documentLabel}
      </p>
      <Document
        isCancelable
        status={getDocumentUploadStatusFlag(residencyDocumentUpload)}
        onCancelDocument={() => {
          setSelectedResidencyDocument(index, null);
        }}
        documentUpload={residencyDocumentUpload}
        containerClass="js-doc-wrap"
      />
      {relationshipDocs.map((doc, i) => (
        <div className="bp-md:ml-30" key={`relationship-doc${doc.id}`}>
          <Separator
            heading={t(
              "screens-ProofOfResidency-documentSeparatorLabel",
              "Connecting Document for {{selectedDocument}}",
              {
                selectedDocument: selectedDocument?.name,
              }
            )}
            containerClass="mt-40 mb-8"
          />
          <Document
            containerClass={
              i !== relationshipDocs.length - 1
                ? "mb-30 js-doc-wrap"
                : "mb-0 js-doc-wrap"
            }
            documentUpload={doc}
            status={getDocumentUploadStatusFlag(doc)}
            isCancelable
            onSelectDifferentDocument={() => {
              updateShowConnectingDocOptions(true);
              navigate(`/residency/doc${index}`);
            }}
            selectDifferentDocumentLabel={t(
              "screens-Dashboard-changeLabel",
              "Select a Different Document"
            )}
          />
        </div>
      ))}
    </>
  );
}
