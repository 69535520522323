import { React } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "@ca-dmv-radv/translation";
import {
  Icon,
  ICON_EMPTY_DESK,
  ICON_CLOCK,
  Button,
  BUTTON_STYLE_LINK,
  ButtonWrapper,
} from "@ca-dmv/core";
import { RadvPageWrapper } from "@ca-dmv-radv/components";
import { APPLICATION_TYPE } from "@ca-dmv-radv/data";


export default function Timeout({ title, applicationType }) {
  const { t } = useTranslation();
  const formProps = { isForm: false };

  return (
    <RadvPageWrapper
      fullWidth
      formProps={formProps}
      showRequiredFieldSeparator={false}
      pageTitle={title}
    >
      <div className="flex flex--col flex--align-center">
        <div className="mb-40">
          {!isMobile && (
            <Icon
              icon={ICON_EMPTY_DESK}
              alt={t(
                "screens-Timeout-img-alt",
                "Empty room with desk with a laptop and coffee on it."
              )}
            />
          )}
          {isMobile && (
            <Icon icon={ICON_CLOCK} alt={t("screens-clock-img-alt", "clock")} />
          )}
        </div>
        <h2 className="h3 text--xmd bp-sm:text--med bp-md:text--xlg text--700 text--center mb-24 bp-md:mb-48">
          {t("screens-Timeout-heading", "Your Session Has Expired")}
        </h2>
        <p className="text--sm mb-0 text--center max-width--400">
          {t(
            "screens-Timeout-closedMessage",
            "To keep your information secure, we closed your session after a period of inactivity."
          )}
        </p>
        {applicationType === APPLICATION_TYPE.DV && (
          <ButtonWrapper wrapperClass="flex--col-reverse mt-40 bp-md:mt-60">
          <Button
            label={t(
              "screens-Timeout-buttonLabel-ReturnToDMVHomePage",
              "Return to the DMV Homepage"
            )}
            btnStyle={BUTTON_STYLE_LINK}
            buttonClass="mb-40 bp-md:mb-0 bp-md:mr-40"
            onClick={() => {
              window.location = process.env.REACT_APP_PORTAL_URL;
            }}
          />
          <Button
            label={t(
              "screens-Timeout-buttonLabel-ReturnToApplication",
              "Return to Application"
            )}
            onClick={() => {
              window.location = process.env.REACT_APP_EDL_URL;
            }}
          />
        </ButtonWrapper>
        )}
      </div>
    </RadvPageWrapper>
  );
}
