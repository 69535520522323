/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
import { RadvPageWrapper } from "@ca-dmv-radv/components";
import {
  APPLICATION_TYPE,
  DOCUMENT_CATEGORY_MDL_IDENTITY,
  getMDLCategorizedDocumentById,
  useApplication,
  useDocumentUploads,
  useIdentityDocument,
} from "@ca-dmv-radv/data";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "@ca-dmv-radv/translation";
import addNexisScript from "../dashboard/helpers/addNexisScript";
import useFocusableElement from "../dashboard/helpers/useFocusableElement";
import { generateUrl } from "../dashboard/PresenceVerification/constants";
import ProofOfIdentity from "../dashboard/ProofOfIdentity";
import ProofOfDriversLicense from "../dashboard/ProofOfDriversLicense";

const DynamicDashboard = ({ applicationType }) => {
  const { documentUploads, useDocumentUploadPing } = useDocumentUploads();
  const { t } = useTranslation();
  const { application } = useApplication();
  const [categorizedDocumentData, setCategorizedDocumentData] = useState({
    identity: null,
    nameChanges: {},
    residency: {},
    connectingResidency: {},
  });
  const navigate = useNavigate();
  const { proofOfIdentityRef, proofOfDriversLicenseRef } = useFocusableElement();
  const { selectedIdentityDocument } = useIdentityDocument();

  useDocumentUploadPing();

  const sessionId = application?.lnSessionId;
  addNexisScript(sessionId);

  const fetchCategorizedDocumentData = async (categorizedDocumentId) => {
    await getMDLCategorizedDocumentById(categorizedDocumentId).then(
      (response) => {
        categorizedDocumentData[DOCUMENT_CATEGORY_MDL_IDENTITY] = response.data;
        setCategorizedDocumentData({ ...categorizedDocumentData });
      }
    );
  };

  useEffect(async () => {
    if (selectedIdentityDocument?.id) {
      await fetchCategorizedDocumentData(Number(selectedIdentityDocument.id));
    }
  }, [selectedIdentityDocument]);

  useEffect(() => {
    const url = generateUrl(application, documentUploads);

    if (url && url !== "/document-upload") {
      navigate(`../${url}`);
    }
  }, [documentUploads]);

  return (
    <>
      <RadvPageWrapper
        formProps={{
          isForm: false,
        }}
        pageTitle={t("screens-mdl-title", "Page Mobile Driver's License")}
        showRequiredFieldSeparator={applicationType === APPLICATION_TYPE.VDL ? false : true}
      >
        {applicationType === APPLICATION_TYPE.MDL && (
          <p className="text--blue-dark-2 max-width--700 mb-40 bp-md:mb-48">
            {t(
              "screens-mdl-sentence1",
              "Select the document type from the menu below, then follow the prompts to take a photo of the document and provide the requested information."
            )}
          </p>
        )}
        {documentUploads?.[7]?.[0] && (
          <div ref={proofOfDriversLicenseRef} id="proof-of-drivers-license">
            <ProofOfDriversLicense />
          </div>
        )}
        {applicationType === APPLICATION_TYPE.MDL && (
          <div ref={proofOfIdentityRef} id="proof-of-identity">
            <ProofOfIdentity
              identityCatDocData={categorizedDocumentData.identity}
              nameChangeCatDocData={categorizedDocumentData.nameChanges}
              isMdl
            />
          </div>
        )}
      </RadvPageWrapper>
    </>
  );
};

export default DynamicDashboard;
