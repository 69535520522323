import HTTP, { defaultHeaders } from "./http";
import errorHandler from "../errors/error-handler";

/**
 * Helper function to get data from the REST API
 *
 * @param {string} route
 * @param {any} data
 * @returns {Promise<object>}
 */
export default function getData(route, headers = null) {
  return HTTP.get(route, {
    headers: { ...defaultHeaders, ...headers },
  })
    .then((response) => response.data)
    .catch((e) => {
      return errorHandler(e);
    });
}
