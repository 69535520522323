import React, { Fragment } from "react";
import classNames from "classnames";
import {
  StatusContainer,
  Alert,
  ALERT_WARNING,
  useFocusedKey,
  STATUS_IN_PROGRESS,
  STATUS_VERIFYING,
  STATUS_ERROR,
  STATUS_INCOMPLETE,
  STATUS_REVIEW,
  STATUS_SUBMITTING,
  STATUS_READY,
} from "@ca-dmv/core";
import {
  useApplication,
  useIdentityDocument,
  useResidencyDocuments,
  useNameChanges
} from "@ca-dmv-radv/data";
import { Trans, useTranslation } from "@ca-dmv-radv/translation";
import ResidencyDoc from "./ResidencyDoc";

export default function ProofOfResidency({ showErrors, errors, residencyCatDocData, connectingResidencyCatDocData }) {
  const {
    application: { poBox },
  } = useApplication();
  const { residencyStatus } = useResidencyDocuments();
  const { identityStatus } = useIdentityDocument();
  const { t } = useTranslation();
  const { focusedElementKey } = useFocusedKey();
  const { noDocsOption } = useNameChanges();

  const showSubheading = [
    "",
    STATUS_IN_PROGRESS,
    STATUS_VERIFYING,
    STATUS_ERROR,
    STATUS_INCOMPLETE,
  ].includes(residencyStatus);

  const isAwaitingIdentityDocUpload = ["", STATUS_IN_PROGRESS].includes(
    identityStatus
  );

  const isAwaitingNameChangeDocUpload = noDocsOption===1;

  const subHeadingClass = classNames("max-width--600 text--blue-dark-2");

  const showVerificationMessage = [STATUS_VERIFYING, STATUS_REVIEW].includes(
    residencyStatus
  );

  const showPOBoxMessage =
    poBox && ["", STATUS_IN_PROGRESS, STATUS_ERROR].includes(residencyStatus);

  return (
    <StatusContainer
      containerClass="mb-0 max-width--900"
      heading={
        <h3 className="mb-24 text--primary">
          {t(
            "screens-ProofOfResidency-legend",
            "Proof of California Residency"
          )}
        </h3>
      }
      subHeading={
        <Trans
          i18nKey="screens-ProofOfResidency-subLegend"
          defaults="<p1>Two separate documents are required for proof of California residency. Documents can be photocopies.</p1><p2>Once your documents have been uploaded or photographed, you will not be able to select a different document.</p2>"
          components={{
            p1: <span className="show mb-16" />,
            p2: <span className="show mb-0" />,
          }}
        />
      }
      showSubheading={showSubheading}
      subHeadingClass={subHeadingClass}
      status={
        residencyStatus !== STATUS_SUBMITTING ? residencyStatus : STATUS_READY
      }
    >
      {showVerificationMessage && (
        <Alert
          alertStyle={ALERT_WARNING}
          ariaLive="off"
          containerClass="mt-24 mb-24 bp-md:max-width--500 bp-lg:max-width--600"
          hasBorder={false}
        >
          <p className="mb-0">
            {t(
              "screens-Dashboard-verificationMessage",
              "You will receive an email when your documents have been verified. This can take up to 2 business days."
            )}
          </p>
        </Alert>
      )}

      {(isAwaitingIdentityDocUpload || isAwaitingNameChangeDocUpload) && (
        <Alert
          alertStyle={ALERT_WARNING}
          ariaLive="off"
          containerClass="mt-24 mb-24 bp-md:max-width--500 bp-lg:max-width--600"
          hasBorder={false}
        >
          <p className="mb-0">
            {t(
              "screens-Dashboard-UploadIdentityDocs",
              "Please upload your identity documents before proceeding to this section."
            )}
          </p>
        </Alert>
      )}

      {[1, 2].map((index) => (
        <Fragment key={`residency-doc${index}`}>
          <ResidencyDoc
            index={index}
            errors={errors}
            showErrors={showErrors}
            status={residencyStatus}
            focusedElementKey={focusedElementKey}
            isAwaitingIdentityDocUpload={isAwaitingIdentityDocUpload || isAwaitingNameChangeDocUpload}
          />
          {index === 1 && showPOBoxMessage && (
            <Alert
              alertStyle={ALERT_WARNING}
              ariaLive="off"
              containerClass="mt-12 mb-12 bp-md:max-width--500 bp-lg:max-width--600"
              hasBorder={false}
            >
              <p className="mb-0">
                {t(
                  "screens-Dashboard-PO-BoxAlert",
                  "Proof of a physical address is required. A P.O. Box can be used as a mailing address to receive your REAL ID, but your residency document must display a P.O. Box and physical address."
                )}
              </p>
            </Alert>
          )}
          {index === 1 && (
            <hr className="mt-24 bp-md:mt-40 mt-24 bp-md:mb-40" />
          )}
        </Fragment>
      ))}
    </StatusContainer>
  );
}
