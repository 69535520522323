const formatTime = (time) => {
  const stringTime = time.replace(/\D/g, "");
  let timeArray;
  if (stringTime.length === 3) {
    timeArray = [stringTime.substring(0, 1), stringTime.substring(1, 3)];
  } else if (stringTime.length === 4) {
    timeArray = [stringTime.substring(0, 2), stringTime.substring(2, 4)];
  }
  const hours =
    timeArray[0] > 12
      ? parseInt(timeArray[0] - 12, 10)
      : parseInt(timeArray[0], 10);
  const minutes = timeArray[1];
  const ampm = timeArray[0] > 11 ? "p.m." : "a.m.";
  return `${hours}:${minutes} ${ampm}`;
};

export default formatTime;
