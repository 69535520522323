import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { sendSms } from './api';

const SMSContext = React.createContext();

export const SMS_IDLE = "idle";
export const SMS_SENDING = "sending";
export const SMS_SUCCESS = "success";
export const SMS_ERROR = "error";

export function SMSContextProvider({ children }) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [documentId, setDocumentId] = useState(0);
  const [tokenExpiration, setTokenExpiration] = useState(0);
  const [shouldSendSMS, setShouldSendSMS] = useState(false);
  const [sendingSMS, setSendingSMS] = useState(SMS_IDLE);
  const [hasSentSMS, setHasSentSMS] = useState(false);

  useEffect(() => {
    (async () => {
      if (!shouldSendSMS) {
        return;
      }

      let success;
      let message;
      let data;

      setSendingSMS(SMS_SENDING);

      try {
        ({ success, message, data } = await sendSms(phoneNumber, documentId));

        if (success === true) {
          if (data != null && data.tokenExpiration != null) {
            setTokenExpiration(data.tokenExpiration);
          }

          setSendingSMS(SMS_SUCCESS);
        } else {
          setSendingSMS(SMS_ERROR);
        }
      } catch ({ response }) {
        setSendingSMS(SMS_ERROR);
      }

      setHasSentSMS(true);
      setShouldSendSMS(false);
    })();
  }, [shouldSendSMS, setShouldSendSMS]);

  const resetModal = useCallback(() => {
    setSendingSMS(SMS_IDLE);
    setHasSentSMS(false);
  }, []);

  const value = useMemo(
    () => ({
      hasSentSMS,
      phoneNumber,
      tokenExpiration,
      sendingSMS,
      setPhoneNumber,
      setDocumentId,
      setShouldSendSMS,
      resetModal,
    }),
    [
      phoneNumber,
      tokenExpiration,
      sendingSMS,
      setPhoneNumber,
      setDocumentId,
      setShouldSendSMS,
      hasSentSMS,
      resetModal,
    ]
  );

  return <SMSContext.Provider value={value}>{children}</SMSContext.Provider>;
}

export function useSMS() {
  return useContext(SMSContext);
}
