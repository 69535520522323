
export default function LoadingIndicator({message, overlayStyles}){
let style={height:"100%", width:"100%",
display:"flex", alignItems:"center", justifyContent:"center"};

if(overlayStyles){
    style={...style, overlayStyles}
}
    return (<div id="overlay" className="presence-spinner" style={style}>
    <div aria-live="polite" className="flex flex--align-center flex--justify-center p-72 bp-md:mh--400">
      <div aria-live="polite" className="flex flex--align-center flex--justify-center p-72 bp-md:mh--400">
        <div className="drunken-square bg--white border--lg border--solid mr-12 border--blue bg--white" aria-hidden="true">
          <span className="drunken-square__fill-up width--100 h--100 show pos--top pos--left bg--blue"></span>
        </div>
        <p className="text-700 mb-0 text--blue-dark-2">{message||"Loading..."}</p>
      </div>
    </div>
  </div>)
}