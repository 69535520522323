import React from 'react'
import { RadvPageWrapper } from '@ca-dmv-radv/components'
import { useApplication } from "@ca-dmv-radv/data";
import MDLForm from './MDLForm';

const MDLHome = () => {
  const { application: { application } } = useApplication();

  const HeaderText = ({title, value}) => {
   return( 
   <>
      <p className="text--blue-dark-2 max-width--700 mb-24">{`${title}: ${value}`}</p>
    </>
   )}

  return (
    <RadvPageWrapper
    formProps={{
      isForm: false,
    }}
    >
      <HeaderText title="Name" value={`${application?.name?.firstName}  ${application?.name?.lastName}`}/>
      <HeaderText title="Date Of Birth" value={`${application?.dob}`}/>
      <MDLForm />
      </RadvPageWrapper>
  )
}

export default MDLHome
