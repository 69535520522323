import { useEffect, useMemo, useState } from "react";
import { getOfficeById } from "../api";

/**
 * Fetches data from /catalogs/office/:officeId.
 *
 * @returns {Object}
 */
export default function useOffices() {
  const [officeId, setOfficeId] = useState(null);
  const [fetchingOffice, setFetchingOffice] = useState(false);
  const [offices, setOffices] = useState(null);

  // Fetch office.
  useEffect(() => {
    if (fetchingOffice || !officeId) {
      return;
    }

    if (officeId in offices) {
      return;
    }

    setFetchingOffice(true);

    (async () => {
      const fetchedOffice = await getOfficeById(officeId);

      setOffices((previousValue) => ({
        ...previousValue,
        [officeId]: fetchedOffice,
      }));
    })();
  }, [officeId, fetchingOffice]);

  return useMemo(
    () => ({
      office: offices?.[officeId],
      officeId,
      offices,
      fetchingOffice,
      setOfficeId,
    }),
    [offices, fetchingOffice, officeId]
  );
}
