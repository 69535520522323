import React, { useEffect, useState } from "react";
import { Button } from "@ca-dmv/core";
import { createPortal } from "react-dom";
import { useTranslation } from "@ca-dmv-radv/translation";
import { useApplication } from "@ca-dmv-radv/data";
import { setUpToggle } from "@ca-dmv-radv/utilities";
import { useLocation } from "react-router-dom";

function LogoutButton() {
  const { t } = useTranslation();

  const { setSaveAndExit, saveAndExitApplication } = useApplication();

  return (
    <div className="nav-menu__item">
      <Button
        buttonClass="logout btn btn--navlink"
        label={t("shared.logout", "Log out")}
        onClick={async () => {
          setSaveAndExit(true);
          await saveAndExitApplication(true);
        }}
      />
    </div>
  );
}

export default function Logout({ authenticatedRoutes = [] }) {
  const { pathname } = useLocation();
  const [wasSetUp, setWasSetUp] = useState(false);

  const isAuthenticated = Boolean(
    authenticatedRoutes.find(
      ({ path }) => path === pathname
    )
  );

  useEffect(() => {
    const mainNavRoot = document.getElementById("main-nav");
    if (!isAuthenticated && mainNavRoot || pathname==="/log-out") {
      mainNavRoot.classList.add("hide");
    }

    if (!mainNavRoot || wasSetUp) {
      return;
    }

    setWasSetUp(true);

    if (isAuthenticated) {
      mainNavRoot.classList.remove("hide");
      setUpToggle();
    }
  }, [pathname, isAuthenticated, wasSetUp]);

  if (isAuthenticated && pathname !== "/log-out") {
    return createPortal(<LogoutButton />, document.getElementById("nav-menu"));
  }

  return null;
}
