import { RadvPageWrapper } from '@ca-dmv-radv/components';
import { useDocumentUploads } from '@ca-dmv-radv/data';
import React, { useState } from 'react';

import PresenceVerfication from './PresenceVerification';

function PresenceVerificationLanding({ title, program }) {
  const formProps = { isForm: false };
  const [showOptInOptOut, setOptInOptOut] = useState(true);

  const onHandleOptinOptOutClose = () => {
    setOptInOptOut(false);
  };

  const { useDocumentUploadPing, setShouldPingDocumentUploads } =
    useDocumentUploads();

  useDocumentUploadPing();

  return (
    <RadvPageWrapper
      fullWidth
      formProps={formProps}
      showRequiredFieldSeparator={false}
      pageTitle={title}
    >
      <div className="flex flex--col flex--align-center">
        {showOptInOptOut && (
          <PresenceVerfication
            onClose={onHandleOptinOptOutClose}
            setShouldPingDocumentUploads={setShouldPingDocumentUploads}
            isLanding={true}
            program={program}
          ></PresenceVerfication>
        )}
      </div>
    </RadvPageWrapper>
  );
}

export default PresenceVerificationLanding;
