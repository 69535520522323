import HTTP, { defaultHeaders } from "./http";
import errorHandler from "../errors/error-handler";

/**
 * Helper function to post data to the REST API
 *
 * @param {string} route
 * @param {any} data
 * @returns {Promise<object>}
 */
export default async function postData(route, data = null, headers = {}) {
  let response;
  try {
    response = await HTTP.post(route, data, {
      headers: { ...defaultHeaders, ...headers },
    });
    return response?.data;
  } catch (e) {
    return errorHandler(e);
  }
}
