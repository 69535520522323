/**
 * @param {string} routeUrl
 * @param {Object.<string, string>} params
 * @return {string}
 */
const getParamUrl = (routeUrl, params) => {
  if (!params) return routeUrl;
  let url = routeUrl;

  // Replace all parameter values in URL
  Object.keys(params).forEach((key) => {
    url = url.replace(`:${key}`, params[key]);
  });

  return url;
};

export default getParamUrl;
