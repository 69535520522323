import React from "react";
import {
  InputGroup,
  SelectMenu,
  InputText,
  CheckboxItem,
  CheckboxGroup,
  PATTERN_NUMBERS_ONLY,
  PATTERN_ALPHA_NUMERIC_SPACE_HYPHEN_APOSTROPHE,
} from "@ca-dmv/core";
import { useTranslation } from "@ca-dmv-radv/translation";

const genders = [
  { key: 0, label: "Choose...", value: "" },
  { key: 1, label: "M", value: "M" },
  { key: 2, label: "F", value: "F" },
  { key: 3, label: "X", value: "X" },
  { key: 4, label: "U", value: "U" },
  { key: 5, label: "(Blank)", value: "(Blank)" },
];

export default function BirthCerificateForm({
  states,
  counties,
  changeHandler,
  data,
}) {
  const { t } = useTranslation();

  return (
    <InputGroup
      containerClass="mt-48"
      inputGroupClass="border p-24"
      legend={t(
        "screens-Dashboard-BirthCertificate-Legend",
        "Please enter the information below exactly as it appears on your birth certificate."
      )}
      legendClass="text--blue-dark-2 text--md text--700 text--lh-1 w--100"
      title={t(
        "screens-Dashboard-BirthCertificate-Title",
        "This information will be used for document verification"
      )}
      titleClass="text--blue-dark-2 mt-8 mb-16"
    >
      <div className="w--100 bp-md:flex bp-md:flex--row bp-md:flex--nowrap mb-0 bp-md:mb-24">
        {states && (
          <SelectMenu
            containerClass="w--100 mb-16 bp-md:mb-0 bp-md:mr-24"
            label={t(
              "screens-Dashboard-BirthCertificate-StateLabel",
              "State of Birth"
            )}
            options={[
              { value: "", label: t("shared.choose", "Choose...") },
              ...states,
            ]}
            selectedValue={data.State_Of_Birth}
            onChange={(value) =>
              changeHandler({ key: "State_Of_Birth", value })
            }
          />
        )}
        <InputText
          containerClass="w--100 mb-16 bp-md:mb-0"
          label={t(
            "screens-Dashboard-BirthCertificate-FileNumLabel",
            "State File Number"
          )}
          value={data.State_File_Number}
          onChange={(value) =>
            changeHandler({ key: "State_File_Number", value })
          }
          disabled={data.State_File_Nbr_Chk === "true"}
          required={data.State_File_Nbr_Chk === "false"}
          hint={t(
            "screens-Dashboard-BirthCertificate-FileNumHint",
            "13 digits maximum, numbers only"
          )}
          hintClass="text--xxsm mb-0 mt-2"
          pattern={PATTERN_NUMBERS_ONLY}
          maxLength="13"
        />
      </div>

      <CheckboxGroup
        containerClass="mt-0 mb-24 p-0 w--100 bp-md:max-width--600 text-xs"
        legend={t(
          "screens-Dashboard-BirthCertificate-NoFileNum",
          "I do not have a state file number on my birth certificate"
        )}
        showLegend={false}
        required={false}
      >
        <CheckboxItem
          label={t(
            "screens-Dashboard-BirthCertificate-NoFileNum",
            "I do not have a state file number on my birth certificate"
          )}
          labelSpanClass="text--xsm"
          checked={data.State_File_Nbr_Chk === "true"}
          onChange={(value) => {
            const stringValue = value ? "true" : "false";
            changeHandler({ key: "State_File_Nbr_Chk", value: stringValue });
          }}
          required={false}
        />
      </CheckboxGroup>

      <div className="w--100 bp-md:flex bp-md:flex--row bp-md:flex--nowrap mb-0 bp-md:mb-24">
        {counties && data.State_Of_Birth === "CA" && (
          <SelectMenu
            containerClass="w--100 mb-16 bp-md:mb-0 bp-md:mr-24"
            label={t(
              "screens-Dashboard-BirthCertificate-CountyLabel",
              "County of Birth"
            )}
            options={[
              { value: "", label: t("shared.choose", "Choose...") },
              ...counties,
            ]}
            selectedValue={data.County_Of_Birth}
            onChange={(value) =>
              changeHandler({ key: "County_Of_Birth", value })
            }
            required
          />
        )}
        <InputText
          containerClass="w--100 mb-16 bp-md:mb-0"
          label={t(
            "screens-Dashboard-BirthCertificate-RegNumLabel",
            "Local Registration Number"
          )}
          value={data.Registrar_Number}
          onChange={(value) =>
            changeHandler({ key: "Registrar_Number", value })
          }
          disabled={data.Registrar_Nbr_Chk === "true"}
          required={data.Registrar_Nbr_Chk === "false"}
          hint={t(
            "screens-Dashboard-BirthCertificate-RegNumHint",
            "13 digits maximum, numbers only"
          )}
          hintClass="text--xxsm mb-0 mt-2"
          pattern={PATTERN_NUMBERS_ONLY}
          maxLength="13"
        />
      </div>

      <CheckboxGroup
        containerClass="mt-0 mb-0 mb-24 p-0 w--100 bp-md:max-width--600 text-xs"
        legend={t(
          "screens-Dashboard-BirthCertificate-NoRegNum",
          "I don't have a registrar number on my birth certificate"
        )}
        showLegend={false}
        required={false}
      >
        <CheckboxItem
          label={t(
            "screens-Dashboard-BirthCertificate-NoRegNum",
            "I don't have a registrar number on my birth certificate"
          )}
          labelSpanClass="text--xsm"
          checked={data.Registrar_Nbr_Chk === "true"}
          onChange={(value) => {
            const stringValue = value ? "true" : "false";
            changeHandler({ key: "Registrar_Nbr_Chk", value: stringValue });
          }}
          required={false}
        />
      </CheckboxGroup>

      <div className="w--100 bp-md:flex flex--col bp-md:flex--row bp-md:flex--nowrap mb-0 bp-md:mb-24">
        <InputText
          containerClass="w--100 mb-16 bp-md:mb-0 bp-md:mr-24"
          label={t(
            "screens-Dashboard-BirthCertificate-MaidenNameLabel",
            "Mother's Maiden Name"
          )}
          value={data.Mother_Maiden_Name}
          onChange={(value) =>
            changeHandler({ key: "Mother_Maiden_Name", value })
          }
          pattern={PATTERN_ALPHA_NUMERIC_SPACE_HYPHEN_APOSTROPHE}
          required={false}
        />
        <SelectMenu
          containerClass="w--100 mb-16 bp-md:mb-0"
          label={t(
            "screens-Dashboard-BirthCertificate-GenderLabel",
            "Gender At Birth"
          )}
          options={genders}
          selectedValue={data.Gender}
          onChange={(value) => changeHandler({ key: "Gender", value })}
          required={false}
        />
      </div>
    </InputGroup>
  );
}
