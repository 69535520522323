import React from "react";
import { useTranslation } from "@ca-dmv-radv/translation";

export default function Location({ fieldOffice, needsAccommodations }) {
  const { t } = useTranslation();

  return (
    <div className="w-100 bp-md:max-width--600 bg--blue-light-5 p-16 mb-24 print--pb-0 print--mb-10">
      <p className="text--gray-dark-2 text--700 text--xxsm text--uppercase mb-8">
        {t("screens-FinalConfirmation-location", "Location")}
      </p>
      <p className="text--blue-dark-2 text--sm bp-sm:text--md text--700 mb-8">
        {fieldOffice?.title?.rendered}
      </p>
      <p className="text--blue-dark-2 text--sm mb-0">
        {fieldOffice?.meta.dmv_field_office_street}
      </p>
      <p className="text--blue-dark-2 text--sm mb-0">{`${fieldOffice?.meta.dmv_field_office_city}, CA ${fieldOffice?.meta.dmv_field_office_zipcode}`}</p>
      {needsAccommodations && (
        <p className="text--blue-dark-2 text--sm mr-24 mt-12 mb-0 text--700">
          {t(
            "screens-FinalConfirmation-adaRequested",
            "ADA compliant service counter requested"
          )}
        </p>
      )}
    </div>
  );
}
