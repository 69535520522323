import React, { useCallback, useMemo, useState } from "react";
import { formatName } from "@ca-dmv-radv/utilities";
import { useApplication } from "../application-context-provider";
import {
  useIdentityDocument,
  useOnIdentityDocumentChange,
} from "../identity-document-context-provider";
import NameChangesContext from "./NameChangesContext";
import usePostNameChanges from "./usePostNameChanges";
import usePostConfirmName from "./usePostConfirmName";
import { parseReceivedNameChanges } from "./parseReceivedNameChanges";
import useFetchNameChanges from "./useFetchNameChanges";

const defaultNameChange = {
  name: "",
  firstName: "",
  middleName: "",
  lastName: "",
  suffix: "",
  order: 0,
  catDocId: null,
};

/**
 * Wrapper for setting individual fields within the nameChanges state.
 */
const generateNameSetter = (field, setState) => (index, value) => {
  setState((previousState) => {
    const newState = [...previousState];

    if (!(index in newState)) {
      newState[index] = { ...defaultNameChange };
    }

    newState[index][field] = value;
    return newState;
  });
};

/**
 * Determines the initial nameMatches state.
 */
const getNameMatchesFromApplicationData = ({
  applicationIdentity,
  selectedIdentityDocument,
}) => {
  if (applicationIdentity.catDocId === selectedIdentityDocument?.id) {
    return applicationIdentity.isApplicantName === true ||
      applicationIdentity.isApplicantName === false
      ? applicationIdentity.isApplicantName
      : null;
  }

  return null;
};

export function NameChangesContextProvider({
  children,
  nameChanges: initialNameChanges = [],
  initialNameChangeDocuments: nameChangeDocuments = [],
}) {
  const { application } = useApplication();
  const { selectedIdentityDocument } = useIdentityDocument();

  /**
   * Tracks the user's name change history.
   * @type {Array<Object>}
   */
  const [nameChanges, setNameChanges] = useState(initialNameChanges || []);

  /**
   * Tracks whether the user has name change documents they can provide.
   * @type {boolean|null}
   */
  const [hasNameChangeDocs, setHasNameChangeDocs] = useState(() => {
    if (application.documents.identity.hasNameChangeDocs === null) {
      return null;
    }

    return !!application.documents.identity.hasNameChangeDocs;
  });

  /**
   * Tracks the option the user has chosen regarding name change docs.
   * @type {Number|null}
   */
  const [noDocsOption, setNoDocsOption] = useState(
    application.documents.identity.noNameChangeOption
  );

  /**
   * Tracks whether the user's name matches what's on their identity document.
   * @type {boolean|null}
   */
  const [nameMatches, setNameMatches] = useState(() =>
    getNameMatchesFromApplicationData({
      applicationIdentity: application.documents.identity,
      selectedIdentityDocument,
    })
  );

  /**
   * Tracks the currently selected option for nameMatches
   * @type {boolean|null}
   */
  const [selectedNameMatchesOption, setSelectedNameMatchesOption] = useState(null);

  /**
   * Previously saved name change IDs deleted in this session.
   */
  const [deletedNames, setDeletedNames] = useState([]);

  /**
   * Build name string from application data
   */
  const nameOnDocument = formatName({
    firstName: application?.application?.name?.firstName,
    middleName: application?.application?.name?.middleName,
    lastName: application?.application?.name?.lastName,
    suffix: application?.application?.name?.suffix,
  });

  const { postNameChanges } = usePostNameChanges({
    hasNameChangeDocs,
    nameChanges,
    noDocsOption,
    setNameChanges,
    deletedNames,
  });

  const { postConfirmName } = usePostConfirmName({
    nameChanges,
    nameMatches,
    selectedNameMatchesOption,
  });

  const fetchNameChanges = useFetchNameChanges({ setNameChanges });

  const clearNameChange = (index) => {
    setNameChanges((previousNameChanges) => {
      const nextNameChanges = [...previousNameChanges];
      nextNameChanges[index] = { ...defaultNameChange };
      return nextNameChanges;
    });
  }

  /**
   * Reset nameMatches state when selectedIdentityDocument changes. This change
   * remains unsaved until the user proceeds through the name change flow.
   */
  useOnIdentityDocumentChange(
    (selectedIdentityDocumentId, previousSelectedIdentityDocumentId) => {
      if (
        previousSelectedIdentityDocumentId &&
        selectedIdentityDocumentId !== previousSelectedIdentityDocumentId
      ) {
        setNameMatches(null);
        setNameChanges(parseReceivedNameChanges([], application));
        setHasNameChangeDocs(null);
        setNoDocsOption(null);
        clearNameChange(0);
      }
    }
  );

  const addNameChangeDocument = useCallback((addedNameChange) => {
    const { isApplicationName, ...nameChange } = addedNameChange;

    // If the document certifies the application name, we only need to set the catDocId
    // on the existing item in the nameChanges array.
    if (isApplicationName) {
      setNameChanges((previousNameChanges) => {
        const newNameChanges = [...previousNameChanges];
        newNameChanges[newNameChanges.length - 1].catDocId =
          addedNameChange.catDocId;
        return newNameChanges;
      });

      return;
    }

    setNameChanges((previousNameChanges) => {
      let newNameChange = { ...defaultNameChange, ...nameChange };
      let nextNameChanges = [...previousNameChanges];

      newNameChange = {
        ...newNameChange,
        order: previousNameChanges.length - 1,
      };

      newNameChange.name = formatName(newNameChange);

      nextNameChanges = [
        ...nextNameChanges.slice(0, -1),
        newNameChange,
        nextNameChanges[nextNameChanges.length - 1],
      ].map((item, index) => ({
        ...item,
        order: index + 1,
      }));

      return nextNameChanges;
    });
  }, []);

  return (
    <NameChangesContext.Provider
      value={useMemo(
        () => ({
          addNameChangeDocument,
          clearNameChange,
          fetchNameChanges,
          hasNameChangeDocs,
          nameChangeDocuments,
          nameChanges,
          nameMatches,
          selectedNameMatchesOption,
          nameOnDocument,
          noDocsOption,
          postNameChanges,
          postConfirmName,
          removeNameChange: (nameChangeToRemove) => {
            setNameChanges((previousNameChanges) => {
              return previousNameChanges
                .filter(
                  (nameChange) => nameChangeToRemove.order !== nameChange.order
                )
                .map((item, index) => ({
                  ...item,
                  order: index + 1,
                }));
            });

            if (nameChangeToRemove.id) {
              setDeletedNames((previousDeletedNames) => [
                ...previousDeletedNames,
                nameChangeToRemove.id,
              ]);
            }
          },
          setCatDocId: generateNameSetter("catDocId", setNameChanges),
          setFirstName: generateNameSetter("firstName", setNameChanges),
          setHasNameChangeDocs,
          setLastName: generateNameSetter("lastName", setNameChanges),
          setMiddleName: generateNameSetter("middleName", setNameChanges),
          setNameChanges,
          setNameMatches,
          setSelectedNameMatchesOption,
          setNoDocsOption,
          setSuffix: generateNameSetter("suffix", setNameChanges),
        }),
        [
          addNameChangeDocument,
          fetchNameChanges,
          hasNameChangeDocs,
          nameChanges,
          nameMatches,
          selectedNameMatchesOption,
          nameOnDocument,
          noDocsOption,
          postConfirmName,
          postNameChanges,
        ]
      )}
    >
      {children}
    </NameChangesContext.Provider>
  );
}

export { default as useNameChanges } from "./useNameChanges";
