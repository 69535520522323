import { useTranslation } from '@ca-dmv-radv/translation';
import { React, useEffect, useState } from 'react';
import { getApplicationDocUploads } from "@ca-dmv-radv/data";
import { invokeIProovValidate, invokeIProovLogErrorMessage, invokeIProovLogCancelledEvent } from "./helpers/invokeiProov";
import { Button } from "@ca-dmv/core";
import SelfieTemplateHeader from "./PresenceVerification/SelfieTempleHeader"
import "@iproov/web-sdk";
import { STATUSID } from './PresenceVerification/constants';
import LoadingIndicator from './PresenceVerification/Loader';


const imageStyle = {
    height: "150px",
};

const iproovBase_URL = process.env.REACT_APP_IPROOV_PLATFORM_URL;
export default function IprooveCapture({ application, applicationType, setCurrentStep, setStatus, setError, iproovToken, fetchDocumentUploads }) {
    const [enableRefresh, setEnableRefresh] = useState(false);
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    // Format any underscores in error code to hyphens to read as key from i18n
    const formatIProovErrorCode = (errorCode) => {
        return errorCode.replaceAll("_", "-");
    };

    const handleIProovError = (errorDetails) => {
        setLoading(true);
        const formattedErrorKey = formatIProovErrorCode(errorDetails.feedback);
        setError(
            t(`iproov-fail-${formattedErrorKey}`, errorDetails.reason)
        );
        setStatus(STATUSID.RETAKE);
        setCurrentStep(3);
        fetchDocumentUploads();
    };

    const onError = async (event) => {
        try {
            handleIProovError(event.detail);
            const iproovPermissionDeniedResponse = await invokeIProovLogErrorMessage(application, applicationType, "Error", "Error during selfie capture");
        } catch (error) {
            console.log(error);
        }
    }

    const onFailed = async (event) => {
        try {
            handleIProovError(event.detail);
            const iproovFailedResponse = await invokeIProovValidate(iproovToken, "failed", application, applicationType);
        } catch (error) {
            setEnableRefresh(true);
            console.log(error);
        }
    }
    const onCancelled = async (event) => {
        try {
            handleIProovError(event.detail);
            const iproovCancelledResponse = await invokeIProovLogCancelledEvent(applicationType, event.detail);
        } catch (error) {
            console.log(error);
        }
    }

    const onPassed = async (event) => {
        try {
            const iproovPassedResponse = await invokeIProovValidate(iproovToken, "passed", application, applicationType);
            await getApplicationDocUploads(applicationType);
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    }
    const onUnsupported = async (event) => {
        try {
            handleIProovError(event.detail);
            const iproovPermissionDeniedResponse = await invokeIProovLogErrorMessage(application, applicationType, "UnSupported", "UnSupported");
        } catch (error) {
            setEnableRefresh(true);
            console.log(error);
        }
    }

    const onPermissionDenied = async (event) => {
        try {
            handleIProovError(event.detail);
            const iproovPermissionDeniedResponse = await invokeIProovLogErrorMessage(application, applicationType, "PermissionDenied", "Camera permissions denied.");
        } catch (error) {
            console.log(error);
        } 
    }

    useEffect(async () => {
        const iProovMe = document.getElementById("radv-proov-id");
        if (iProovMe) {
            iProovMe.addEventListener("canceled", onCancelled);
            iProovMe.addEventListener("passed", onPassed);
            iProovMe.addEventListener("failed", onFailed);
            iProovMe.addEventListener("error", onError);
            iProovMe.addEventListener("unsupported", onUnsupported);
            iProovMe.addEventListener("permission_denied", onPermissionDenied);
            iProovMe.addEventListener("streamed", () => {
                setLoading(true);
            });
        }

    }, [iproovToken]);
    return (
        <>
            {loading && (
                <LoadingIndicator 
                    message={t("screen-modal-presence-verification-iproov-processing", "Processing")} 
                />
            )}

            {!loading && (
                <div>
                    <SelfieTemplateHeader applicationType={applicationType} />
                    <div>
                        {iproovToken && <iproov-me id="radv-proov-id" token={iproovToken} base_url={iproovBase_URL} custom_title="Selfie"
                            filter="shaded" allow_landscape={process.env.REACT_APP_IPROOV_ALLOW_LANDSCAPE} show_countdown={process.env.REACT_APP_IPROOV_SHOW_COUNTDOWN} 
                            disable_exterior_blur={process.env.REACT_APP_IPROOV_EXTERIOR_BLUR} debug={process.env.REACT_APP_IPROOV_DEBUG_MODE} >

                            <div slot="ready">
                            </div>
                            <div slot="passed">
                                {t(
                                    "screen-modal-presence-verification-iproov-passed",
                                    "Successfully captured selfie."
                                )}
                            </div>
                            <div slot="error">
                                {t(
                                    "screen-modal-presence-verification-iproov-error",
                                    "We encountered an issue. Please try again."
                                )}
                            </div>
                            <div slot="canceled">
                                {t(
                                    "screen-modal-presence-verification-iproov-cancelled",
                                    "Do not exit prior to completion of the capture. Please try again."
                                )}
                            </div>
                            <div slot="failed">
                                {t(
                                    "screen-modal-presence-verification-iproov-failed",
                                    "We encountered an issue. Please try again."
                                )}
                            </div>
                            <div slot="permission_denied">
                                {t(
                                    "screen-modal-presence-verification-iproov-permissionDenied",
                                    "Access to camera is denied. Please grant camera permissions and try again."
                                )}
                            </div>
                            <div slot="button" className="flex flex--justify-end mb-12 mt-24">
                                <Button type="button" buttonClass="btn--secondary"> 
                                    {t(
                                        "screen-modal-presence-verification-selfie1",
                                        "Enable Camera"
                                    )}
                                </Button>
                            </div>
                        </iproov-me>}
                        {enableRefresh && <div slot="button" className="flex flex--justify-end mb-12 mt-24">
                            <Button type="button" buttonClass="btn--secondary" onClick={() => window.location.reload()}>
                                Refresh</Button>
                        </div>}
                    </div >
                </div >
            )}

        </>
    )
}

