import React from "react";
import { PageWrapper } from "@ca-dmv/core";
import { useTranslation } from "@ca-dmv-radv/translation";

function RadvPageWrapper({
  pageTitle,
  pageHeading,
  formProps,
  showRequiredFieldSeparator,
  showSeparator,
  fullWidth,
  children,
}) {
  const { t } = useTranslation();

  const breadcrumbProps = { showBreadcrumb: false };

  const errorTextTranslations = {
    singularErrorText: t(
      "errorContainer.singularErrorText",
      "You have an error that needs your attention."
    ),
    pluralErrorsText: t(
      "errorContainer.pluralErrorsText",
      "You have %d errors that need your attention."
    ),
    singleLinkText: t(
      "errorContainer.singleLinkText",
      "Clicking on the link below will guide you to the section that needs more information."
    ),
    multipleLinksText: t(
      "errorContainer.multipleLinksText",
      "Clicking on the links below will guide you to each section that needs more information."
    ),
  };

  return (
    <PageWrapper
      fullWidth={fullWidth}
      pageTitle={pageTitle}
      pageHeading={pageHeading}
      breadcrumbProps={breadcrumbProps}
      formProps={formProps}
      errorTextTranslations={errorTextTranslations}
      showRequiredFieldSeparator={showRequiredFieldSeparator}
      showSeparator={showSeparator}
      showNeedHelp={false}
    >
      {children}
    </PageWrapper>
  );
}

export default RadvPageWrapper;
