import { useEffect } from "react";
import useIdentityDocument from "./useIdentityDocument";

/**
 * Runs a callback when the selected identity document changes.
 *
 * @param {Function} callback
 */
export default function useOnIdentityDocumentChange(callback = () => null) {
  const { selectedIdentityDocument, previousSelectedIdentityDocumentId } =
    useIdentityDocument();

  useEffect(() => {
    if (selectedIdentityDocument?.id !== previousSelectedIdentityDocumentId) {
      callback(
        selectedIdentityDocument?.id,
        previousSelectedIdentityDocumentId
      );
    }
  }, [selectedIdentityDocument, previousSelectedIdentityDocumentId]);
}
