/**
 * Behavior expects that two elements, will be specified by the target string with the class attribute
 * when toggling the display of two elements together.
 *
 * @param {object} btn Element to bind click event.
 * @param {string} targetString Target string to identify elements for toggle behavior.
 */
const initTwoElementToggle = (btn, targetString) => {
  const targetElements = document.querySelectorAll(`.${targetString}`);

  if (targetElements.length === 0) {
    return;
  }

  const targetElementOne = targetElements[0];
  const targetElementTwo = targetElements[1];

  // Click event expects one element to be hidden and one to be displayed by mark up
  btn.addEventListener("click", (e) => {
    e.preventDefault();

    if (targetElementOne.getAttribute("hidden")) {
      targetElementOne.removeAttribute("hidden");
      targetElementOne.setAttribute("aria-hidden", false);

      if (targetElementTwo) {
        targetElementTwo.setAttribute("hidden", "hidden");
        targetElementTwo.setAttribute("aria-hidden", true);
      }
    } else {
      targetElementOne.setAttribute("hidden", "hidden");
      targetElementOne.setAttribute("aria-hidden", true);

      if (targetElementTwo) {
        targetElementTwo.removeAttribute("hidden");
        targetElementTwo.setAttribute("aria-hidden", false);
      }
    }
  });
};

/**
 * Single element click binding
 *
 * @param {object} btn Element to bind click event.
 * @param {object} target Element to toggle display.
 */
const bindClick = (btn, target) => {
  btn.addEventListener(
    "click",
    () => {
      if (target.getAttribute("hidden")) {
        target.removeAttribute("hidden");
        target.setAttribute("aria-hidden", "false");
        btn.setAttribute("aria-expanded", "true");
        target.setAttribute("tabindex", "-1");
        btn.querySelector(".icon--toggle")?.classList.add("rotate--180");
        target.focus();
        return;
      }

      target.setAttribute("hidden", "hidden");
      target.setAttribute("aria-hidden", "true");
      target.removeAttribute("tabindex");
      btn.setAttribute("aria-expanded", "false");
      btn.querySelector(".icon--toggle")?.classList.remove("rotate--180");
      btn.focus();
    },
    false
  );
};

const setUpToggle = () => {
  const controls = [...document.querySelectorAll("[data-toggle]")];

  controls.forEach((control) => {
    const targetString = control.getAttribute("data-toggle");
    const targetElement = document.getElementById(targetString);

    if (!control.getAttribute("aria-controls")) {
      control.setAttribute("aria-controls", targetString);
    }

    // If ID is not provided, use secondary function to check for classes
    if (!targetElement) {
      initTwoElementToggle(control, targetString);
    } else {
      control.setAttribute("aria-expanded", "false");
      targetElement.setAttribute("hidden", "hidden");
      targetElement.setAttribute("aria-hidden", "true");
      bindClick(control, targetElement);
    }
  });
};

export default setUpToggle;
