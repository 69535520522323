import { RadvPageWrapper } from '@ca-dmv-radv/components';
import { APPLICATION_TYPE } from '@ca-dmv-radv/data';
import { useTranslation } from '@ca-dmv-radv/translation';
import React from 'react';

export default function DynamicFinalConfirmation({ title, applicationType }) {
  const { t } = useTranslation();
  const formProps = { isForm: false };
  const headingText = {
     [APPLICATION_TYPE.MDL]: {
      key: "screens-MDL-FinalConfirmation-subtitle",
      value: "Your documents have been submitted for verification."
     },
     [APPLICATION_TYPE.ODP]: {
      key: "screens-ODP-FinalConfirmation-subtitle",
      value: "Your verification is complete."
     },
     [APPLICATION_TYPE.VDL]: {
      key: "screens-VDL-FinalConfirmation-subtitle",
      value: "Your document has been submitted for verification."
     },
  };

  return (
    <RadvPageWrapper
      fullWidth
      formProps={formProps}
      showRequiredFieldSeparator={false}
      pageTitle={title}
    >
      <div className="max-width--800">
        <h3 className="text--primary text--md text--blue-dark-2 text--700 mb-24">
          {t(headingText[applicationType].key, headingText[applicationType].value)}
        </h3>
        <h3 className="text--primary text--md text--blue-dark-2 text--700 mb-24">
          {t(
            "screens-MDL-FinalConfirmation-text",
            "It is now safe to close the browser window."
          )}
        </h3>
      </div>
    </RadvPageWrapper>
  );
}
