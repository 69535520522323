import {
  useApplication,
  useDocumentUploads,
  useGoogleAnalytics,
  useResidencyDocuments,
} from "@ca-dmv-radv/data";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function useConfirmResidencyNameNavigation({
  errors,
  setShowErrors,
  nameOption,
  docNumber,
  selectedRelationshipDocument,
}) {
  const { fetchApplication } = useApplication();
  const {
    setSelectedResidencyDocument,
    postDocumentDetails,
    postConnectionDoc,
    residencyDocDetails,
    setResidencyDocDetails,
    showConnectingDocOptions
  } = useResidencyDocuments();
  const { fetchDocumentUploads } = useDocumentUploads();
  const [requestingNavigation, setRequestingNavigation] = useState(false);
  const navigate = useNavigate();
  const [navigating, setNavigating] = useState(false);
  const { setAnalyticsAction } = useGoogleAnalytics();

  
  useEffect(() => {
    if (!requestingNavigation) {
      return;
    }

    if (!showConnectingDocOptions) {
      navigate("/");
      return;
    };

    if (Object.keys(errors).length > 0) {
      setShowErrors(true);
      setRequestingNavigation(false);
      setResidencyDocDetails(docNumber, { complete: false });
      return;
    }

    (async () => {
      setNavigating(true);
      let success;
      const docNames = residencyDocDetails[docNumber].documentName;
      if (nameOption === 1) {
        setSelectedResidencyDocument(docNumber, null);
      } else if (nameOption === 2) {
        success = await postDocumentDetails(docNumber);
        if (!success) {
          setNavigating(false);
          setRequestingNavigation(false);
          return;
        }

        success = await postConnectionDoc(
          docNumber,
          selectedRelationshipDocument.id
        );
        if (!success) {
          setNavigating(false);
          setRequestingNavigation(false);
          return;
        }
      } else {
        success = await postDocumentDetails(docNumber);
        if (!success) {
          setNavigating(false);
          setRequestingNavigation(false);
          return;
        }
      }

      if (nameOption !== 1) {
        await Promise.all([fetchApplication(), fetchDocumentUploads()]);
      }

      setAnalyticsAction("Submit");

      navigate("/");
    })();
  }, [
    docNumber,
    errors,
    nameOption,
    navigate,
    postConnectionDoc,
    postDocumentDetails,
    requestingNavigation,
    selectedRelationshipDocument,
  ]);

  return useMemo(
    () => ({
      navigating,
      requestingNavigation,
      setRequestingNavigation,
    }),
    [requestingNavigation, navigating]
  );
}
