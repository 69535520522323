import React, { createContext, useContext, useMemo, useState } from "react";

const AppointmentContext = createContext();

export function AppointmentContextProvider({ children }) {
  const [selectedFieldOffice, setSelectedFieldOffice] = useState(null);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [notificationMethod, setNotificationMethod] = useState(null);
  const [needsAccommodations, setNeedsAccommodations] = useState(false);
  const [telNumber, setTelNumber] = useState(null);

  return (
    <AppointmentContext.Provider
      value={useMemo(
        () => ({
          needsAccommodations,
          notificationMethod,
          selectedAppointment,
          selectedFieldOffice,
          setNeedsAccommodations,
          setNotificationMethod,
          setSelectedAppointment,
          setSelectedFieldOffice,
          setTelNumber,
          telNumber,
        }),
        [
          needsAccommodations,
          notificationMethod,
          selectedAppointment,
          selectedFieldOffice,
          telNumber,
        ]
      )}
    >
      {children}
    </AppointmentContext.Provider>
  );
}

export const useAppointments = () => useContext(AppointmentContext);
