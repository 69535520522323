import { SMS_SUCCESS, useModals, useSMS } from "@ca-dmv-radv/data";
import { useTranslation } from "@ca-dmv-radv/translation";
import React from "react";

import TakePhoto from "./TakePhoto";
import TakePhotoWaiting from "./TakePhotoWaiting";

export default function TakePhotoButton({
  documentUpload,
  selfieCall,
  setTriggerSMSModal,
}) {
  const { t } = useTranslation();
  const { sendingSMS, hasSentSMS } = useSMS();
  const { setTakingPhotoDocumentId } = useModals(null);

  if (sendingSMS !== SMS_SUCCESS && !hasSentSMS) {
    return (
      <TakePhoto
        documentId={selfieCall ? 0 : documentUpload.catDocId}
        onClose={() => {
          setTakingPhotoDocumentId(null);
          if (selfieCall) setTriggerSMSModal(false);
        }}
        modalTitle={
          (!selfieCall &&
            t(
              "screens-Dashboard-Modal-TakePhoto-modalTitle",
              "Take a Photo {{documentName}}",
              {
                documentName: documentUpload.name,
              }
            )) ||
          (selfieCall &&
            t(
              "screens-Dashboard-Modal-TakePhoto-modalSelfie",
              "Use your phone to take selfies"
            ))
        }
      />
    );
  }
  return (
    <TakePhotoWaiting
      onClose={() => {
        setTakingPhotoDocumentId(null);
      }}
    />
  );
}
