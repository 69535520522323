import MaintenanceEnabled from "./MaintenanceEnabled";

/**
 * Helper function to throw an error class
 *
 * @param {object} error
 * @returns {object}
 */
export default function errorHandler(error) {
  switch (error?.response?.status) {
    case 503: // Under Maintenance
      throw new MaintenanceEnabled(error);
    case 401 && !window.location.href.includes("mdl"): // Unauthorized
      window.location = process.env.REACT_APP_EDL_URL;
      break;
    default:
      throw error;
  }
}
