import React, { useState } from "react";
import { formatName, numberOfDocuments } from "@ca-dmv-radv/utilities";
import { Trans, useTranslation } from "@ca-dmv-radv/translation";
import { RadvPageWrapper } from "@ca-dmv-radv/components";
import {
  Icon,
  ButtonWrapper,
  Button,
  BUTTON_STYLE_LINK,
  ICON_CHECK,
  ICON_ENVELOPE,
} from "@ca-dmv/core";
import {
  useApplication,
  useDocumentUploads,
  useAppointments,
} from "@ca-dmv-radv/data";
import EmailDocument from "../dashboard/EmailDocument";
import Barcodes from "../dashboard/Barcodes";
import CustomerInfo from "./CustomerInfo";
import DateAndTime from "./DateAndTime";
import Location from "./Location";
import useTranslateDocumentName from "../dashboard/helpers/useTranslateDocumentName";
import { useNavigate } from "react-router-dom";

function DocumentList({ documents }) {
  return (
    <>
      {Object.keys(documents).map((key) => {
        return documents[key].map((document) => {
          const translatedDocumentName = useTranslateDocumentName(document);
          return (
            <p
              key={document.id}
              className="text--700 text--blue-dark-2 text--sm mb-24 flex flex--align-top flex--nowrap text--lh-1"
            >
              <Icon icon={ICON_CHECK} className="min-width--30 mt-2" />
              <span>{translatedDocumentName || document.name}</span>
            </p>
          );
        });
      })}
    </>
  );
}

export default function FinalConfirmation({ title }) {
  const { t } = useTranslation();
  const formProps = { isForm: false };
  const { application } = useApplication();
  const {
    selectedFieldOffice,
    selectedAppointment,
    notificationMethod,
    telNumber,
    needsAccommodations,
  } = useAppointments();
  const [addEmail, setAddEmail] = useState(false);
  const { documentUploads } = useDocumentUploads();
  const filteredDocs = documentUploads.hasOwnProperty(6) && delete documentUploads[6];

  const {
    email,
    confirmationCode,
    application: { name },
  } = application;
  const legalName = formatName(name);
  const navigate = useNavigate();

  return (
    <RadvPageWrapper
      fullWidth
      formProps={formProps}
      showRequiredFieldSeparator={false}
      pageTitle={title}
    >
      <div className="max-width--800">
        <h2 className="text--md bp-md:text--lg text--700 mb-24 bp-md:mb-48">
          {t("screens-FinalConfirmation-heading", "Good News!")}
        </h2>
        <Barcodes barcodeType={39} />
        <h3 className="text--primary text--md text--blue-dark-2 text--700 mb-24">
          {t(
            "screens-FinalConfirmation-subtitle",
            "Your REAL ID documents have been pre-approved by our online verification system."
          )}
        </h3>

        {selectedAppointment && (
          <p className="text--blue-dark-2 text--sm mb-24">
            {t(
              "screens-FinalConfirmation-appointmentDetails",
              "Your appointment details have been successfully submitted to DMV. If you need to cancel or reschedule your appointment, use the online "
            )}
            <a href={process.env.REACT_APP_ALL_APPOINTMENTS_LINK}>
              {t(
                "screens-FinalConfirmation-appointmentsPage",
                "Appointment Center."
              )}
            </a>
          </p>
        )}

        {notificationMethod && telNumber && (
          <CustomerInfo
            legalName={legalName}
            notificationMethod={notificationMethod}
            telNumber={telNumber}
          />
        )}
        {selectedAppointment && (
          <DateAndTime selectedAppointment={selectedAppointment}  lang={application?.lang} />
        )}
        {selectedFieldOffice && (
          <Location
            fieldOffice={selectedFieldOffice}
            needsAccommodations={needsAccommodations}
          />
        )}

        <div className="flex flex--col mt-24">
          {confirmationCode && (
            <div className="w--100 max-width--500 p-20 bp-md:p-30 bg--yellow-light-2 mb-24">
              <p className="text--sm mb-0 text--blue-dark-2 text--700">
                {t(
                  "screens-FinalConfirmation-confirmationCode",
                  "This is your confirmation code:"
                )}
              </p>
              <p className="text--md text--700 text--blue-dark-1 mb-0">
                {confirmationCode}
              </p>
            </div>
          )}
        </div>

        {/* {selectedAppointment && (
          <div className="w--100 p-20 bp-md:p-30 bg--gray-light-2 mb-24">
            <p className="text--md text--blue-dark-2 text--700">
              {t(
                "screens-FinalConfirmation-saveTime-heading",
                "Save time at your upcoming DMV appointment"
              )}
            </p>
            <p className="text--blue-dark-2 text--sm mb-0">
              {t(
                "screens-FinalConfirmation-saveTime-subheading",
                "You can now save time by"
              )}
            </p>
            <ul className="mt-0">
              <li className="text--blue-dark-2 text--sm">
                {t(
                  "screens-FinalConfirmation-saveTime-item1",
                  "Confirming your summary information"
                )}
              </li>
              <li className="text--blue-dark-2 text--sm">
                {t(
                  "screens-FinalConfirmation-saveTime-item2",
                  "Making a payment"
                )}
              </li>
            </ul>
            <ButtonWrapper wrapperClass="mt-40 bp-md:mt-40">
              <Button 
                label={t("screens-FinalConfirmation-saveTime-confirmPayLabel", "Confirm and Pay")} 
                onClick={() => {
                  navigate("/attest");
                }} 
              />
            </ButtonWrapper>
          </div>
        )} */}

        <div className="border--top mt-24 pt-24 mb-24">
          <h4 className="text--primary text--md text--blue-dark-2 text--700 mb-0">
            <Trans
              i18nKey="screens-FinalConfirmation-bringDocuments"
              defaults="Bring these {{numDocs}} documents with you to your DMV appointment to finalize your REAL ID."
              values={{
                numDocs: numberOfDocuments(documentUploads),
              }}
            />
          </h4>
        </div>

        <DocumentList documents={documentUploads} />

        <p className="text--blue-dark-2">
          {t(
            "screens-FinalConfirmation-printDocuments",
            "Print this page and bring it to your DMV appointment for an express experience."
          )}
        </p>
        <p className="text--blue-dark-2 mb-0">
          <Trans
            i18nKey="screens-FinalConfirmation-startHereWindow"
            defaults="When you arrive, report to the <span>Start Here</span> window."
            components={{ span: <span className="text--700" /> }}
          />
        </p>

        {email && (
          <div className="text--blue-dark-2 mt-16 flex flex--align-start bp-lg:flex--align-center flex--nowrap">
            <Icon
              icon={ICON_ENVELOPE}
              className="mr-8 mt-5 bp-lg:mt-0 min-width--30"
            />

            <p className="mb-0">
              <Trans
                i18nKey="screens-FinalConfirmation-emailCopy"
                defaults="For your convenience, an email with a copy of this page has been sent to <span>{{email}}</span>."
                values={{ email }}
                components={{ span: <span className="text--700" /> }}
              />
            </p>
          </div>
        )}
      </div>
      <Barcodes barcodeType={417} />
      <ButtonWrapper wrapperClass="flex--col-reverse mt-40 bp-md:mt-60">
        {/* <Button
          buttonClass="mb-0 bp-md:mr-24"
          label={t("modal-Share-title", "Share a copy of this page")}
          btnStyle={BUTTON_STYLE_LINK}
          onClick={() => {
            setAddEmail(true);
          }}
        /> */}
        <Button
          buttonClass="mb-24 bp-md:mb-0"
          label={t("shared.donelabel", "Done")}
          onClick={() => {
            window.location = process.env.REACT_APP_THANK_YOU_LINK;
          }}
        />
      </ButtonWrapper>

      {addEmail && (
        <EmailDocument
          type="final-confirmation"
          errorAlert={t(
            "errorMessages.addingEmail",
            "There was an error adding your email address. Please try again."
          )}
          onClose={() => {
            setAddEmail(false);
          }}
          actionButtonText={t("shared.continueLabel", "Continue")}
        />
      )}
    </RadvPageWrapper>
  );
}
