import { useEffect, useMemo, useState } from "react";
import { getStates } from "../api";
import { useApplication } from '../application-context-provider';

/**
 * Handles U.S. state data from /catalogs/states.
 *
 * @returns {Object}
 */
export default function useStates() {
  const [shouldFetchStates, setShouldFetchStates] = useState(false);
  const [fetchingStates, setFetchingStates] = useState(false);
  const [states, setStates] = useState(null);
   const { applicationType } = useApplication()
  // Fetch states.
  useEffect(() => {
    if (states || fetchingStates || !shouldFetchStates) {
      return;
    }

    setFetchingStates(true);

    (async () => {
      try {
        const fetchedStates = await getStates(applicationType);
        setStates(
          fetchedStates.data.sort((a, b) => (a.name > b.name) ? 1 : -1)
            .map((state) => {
            return {
              value: state.id,
              label: state.name,
            };
          })
        );
      } catch (error) {
        console.log("Fetch States error: ", error);
      }
      setFetchingStates(false);
    })();
  }, [shouldFetchStates, fetchingStates]);

  return useMemo(
    () => ({
      states,
      fetchingStates,
      setShouldFetchStates,
    }),
    [states, fetchingStates]
  );
}
