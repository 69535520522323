import { useNavigate } from "react-router-dom";
import React from "react";
import { Button } from "@ca-dmv/core";
import { useTranslation } from "@ca-dmv-radv/translation";
import { useGoogleAnalytics } from "@ca-dmv-radv/data";

export default function SelectALocationButton() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setAnalyticsAction } = useGoogleAnalytics();

  return (
    <Button
      buttonClass="mb-40 bp-md:mb-48 mt-48"
      label={t("screens-confirmation-selectLocation", "Select a Location")}
      onClick={() => {
        setAnalyticsAction("Selected a Location");
        navigate("/appointment-location");
      }}
    />
  );
}
