import React from "react";
import { Modal } from "@ca-dmv/modal";
import { DrunkenSquare } from "@ca-dmv/core";

export default function ProcessingPhotosModal({ t }) {
  return (
    <Modal
      modalTitle=""
      contentContainerClass="bp-lg:pl-30 bp-lg:pr-30 bp-lg:pb-30"
    >
      <DrunkenSquare
        loadingText={t(
          "screens-Dashboard-Modal-processingImages-loadingText",
          "Processing Image(s)"
        )}
      />
    </Modal>
  );
}
