import React from "react";
import { Modal } from "@ca-dmv/modal";
import { useTranslation } from "@ca-dmv-radv/translation";
import { Button, BUTTON_STYLE_SECONDARY } from "@ca-dmv/core";

export default function RemoveNameCardModal({
  onClose,
  onChange,
  nameChangeToRemove,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      titleClass="text--blue-dark-2 text--med bp-md:text--lg bp-lg:text--xlg text--uppercase text--italics text--italics text--700 text--lh-1"
      onClose={onClose}
      modalTitle={t(
        "screens-confirm-name-Modal-RemoveNameCard-modalTitle",
        "Remove this name?"
      )}
    >
      <p className="text--sm bp-md:text--med text--lh-sm text--blue-dark-2">
        {t(
          "screens-confirm-name-Modal-RemoveNameCard-modalContent",
          "Are you sure you want to remove {{name}}?",
          {
            name: nameChangeToRemove.name,
          }
        )}
      </p>

      <div className="flex flex--align-center flex--justify-between mt-40">
        <Button
          label={t("shared.noLabel", "No")}
          onClick={onClose}
          btnStyle={BUTTON_STYLE_SECONDARY}
        />
        <Button
          label={t("shared.yesLabel", "Yes")}
          onClick={onChange}
          type="button"
        />
      </div>
    </Modal>
  );
}
