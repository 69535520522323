import React from "react";
import { Modal } from "@ca-dmv/modal";
import { Button, Icon, ICON_CHECK } from "@ca-dmv/core";

export default function ProcessingPhotosSuccessModal({ onClose, t }) {
  return (
    <Modal
      modalTitle=""
      onClose={onClose}
      contentContainerClass="bp-lg:pl-30 bp-lg:pr-30 bp-lg:pb-30 text--center"
    >
      <Icon
        icon={ICON_CHECK}
        className="ml-auto mr-auto icon--xlg"
        alt="check"
      />
      <p className="text--xmd text--lh-sm text--blue-dark-2 text--700 mt-30 mb-0">
        {t(
          "screens-Dashboard-Modal-ProcessingPhotosSuccess-subtitle",
          "Upload successful"
        )}
      </p>
      <p className="text--blue-dark-2 mt-10 mb-40 text--xsm">
        {t(
          "screens-Dashboard-Modal-ProcessingPhotosSuccess-message",
          "Your photo was successfully uploaded."
        )}
      </p>

      <Button
        label={t(
          "screens-Dashboard-Modal-ProcessingPhotosSuccess-buttonOkLabel",
          "Ok"
        )}
        buttonClass="ml-auto mr-auto mb-90"
        onClick={onClose}
      />
    </Modal>
  );
}
