import React, { useContext, useMemo } from "react";
import useCounties from "./useCounties";
import useOffices from "./useOffices";
import useStates from "./useStates";

const CatalogsContext = React.createContext();

export function CatalogsContextProvider({ children }) {
  const { states, fetchingStates, setShouldFetchStates } = useStates();
  const { counties, fetchingCounties, setShouldFetchCounties } = useCounties();
  const offices = useOffices();

  return (
    <CatalogsContext.Provider
      value={useMemo(
        () => ({
          counties,
          fetchingCounties,
          fetchingStates,
          offices,
          setShouldFetchCounties,
          setShouldFetchStates,
          states,
        }),
        [counties, fetchingCounties, fetchingStates, offices, states]
      )}
    >
      {children}
    </CatalogsContext.Provider>
  );
}

export function useCatalogs() {
  return useContext(CatalogsContext);
}
