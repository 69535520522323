import { React } from 'react';
import { Alert, ALERT_WARNING } from "@ca-dmv/core";
import { useTranslation } from '@ca-dmv-radv/translation';
import { isIOS } from 'react-device-detect';
import { APPLICATION_TYPE } from '@ca-dmv-radv/data';

export default function SelfieTemplateHeader({ applicationType }) {
    const { t } = useTranslation();
    return (
        <>
            {applicationType !== APPLICATION_TYPE.VDL && (
                <h3 className="mb-16">
                    {t(
                        "screen-modal-presence-verification-takeYourSelfie-heading",
                        "Ready to provide your photo?"
                    )}
                </h3>
            )}
            <h5>{t(
                "screen-modal-presence-verification-takeYourSelfie-subheading",
                'Tap "Enable Camera" and follow the on-screen instructions. Your picture will be automatically captured.'
            )}</h5>
            <div
                style={{
                    height: "200px",
                    background: "aliceblue",
                }}
            >
                <img
                    src={`${process.env.REACT_APP_IMAGE_PATHS}/img/icons/selfie.gif`}
                    style={{ width: "auto", height: "200px" }}
                    alt="selfie"
                />
            </div>
            <div>
                <Alert
                    alertStyle={ALERT_WARNING}
                    ariaLive="off"
                    iconClass="mr-8 min-width--30"
                    containerClass="mt-24"
                    content={t(
                        "screen-modal-presence-verification-warning",
                        "Do not go back or refresh the page when taking your photo."
                    )}
                    contentClass="text--xsm"
                    alertAlt="warning"
                />
            </div>
        </>
    );
}