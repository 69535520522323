import { useFileUpload } from "@ca-dmv-radv/data";
import { useTranslation } from "@ca-dmv-radv/translation";
import classNames from "classnames";
import React, { useRef } from "react";
import { processFiles, ALLOWED_FILE_TYPES } from "@ca-dmv-radv/files";
import inlineDashedBorder from "./inlineDashedBorder";
import DropzoneMessage from "./DropzoneMessage";
import useDragAndDrop from "./useDragAndDrop";

export default function Dropzone({
  documentUpload,
  setAddingAdditionalPage,
  setFileError,
}) {
  const { t } = useTranslation();

  const { onDragEnter, onDragLeave, onDragOver, isDragActive, onDrop } =
    useDragAndDrop({ t });
  const { files, setFiles, uploading } = useFileUpload();

  const fileInputRef = useRef();

  const updateFilesState = (processedFiles) => {
    setAddingAdditionalPage(false);
    setFiles((currentFiles) => {
      const nextFiles = { ...currentFiles };

      nextFiles[documentUpload.id] = nextFiles[documentUpload.id] || [];

      nextFiles[documentUpload.id] = [
        ...nextFiles[documentUpload.id],
        ...processedFiles,
      ];

      return nextFiles;
    });
  };

  const handleUploadedFiles = async (uploadedFiles) => {
    setFileError(null);

    let processedFiles;

    try {
      processedFiles = await processFiles({ files: uploadedFiles, t });
    } catch (error) {
      if (error instanceof Error) {
        setFileError(error.message);
      } else {
        setFileError(error);
      }

      return;
    }

    // Reject uploads that go over the required page count.
    const requiredPagesError = () => {
      setFileError(
        t("screens-Dashboard-Modal-maximumPagesError", {
          defaultValue: "This document requires a maximum of {{pages}} pages.",
          pages: documentUpload.requiredPages.length,
        })
      );
    };

    if (documentUpload.requiredPages) {
      // set to 0 in case the files array doesn't have documentUpload.id
      const documentUploadFilesLength = files[documentUpload.id]
        ? files[documentUpload.id].length
        : 0;

      if (
        // Ensure the number of uploaded files is not greater than the required page count.
        processedFiles.length + documentUploadFilesLength >
        documentUpload.requiredPages.length
      ) {
        requiredPagesError();
        return;
      }
      if (
        // PDF files have a numPages property that indicates how many pages are in the document.
        processedFiles.length === 1 &&
        processedFiles[0].numPages &&
        processedFiles.numPages + documentUploadFilesLength >
          documentUpload.requiredPages.length
      ) {
        requiredPagesError();
        return;
      }
    }

    // Reject uploads of multiple pages where only one page is allowed.
    const onePageError = () => {
      setFileError(
        t(
          "screens-Dashboard-Modal-onePageError",
          "This document can only have one page"
        )
      );
    };

    if (!documentUpload.multiplePages) {
      if (processedFiles.length > 1) {
        onePageError();
        return;
      }

      if (
        processedFiles.length === 1 &&
        processedFiles[0].numPages &&
        processedFiles[0].numPages > 1
      ) {
        onePageError();
        return;
      }
    }

    updateFilesState(processedFiles);
  };

  const dropzoneWrapperClassnames = classNames(
    "pos--relative bg--blue-light-3 mb-24 p-60 mh--200 border border--md border--blue-dark-1 border-radius--sm border--none"
  );
  const dropzoneClassnames = classNames(
    "pos--absolute pos--top pos--left width--100 h--100 text--blue-dark-2 flex flex--justify-center flex--align-center p-20",
    { "bg--blue": isDragActive }
  );

  return (
    <div
      className={dropzoneWrapperClassnames}
      id="dropzone"
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={async (event) => {
        const droppedFiles = [...onDrop(event)];

        handleUploadedFiles([droppedFiles[0]]);
      }}
      style={!isDragActive && !uploading ? inlineDashedBorder : {}}
    >
      <div className={dropzoneClassnames} id="dropzone-grid">
        <DropzoneMessage
          isDragActive={isDragActive}
          uploading={uploading}
          onClick={() => {
            fileInputRef.current.click();
          }}
        />
        <input
          ref={fileInputRef}
          disabled={uploading}
          type="file"
          multiple={documentUpload.multiplePages === true}
          accept={ALLOWED_FILE_TYPES.join(",")}
          onChange={async (event) => {
            handleUploadedFiles([...event.target.files]);
          }}
          id="file"
          className="visually-hidden"
        />
      </div>
    </div>
  );
}
