import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, ALERT_BLUE, ALERT_WARNING, Button, ButtonWrapper, BUTTON_STYLE_SECONDARY } from "@ca-dmv/core";
import { RadvPageWrapper, ButtonSaveAndExit } from "@ca-dmv-radv/components";
import { useTranslation } from "@ca-dmv-radv/translation";
import { isMobile } from "react-device-detect";
import {
  useApplication,
  useDocumentUploads,
  getApplicationStatus,
  useGoogleAnalytics,
  useThrowFetchError,
  useEmail,
  useIdentityDocument,
  useResidencyDocuments,
  useNameChanges,
  getCategorizedDocumentById,
} from "@ca-dmv-radv/data";
import { usePDFWorker } from "@ca-dmv-radv/files";
import { Modal } from "@ca-dmv/modal";
import ProofOfIdentity from "./ProofOfIdentity";
import ProofOfResidency from "./ProofOfResidency";
import useDashboardStatus, {
  DASHBOARD_STATUS_DONE,
  DASHBOARD_STATUS_READY_TO_SUBMIT,
  DASHBOARD_STATUS_UNDER_REVIEW,
  DASHBOARD_STATUS_VERIFYING,
  DASHBOARD_STATUS_SUBMITTING,
} from "./helpers/useDashboardStatus";
import { autoSubmitOptIn } from "./helpers/constants";
import HeaderText from "./HeaderText";
import UpdateEmail from "./UpdateEmail";
import useFocusableElement from "./helpers/useFocusableElement";
import ReadyToSubmit from "./ReadyToSubmit";
import UnderReview from "./UnderReview";
import PresenceVerficationModal from "./PresenceVerification/PresenceVerficationModal";
import addNexisScript from "./helpers/addNexisScript";
import Barcodes from "./Barcodes";
import { getDeviceWarningMessageFlag } from "../../../data/src/api/index";

const pageHeading = ({ dashboardStatus, t }) => {
  if (dashboardStatus === DASHBOARD_STATUS_DONE) {
    return t("screens-confirmation-heading", "Good News!");
  }

  return t("screens-Dashboard-pageHeading", "Document Submission");
};

let sessionId;

export default function Dashboard({ title }) {
  const {
    submitting,
    setSubmitting,
    submitApplication,
    fetchApplication,
    application,
    setValidationComplete,
    validationComplete,
    submitAutoSubmit,
  } = useApplication();
  const {
    useDocumentUploadPing,
    setShouldPingDocumentUploads,
    residencyDocumentUploads,
    identityDocumentUploads,
    relationshipDocumentUploads,
  } = useDocumentUploads();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const email = application?.email;
  const [showUpdateEmailModal, setShowUpdateEmailModal] = useState(!email);
  const dashboardStatus = useDashboardStatus();
  const { setAnalyticsAction } = useGoogleAnalytics();
  const { setShouldSendUnderReviewEmail } = useEmail();
  const throwFetchError = useThrowFetchError();
  const [previousDashboardStatus, setPreviousDashboardStatus] =
    useState(dashboardStatus);
  const [showOptinOptOutModal, setOptinOptOutModal] = useState(false);
  const [showAutoSubmitModal, setShowAutoSubmitModal] = useState(false);
  const [shouldAutoSubmit, setShouldAutoSubmit] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(true)

  useDocumentUploadPing();
  usePDFWorker();

  sessionId = application?.lnSessionId;
  addNexisScript(sessionId);

  const { statusAlertRef, proofOfIdentityRef, proofOfResidencyRef } =
    useFocusableElement();

  const { selectedIdentityDocument, updateShowIdentityNameOptions } = useIdentityDocument();
  const { selectedResidencyDocuments, selectedRelationshipDocuments, updateShowConnectingDocOptions } =
    useResidencyDocuments();
  const { nameChanges } = useNameChanges();

  const [categorizedDocumentData, setCategorizedDocumentData] = useState({
    identity: null,
    nameChanges: {},
    residency: {},
    connectingResidency: {},
  });

  const fetchCategorizedDocumentData = async (
    categorizedDocumentId,
    documentType,
    index = 1
  ) => {
    await getCategorizedDocumentById(categorizedDocumentId).then((response) => {
      if (
        documentType === "nameChanges" ||
        documentType === "residency" ||
        documentType === "connectingResidency"
      ) {
        categorizedDocumentData[documentType][`doc${index}`] = response.data;
      } else {
        categorizedDocumentData[documentType] = response.data;
      }
      setCategorizedDocumentData({ ...categorizedDocumentData });
    });
  };

  const [deviceWarnMsg, setDeviceWarnMsg] = useState({});
  useEffect(() => {
    (async () => {
      try{
      const devicWarningMsgResponse = await getDeviceWarningMessageFlag();
      if (devicWarningMsgResponse.success) {
        setDeviceWarnMsg(devicWarningMsgResponse.data);
      }
    }catch(e){
      switch (e.response?.status) {
        case 503: // Under Maintenance
          throw new MaintenanceEnabled(e);
        case 404: // Not Found
          throw new ApplicationNotFound(e);
        case 401: // Unauthorized
          throw new SessionExpired(e);
        default:
          throw e;
      }
    }
    })();
  }, []);

  // Fetches calls made on manual refresh when user lands on dashboard
  useEffect(async () => {
    if (shouldRefresh) {
      updateShowIdentityNameOptions(false);
      updateShowConnectingDocOptions(false);
      setShouldRefresh(false);
    }
  }, [shouldRefresh]);

  // Show warning when user clicks browser back or forward button while on dashboard
  useEffect(() => {
      const handlePopState = (event) => {
        // Sample message, will replace with approved language
        const message = t("screens-backButton-warning", "You may experience issues with your application if you use browser buttons to navigate from this page. Click 'Cancel' to stay on this dashboard."); 
        if (!window.confirm(message)) {
          event.preventDefault();
          window.history.back();
        }
      };
  
      window.addEventListener('popstate', handlePopState);
      
      return () => {
        window.removeEventListener('popstate', handlePopState);
      }
    }, []);

  useEffect(async () => {
    if (selectedIdentityDocument) {
      await fetchCategorizedDocumentData(
        Number(selectedIdentityDocument.id),
        "identity"
      );
    }
    if (nameChanges) {
      await nameChanges.forEach(function (nameChangeDocument, index) {
        if (nameChangeDocument?.id && index !== 0)
          fetchCategorizedDocumentData(
            Number(nameChangeDocument.catDocId),
            "nameChanges",
            index
          );
      });
    }
    if (selectedResidencyDocuments) {
      await selectedResidencyDocuments.forEach(function (
        residencyDocument,
        index
      ) {
        if (residencyDocument?.id && index !== 0)
          fetchCategorizedDocumentData(
            Number(residencyDocument.id),
            "residency",
            index
          );
      });
    }
    if (selectedRelationshipDocuments) {
      await selectedRelationshipDocuments.forEach(function (
        connectingResidencyDocument,
        index
      ) {
        if (connectingResidencyDocument?.id && index !== 0)
          fetchCategorizedDocumentData(
            Number(connectingResidencyDocument.id),
            "connectingResidency",
            index
          );
      });
    }
  }, []);

  // Dashboard-status-related side effects.
  useEffect(() => {
    switch (dashboardStatus) {
      case DASHBOARD_STATUS_VERIFYING:
        if (dashboardStatus !== previousDashboardStatus) {
          setAnalyticsAction("Requirements Completed");
        }

        setShouldPingDocumentUploads(true);
        break;
      case DASHBOARD_STATUS_UNDER_REVIEW:
        // Only send on change within the current session.
        if (dashboardStatus !== previousDashboardStatus) {
          setShouldSendUnderReviewEmail(true);
        }

        setShouldPingDocumentUploads(true);
        break;
      case DASHBOARD_STATUS_DONE:
      case DASHBOARD_STATUS_READY_TO_SUBMIT:
        setShouldPingDocumentUploads(false);
        break;
      default:
        setShouldPingDocumentUploads(true);
        break;
    }
  }, [dashboardStatus, previousDashboardStatus]);

  useEffect(() => {
    setPreviousDashboardStatus(dashboardStatus);
  }, [dashboardStatus]);

  const shouldShowSaveAndExit = ![
    DASHBOARD_STATUS_VERIFYING,
    DASHBOARD_STATUS_READY_TO_SUBMIT,
    DASHBOARD_STATUS_SUBMITTING,
    DASHBOARD_STATUS_UNDER_REVIEW,
    DASHBOARD_STATUS_DONE,
  ].includes(dashboardStatus);

  const shouldShowSaveAndExitToUnderReview = [
    DASHBOARD_STATUS_VERIFYING,
    DASHBOARD_STATUS_UNDER_REVIEW,
  ].includes(dashboardStatus);

  const shouldShowSubmit = [
    DASHBOARD_STATUS_READY_TO_SUBMIT,
    DASHBOARD_STATUS_SUBMITTING,
  ].includes(dashboardStatus);

  useEffect(() => {
    if (!shouldShowSubmit) {
      return;
    }

    (async () => {
      let success;
      let message;
      let statusResponse;

      try {
        ({
          success,
          message,
          data: statusResponse,
        } = await getApplicationStatus());

        setValidationComplete(statusResponse && success);
      } catch (error) {
        throwFetchError({
          error,
          message,
        });
      }
    })();
  }, [shouldShowSubmit]);

  const submitApplicationHandler = async () => {
    if (!submitting) {
      setShouldPingDocumentUploads(false);
      setSubmitting(true);
      setAnalyticsAction("Submit Application");
      const success = await submitApplication();
      if (success) {
        await fetchApplication();
        window.scrollTo(0, 0);
      }
      setSubmitting(false);
    }
  };

  const onHandleSelfieModalClose = (hasToInvokeSubmitApplication) => {
    setOptinOptOutModal(false);

    if (hasToInvokeSubmitApplication) {
      submitApplicationHandler();
    }
  };

  const onSubmit = async () => {
    setSubmitting(true);
    await submitApplicationHandler();
    // const response = await callContactless();
    // setSubmitting(false);
    // if(response?.data){
    //   setOptinOptOutModal(true);
    // }if(!response.data){
    //   submitApplicationHandler();
    // }
  };

  /**
   * Check to see if an uploaded document has an associated document. Used for resident documents that have associated relationship document.
   * @param {uploadedDocument} doc1
   * @param {uploadedDocument} doc2
   * @returns boolean
   */
  const docHasAssosiatedDoc = (doc1, doc2) => {
    return doc1.doc === doc2.doc;
  };

  /**
   * Checks to see if all necessary documents have been uploaded and aren't in one of provided statuses.
   * Uses `documentUploads` from the document context to get latest documents.
   *
   * @param Array of statuses
   * @returns boolean
   */
  const checkDocumentStatusesInvalid = (invalidStatuses) => {
    let identityUploaded = false;
    let residentUploaded = false;
    let relationUploaded = false;

    if (identityDocumentUploads?.length >= 1) {
      // Check if any identity documents are in a fail state
      const uploads = identityDocumentUploads.filter((upload) => {
        return invalidStatuses.indexOf(upload.statusId) >= 0;
      });

      identityUploaded = uploads.length === 0;
    }

    // Check if any residency documents are in a fail state
    if (identityUploaded && residencyDocumentUploads?.length >= 2) {
      const uploads = residencyDocumentUploads.filter((upload) => {
        return invalidStatuses.indexOf(upload.statusId) >= 0;
      });

      residentUploaded = uploads.length === 0;
    }

    // Check if any relationship documents are in a fail state
    // If no relationship documents exist, test passes
    if (residentUploaded && relationshipDocumentUploads?.length > 0) {
      const relationshipStatuses = [];
      relationshipDocumentUploads.forEach((item) => {
        // Document data indexes are 1-indexed
        const index = item.doc - 1;
        const assoc = docHasAssosiatedDoc(
          residencyDocumentUploads[index],
          item
        );

        // If there is an associated doc, check its status
        if (assoc) {
          if (invalidStatuses.indexOf(item.statusId) < 0) {
            relationshipStatuses.push(true);
          } else {
            relationshipStatuses.push(false);
          }
        }
      });

      relationUploaded =
        relationshipStatuses.length > 0 &&
        relationshipStatuses.indexOf(false) < 0;
    } else if (
      !relationshipDocumentUploads ||
      relationshipDocumentUploads.length === 0
    ) {
      relationUploaded = true;
    }

    return identityUploaded && residentUploaded && relationUploaded;
  };

  useEffect(() => {
    const run = async () => {
      // Application is submitted, don't do anything.
      if (DASHBOARD_STATUS_DONE === dashboardStatus) {
        return;
      }

      const valid = checkDocumentStatusesInvalid([1, 4, 8]);

      if (!valid) {
        return;
      }

      // Check if use has opted either way and docs are in state to show modal
      if (
        application?.autoSubmit === null ||
        typeof application?.autoSubmit === "undefined"
      ) {
        const updatedApp = await fetchApplication();
        if (
          updatedApp?.status.IDENTITY === true &&
          (updatedApp?.autoSubmit === null ||
            typeof updatedApp?.autoSubmit === "undefined")
        ) {
          setShowAutoSubmitModal(true);
        }
      }
      // Done checking, nothing happening, show dashboard
    };

    run();
  }, [residencyDocumentUploads, identityDocumentUploads, dashboardStatus]);

  const setAutoSubmit = async (option) => {
    const success = await submitAutoSubmit(option);
    setShowAutoSubmitModal(false);

    if (!success) {
      return;
    }

    setShouldAutoSubmit(option);
  };

  return (
    <RadvPageWrapper
      pageHeading={pageHeading({ dashboardStatus, t })}
      formProps={{
        isForm: false,
      }}
      pageTitle={title}
    >
      <HeaderText dashboardStatus={dashboardStatus} />
      {showAutoSubmitModal && (
        <Modal
          modalTitle={t("document-submission-header", "Document Submission")}
          titleClass="text--blue-dark-2 text--md bp-md:text--xlg bp-lg:text--xxlg text--uppercase text--italics text--italics text--700 text--lh-1"
        >

          <p className="mb-24">
            {t(
              "document-submission-subheader",
              "To save time, would you like DMV to automatically submit your uploaded documents after verification?"
            )}
          </p>
          <div>
            <div
              style={{ display: "flex", flexDirection: "column" }}
              className="bp-md:w--45 w--100"
            >
              <Button
                label={t("auto-submit-yes", "Yes, auto submit")}
                onClick={() => setAutoSubmit(autoSubmitOptIn.OPTED_IN)}
                buttonClass="w--100 mb-12"
              />
              <Button
                label={t("auto-submit-no", "No, I will submit later")}
                btnStyle={BUTTON_STYLE_SECONDARY}
                onClick={() => setAutoSubmit(autoSubmitOptIn.OPTED_OUT)}
                buttonClass="w--100"
              />
            </div>
          </div>
        </Modal>
      )}
      {
        (() => {
          if (isMobile && dashboardStatus !== DASHBOARD_STATUS_DONE && typeof deviceWarnMsg !== 'undefined' && deviceWarnMsg?.messageCode && deviceWarnMsg?.defaultMessage) {
            return <Alert
              alertStyle={ALERT_WARNING}
              ariaLive="on"
              containerClass="mt-24 mb-24 bp-md:max-width--500 bp-lg:max-width--900"
              hasBorder={false}
            >
              <p className="mb-0">
                {t(
                  deviceWarnMsg.messageCode,
                  deviceWarnMsg.defaultMessage
                )}
              </p>
            </Alert>
          }
        })()
      }
      <div ref={proofOfIdentityRef} id="proof-of-identity">
        <ProofOfIdentity
          identityCatDocData={categorizedDocumentData.identity}
          nameChangeCatDocData={categorizedDocumentData.nameChanges}
          isMdl={false}
        />
      </div>

      <div ref={proofOfResidencyRef} id="proof-of-residency">
        <ProofOfResidency
          residencyCatDocData={categorizedDocumentData.residency}
          connectingResidencyCatDocData={
            categorizedDocumentData.connectingResidency
          }
        />
      </div>
      {((application.autoSubmit === autoSubmitOptIn.OPTED_IN) && shouldShowSaveAndExitToUnderReview) && (
        <Alert
          alertStyle={ALERT_BLUE}
          ariaLive="on"
          containerClass="mt-24 mb-24 bp-md:max-width--500 bp-lg:max-width--900"
          hasBorder={false}
        >
          <p className="mb-0">
            {t(
              "screens-Dashboard-autoSubmit-indication-message",
              "Your documents will be automatically submitted after verification. You will receive an email with the confirmation code once your documents have been pre-approved."
            )}
          </p>
        </Alert>
      )}
      {showUpdateEmailModal && (
        <UpdateEmail
          onClose={() => {
            setShowUpdateEmailModal(false);
          }}
          modalTitle={t(
            "screens-Dashboard-Modal-UpdateEmail-title",
            "Email Address Not found"
          )}
        />
      )}

      {dashboardStatus === DASHBOARD_STATUS_READY_TO_SUBMIT && (
        <div ref={statusAlertRef}>
          <ReadyToSubmit />
        </div>
      )}

      <ButtonWrapper wrapperClass="flex--col-reverse mt-40 bp-md:mt-60">
        {!showAutoSubmitModal && shouldShowSaveAndExit && (
          <ButtonSaveAndExit buttonClass="bp-md:mr-24 mb-24 bp-md:mb-0" />
        )}
        {!showAutoSubmitModal && shouldShowSaveAndExitToUnderReview && (
          <Button
            buttonClass="bp-md:mr-24 mb-24 bp-md:mb-0"
            label={t("screens-Dashboard-saveAndExit", "Save and Exit")}
            onClick={() => {
              setAnalyticsAction("Save and Exit Under Review");
              navigate("/saved");
            }}
          />
        )}
        {isMobile && showOptinOptOutModal && (
          <PresenceVerficationModal
            onClose={onHandleSelfieModalClose}
            setShouldPingDocumentUploads={setShouldPingDocumentUploads}
          />
        )}
        {shouldShowSubmit &&
          validationComplete &&
          (!application ||
            application.autoSubmit !== autoSubmitOptIn.OPTED_IN) && (
            <Button
              label={t("shared.submitLabel", "Submit and Book DMV Appointment")}
              isLoading={
                submitting || dashboardStatus === DASHBOARD_STATUS_SUBMITTING
              }
              disabled={
                submitting || dashboardStatus === DASHBOARD_STATUS_SUBMITTING
              }
              onClick={onSubmit}
            />
          )}
      </ButtonWrapper>
      {dashboardStatus === DASHBOARD_STATUS_DONE && (
        <Barcodes barcodeType={417} />
      )}
    </RadvPageWrapper>
  );
}

export { sessionId };
