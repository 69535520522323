import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

const GoogleAnalyticsContext = createContext();

export function GoogleAnalyticsContextProvider({ children }) {
  const stepNumber = useRef(0);

  const [analyticsAction, setAnalyticsAction] = useState("Start");

  const trackEvent = useCallback((event, stepTitle) => {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    stepNumber.current += 1;
    window.dataLayer.push({
      event,
      stepTitle,
      stepNumber: stepNumber.current,
    });
  }, []);

  return (
    <GoogleAnalyticsContext.Provider
      value={useMemo(
        () => ({ analyticsAction, setAnalyticsAction, trackEvent }),
        [analyticsAction, setAnalyticsAction, trackEvent]
      )}
    >
      {children}
    </GoogleAnalyticsContext.Provider>
  );
}

export const useGoogleAnalytics = () => {
  return useContext(GoogleAnalyticsContext);
};
