import React, { useEffect, useState } from "react";
import {
  getBarcode39,
  getBarcodePdf417,
  useApplication,
} from "@ca-dmv-radv/data";
import {useTranslation } from "@ca-dmv-radv/translation";

export default function Barcodes({ barcodeType }) {
  const { application } = useApplication();
  const [barcode39, setBarcode39] = useState(null);
  const [barcode417, setBarcode417] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (barcodeType === 39) {
      (async () => {
        const barcode39Result = await getBarcode39(application.confirmationCode);
        if (barcode39Result.success) {
          setBarcode39(barcode39Result.data.barcode);
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (barcodeType === 417) {
      (async () => {
        const barcode417Result = await getBarcodePdf417(
          application.confirmationCode,
          application.application.name.lastName
        );
        if (barcode417Result.success) {
          setBarcode417(barcode417Result.data.barcode);
        }
      })();
    }
  }, []);

  return (
    <div
      className="flex flex--col bp-sm:flex--row bp-sm:flex--align-center flex--nowrap"
      style={{ minHeight: 77 }}
    >
      { barcodeType === 39 && 
        <Barcode
          key="barcode-39"
          alt={t("screens-finalConfirmaiton-barcode39", "barcode 39")}
          imgSrc={barcode39}
          containerClass="ml-0 mb-40 bp-sm:mb-0 max-width--200"
        />
      }
      { barcodeType === 417 && 
        <Barcode
          key="barcode-417"
          alt={t("screens-finalConfirmaiton-barcode417", "barcode 417")}
          imgSrc={barcode417}
          containerClass="ml-0 mb-0 bp-sm:ml-0 max-width--200"
        />
      }
    </div>
  );
}

function Barcode({ alt, imgSrc, containerClass }) {
  if (!imgSrc) {
    return null;
  }
  return (
    <img
      className={containerClass}
      src={`data:image/png;base64,${imgSrc}`}
      alt={alt}
    />
  );
}
