import React, { useEffect, useState } from "react";
import {
  Document as PDFDocument,
  Page,
} from "react-pdf/dist/esm/entry.webpack";

export default function PDF({ dataUrl }) {
  const [pages, setPages] = useState(null);
  const [width, setWidth] = useState(null);

  // Track the modal width when window size changes.
  useEffect(() => {
    const handleResize = () => {
      const modal = document.querySelector(".modal aside"); // In lieu of a more specific selector that would need to be in the design system.
      if (modal) {
        setWidth(modal.offsetWidth);
      } else {
        setWidth(null);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <PDFDocument
      file={dataUrl}
      onLoadSuccess={({ numPages }) => {
        setPages(numPages);
      }}
    >
      {pages &&
        Array.from(Array(pages).keys()).map((page) => (
          <Page
            key={page}
            pageNumber={page + 1}
            renderMode="canvas"
            renderTextLayer={false}
            renderAnnotationLayer={false}
            width={width * 0.95}
          />
        ))}
    </PDFDocument>
  );
}
