import { SMS_SENDING, useGoogleAnalytics, useSMS } from '@ca-dmv-radv/data';
import { useTranslation } from '@ca-dmv-radv/translation';
import { Alert, ALERT_WARNING, Button, BUTTON_STYLE_SECONDARY, ICON_WARNING, InternationalPhoneNumber } from '@ca-dmv/core';
import { Modal } from '@ca-dmv/modal';
import React, { useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';

export default function TakePhoto({ onClose, modalTitle, documentId }) {
  const { phoneNumber, sendingSMS, setPhoneNumber, setDocumentId, setShouldSendSMS } =
    useSMS();
  const { t } = useTranslation();
  const { setAnalyticsAction } = useGoogleAnalytics();

  return (
    <Modal
      onClose={onClose}
      modalTitle={modalTitle}
      contentContainerClass="bp-lg:pl-30 bp-lg:pr-30 bp-lg:pb-30"
    >
      <p className="text--xmd text--lh-sm text--blue-dark-2">
        {t(
          "screens-Dashboard-Modal-TakePhoto-modalSubtitle",
          "A secure link will be sent to your personal mobile device (such as a smartphone or tablet) via text message so you can take photos. No download is required."
        )}
      </p>
      <Alert
        alertStyle={ALERT_WARNING}
        alertIcon={ICON_WARNING}
        ariaLive="off"
        iconClass="mr-8 min-width--30"
        containerClass="mt-24 "
        content={t(
          "screens-Dashboard-Modal-TakePhoto-alertText",
          "Important: Your personal mobile device must have a camera and access to the internet. Standard messaging rates apply."
        )}
        contentClass="text--xsm"
        alertAlt="warning"
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setShouldSendSMS(true);
        }}
      >
        <div className="w--100">
          <InternationalPhoneNumber
            defaultCountry="US"
            containerClass="d-block mx-auto max-width--300"
            countrySelectLables={t(
              "screens-Dashboard-Modal-TakePhoto-countryLabels"
            )}
            phoneNumber={phoneNumber}
            phoneLabel={t(
              "screens-Dashboard-Modal-TakePhoto-phoneLabel",
              "Mobile Phone Number"
            )}
            intPhoneLabel={t(
              "screens-Dashboard-Modal-TakePhoto-countryCode",
              "Country Code"
            )}
            setPhoneNumber={(value) => setPhoneNumber(value || "")}
            hint={t(
              "screens-Dashboard-Modal-TakePhoto-phoneHint",
              "Numbers only, no special characters"
            )}
            isValid={isValidPhoneNumber(phoneNumber)}
            error={t(
              "screens-Dashboard-Modal-TakePhoto-phoneError",
              "Please enter a valid phone number"
            )}
            hideError
          />
        </div>
        <div className="flex flex--align-center flex--justify-between mt-40">
          <Button
            label={t(
              "screens-ProofOfIdentity-Modal-Upload-buttonCancelLabel",
              "Cancel"
            )}
            btnStyle={BUTTON_STYLE_SECONDARY}
            onClick={onClose}
            type="button"
          />
          <Button
            label={`${t(
              "screens-ProofOfIdentity-Modal-Upload-buttonNextLabel",
              "Next"
            )} ${sendingSMS === SMS_SENDING ? "..." : ""}`}
            disabled={
              sendingSMS === SMS_SENDING || !isValidPhoneNumber(phoneNumber)
            }
            onClick={() => {
              setDocumentId(documentId);
              setAnalyticsAction("Sending SMS");
              setShouldSendSMS(true);
            }}
            isSubmit
          />
        </div>
      </form>
    </Modal>
  );
}
