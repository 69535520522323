
import {
  STATUS_IN_PROGRESS,
  STATUS_VERIFYING,
  STATUS_ERROR,
  STATUS_READY,
  STATUS_REVIEW,
  STATUS_INCOMPLETE
} from "@ca-dmv/core";

/**
 * Concatenates the pageTitle and siteTitle
 *
 * @param {string} pageTitle
 * @returns string
 */
export function getDocumentTitle(pageTitle) {
  return `${pageTitle}: ${process.env.REACT_APP_SITE_TITLE}`;
}

// Statuses are weighted to determine precedence
const checkStatusID = {
  7: STATUS_VERIFYING,
  1: STATUS_IN_PROGRESS,
  4: STATUS_ERROR,
  3: STATUS_REVIEW,
  6: STATUS_VERIFYING,
  2: STATUS_VERIFYING,
  5: STATUS_READY,
};

const statusValues = {
  [STATUS_IN_PROGRESS]: 1,
  [STATUS_ERROR]: 2,
  [STATUS_VERIFYING]: 3,
  [STATUS_REVIEW]: 4,
  [STATUS_READY]: 5
};

export function getDocumentSectionStatus(rawDocs) {
  const docs = rawDocs.filter((item) => item);
  let returnStatusName = STATUS_READY;
  let returnStatusValue = 5;
  // Retrieve the minimum weighted status to display for the section
  for (let i = 0; i < docs.length; i += 1) {
    const doc = docs[i];
    let currentStatusName = checkStatusID[doc.statusId];
    let currentStatusValue = statusValues[currentStatusName];
    if (currentStatusName !== STATUS_READY && currentStatusValue < returnStatusValue) {
      returnStatusName = currentStatusName;
      returnStatusValue = currentStatusValue;
    }
  }
  return returnStatusName;
}