import React from "react";
import {
  Separator,
  Button,
  BUTTON_STYLE_LINK__ICON_UNDERLINE,
} from "@ca-dmv/core";
import { useNavigate } from "react-router-dom";
import { useDocumentUploads, useGoogleAnalytics, useIdentityDocument } from "@ca-dmv-radv/data";
import { useTranslation } from "@ca-dmv-radv/translation";
import Document from "../Document";
import getDocumentUploadStatusFlag from "../helpers/getDocumentUploadStatusFlag";

export default function NameChangeUploads({ nameChangeCatDocData }) {
  const navigate = useNavigate();
  const { updateShowIdentityNameOptions } = useIdentityDocument();
  const { nameChangeDocumentUploads } = useDocumentUploads();
  const { setAnalyticsAction } = useGoogleAnalytics();
  const { t } = useTranslation();

  return (
    <div className="bp-md:ml-30">
      <Separator
        heading={t(
          "screens-ProofOfIdentity-nameChangeDocumentLabel",
          "Name Change Certification Document"
        )}
        containerClass="mt-40 mb-8"
      />
      {nameChangeDocumentUploads?.length > 0 &&
        nameChangeDocumentUploads.map((doc, index) => (
          <Document
            containerClass={
              index < nameChangeDocumentUploads.length - 1
                ? "mb-30 js-doc-wrap"
                : "mb-0 js-doc-wrap"
            }
            key={`name-change-document-${doc.id}`}
            documentUpload={doc}
            isCancelable
            onSelectDifferentDocument={() => {
              updateShowIdentityNameOptions(true);
              navigate("/identity");
            }}
            selectDifferentDocumentLabel={t(
              "screens-Dashboard-changeLabel",
              "Select a Different Document"
            )}
            status={getDocumentUploadStatusFlag(doc)}
          />
        ))}
      {!nameChangeDocumentUploads?.length && (
        <div className="flex flex--justify-center bp-md:flex--justify-start">
          <Button
            btnStyle={BUTTON_STYLE_LINK__ICON_UNDERLINE}
            label={t(
              "screens-ProofOfIdentity-selectDocumentButtonLabel",
              "Select Certifying Document"
            )}
            onClick={() => {
              setAnalyticsAction("Selecting Name Change Documents");
              updateShowIdentityNameOptions(true);
              navigate("/identity");
            }}
          />
        </div>
      )}
    </div>
  );
}
