import {
    useDocumentUploads,
  } from "@ca-dmv-radv/data";
  import { Trans, useTranslation } from "@ca-dmv-radv/translation";
  import {
    Alert,
    ALERT_ERROR,
    ALERT_WARNING,
    ICON_SMALL,
    ICON_STOP,
    STATUS_ERROR,
    STATUS_IN_PROGRESS,
    STATUS_INCOMPLETE,
    STATUS_READY,
    STATUS_REVIEW,
    STATUS_SUBMITTING,
    STATUS_VERIFYING,
    StatusContainer,
  } from "@ca-dmv/core";
  import classNames from "classnames";
  import React, { useState } from "react";
  
  import Document from "../Document";
  import getDocumentUploadStatusFlag from "../helpers/getDocumentUploadStatusFlag";
import { showStatus } from "../../mdl/utils";
import { isPendingConfirmationStatus } from "../PresenceVerification/constants";
  
  export default function ProofOfDriversLicense({
    errors,
    showErrors,
    isMdl,
  }) {
    const {
      driversLicenseDocumentUploads,
      isMDLDocument,
      fetchDocumentUploads,
    } = useDocumentUploads();
    const { t } = useTranslation();
    const { documentUploads } = useDocumentUploads();
    const [confirmFields, setConfirmFields] = useState(false);
    const [showIdentityForm, setShowIdentityForm] = useState(false);
    const driversLicense = driversLicenseDocumentUploads[0];

    fetchDocumentUploads();

    const showSubheading = [
      "",
      STATUS_IN_PROGRESS,
      STATUS_VERIFYING,
      STATUS_ERROR,
      STATUS_INCOMPLETE,
    ].includes(showStatus(driversLicense));
  
    const showVerificationMessage = [STATUS_VERIFYING, STATUS_REVIEW].includes(
      showStatus(driversLicense)
    );
  
    const subHeadingClass = classNames("max-width--600 text--blue-dark-2");
  
    return (
      <StatusContainer
        containerClass="mb-40 bp-md:mb-48 max-width--900"
        heading={
          <h3 className="mb-24 text--primary">
            {t("screens-ProofOfDL-legend", "Driver's License Document Verification")}
          </h3>
        }
        headingClass="mb-24"
        subHeading={
          <Trans
            i18nKey="screens-ProofOfDL-subLegend"
            defaults="<p1>Please upload your California Driver's License for verification. Uploaded document must be the original.</p1><p2>Once uploaded, changes cannot be made to your document.</p2>"
            components={{
              p1: <span className="show mb-16" />,
              p2: <span className="show mb-0" />,
            }}
          />
        }
        status={
          showStatus(driversLicense) ? showStatus(driversLicense) : STATUS_READY
        }
        showSubheading={showSubheading}
        subHeadingClass={subHeadingClass}
      >
        {showVerificationMessage && (
          <Alert
            alertStyle={ALERT_WARNING}
            ariaLive="off"
            containerClass="mt-24 mb-24 bp-md:max-width--500 bp-lg:max-width--600"
            hasBorder={false}
          >
            <p className="mb-0">
              {t(
                "screens-Dashboard-verificationMessage",
                "You will receive an email when your documents have been verified. This can take up to 2 business days."
              )}
            </p>
          </Alert>
        )}
        <Document
          status={getDocumentUploadStatusFlag(
            driversLicense,
            documentUploads,
            isMDLDocument
          )}
          isCancelable
          documentUpload={driversLicense}
          confirmFields={confirmFields}
          setConfirmFields={setConfirmFields}
          showIdentityForm={showIdentityForm}
          setShowIdentityForm={setShowIdentityForm}
          containerClass="js-doc-wrap"
        />
        {isPendingConfirmationStatus(driversLicense.ATD) &&
          !confirmFields && (
            <Alert
              alertStyle={ALERT_ERROR}
              alertIcon={ICON_STOP}
              iconSize={ICON_SMALL}
              ariaLive="off"
              containerClass="mt-24 mb-24 bp-md:max-width--500 bp-lg:max-width--600"
              hasBorder={false}
            >
              <p className="mb-0">
                {t(
                  "screens-Dashboard-unconfirmedMessage",
                  "We are unable to confirm the details on your document. Please try again."
                )}
              </p>
            </Alert>
          )}
      </StatusContainer>
    );
  }
  