const formatDate = (date, lang) => {
  let dateFormat;
  if (!date.includes("-") && !date.includes("/")) {
    dateFormat = `${date.substring(0, 2)}-${date.substring(
      2,
      4
    )}-${date.substring(4, 6)}`;
  } else {
    const dateParts = date.split("-");
    // force date into mm/dd/yyyy format
    dateFormat = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
  }
  const newDate = new Date(dateFormat);
  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formattedDate = newDate.toLocaleDateString(lang, dateOptions);

  return `${formattedDate}`;
};

export default formatDate;
