import React from "react";
import { NameGroup, SUFFIX_EN } from "@ca-dmv/core";
import { useTranslation } from "@ca-dmv-radv/translation";

export default function NameFields({
  namePrompt,
  firstName,
  middleName,
  lastName,
  suffix,
  onChangeFirstName,
  onChangeMiddleName,
  onChangeLastName,
  onChangeSuffix,
  errors,
  showErrors,
  focusedElementKey,
}) {
  const { t } = useTranslation();
  const nameInputPattern = "[a-zA-Z\\-\\s']*";

  return (
    <NameGroup
      namePrompt={namePrompt}
      autocomplete
      firstNameLabel={t("shared.firstNameLabel", "First Name")}
      middleNameLabel={t("shared.middleNameLabel", "Middle Name (Optional)")}
      lastNameLabel={t("shared.lastNameLabel", "Last Name")}
      suffixLabel={t("shared.suffixLabel", "Suffix (Optional)")}
      suffixOptions={SUFFIX_EN}
      firstNameMaxLength="35"
      middleNameMaxLength="35"
      lastNameMaxLength="35"
      firstNamePattern={nameInputPattern}
      middleNamePattern={nameInputPattern}
      lastNamePattern={nameInputPattern}
      firstNameRequired={false}
      lastNameRequired
      firstName={firstName}
      middleName={middleName}
      lastName={lastName}
      suffix={suffix}
      onChangeFirstName={onChangeFirstName}
      onChangeMiddleName={onChangeMiddleName}
      onChangeLastName={onChangeLastName}
      onChangeSuffix={onChangeSuffix}
      showRequiredAsterisk={false}
      translatedErrors={errors}
      showErrors={showErrors}
      focusedElementKey={focusedElementKey}
    />
  );
}
