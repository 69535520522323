import { Modal } from '@ca-dmv/modal';
import React from 'react';

import PresenceVerfication from './PresenceVerification';

export default function PresenceVerficationModal({
  onClose,
  setShouldPingDocumentUploads,
}) {
  return (
    <Modal onClose={onClose}>
      <PresenceVerfication
        onClose={onClose}
        setShouldPingDocumentUploads={setShouldPingDocumentUploads}
        isLanding={false}
      ></PresenceVerfication>
    </Modal>
  );
}