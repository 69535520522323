import { ErrorScreen, UnderMaintenance } from "@ca-dmv-radv/screens";
import React, {
  Component,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Modal } from "@ca-dmv/modal";
import { Button } from "@ca-dmv/core";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "@ca-dmv-radv/translation";
import { invalidateSession } from "./api";

const SetErrorContext = createContext();

export function RedirectToError() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname !== "/error") {
      navigate("/error");
    }
  }, [navigate, pathname]);

  return <ErrorScreen />;
}

export function RedirectToUnderMaintenance() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname !== "/under-maintenance") {
      navigate("/under-maintenance");
    }
  }, [navigate, pathname]);

  return <UnderMaintenance />;
}

export const ERROR_AS_REDIRECT = "error-as-redirect";
export const ERROR_AS_MODAL = "error-as-modal";

const initialState = {
  message: null,
  title: null,
  as: null,
  onCloseModal: null,
  onOpenModal: null,
};

export class ErrorContextProvider extends Component {
  constructor(props) {
    super(props);

    this.onCloseModal = this.onCloseModal.bind(this);
    this.setError = this.setError.bind(this);

    this.state = { ...initialState };
  }

  componentDidUpdate() {
    const { onOpenModal } = this.state;
    if (onOpenModal) {
      onOpenModal();
      this.setState({ onOpenModal: null });
    }
  }

  componentDidCatch(error) {
    this.setState({ error });
  }

  onCloseModal() {
    const { onCloseModal } = this.state;

    if (onCloseModal) {
      onCloseModal();
    }

    this.setState({ ...initialState });
  }

  setError(newError) {
    this.setState({
      ...newError,
    });
  }

  render() {
    const { children } = this.props;
    const { error, as, message, title } = this.state;

    if (error && as === ERROR_AS_REDIRECT) {
      // eslint-disable-next-line no-console
      console.error(error);
      return <RedirectToError />;
    }

    return (
      <SetErrorContext.Provider value={this.setError}>
        {as === ERROR_AS_MODAL && (
          <Modal
            modalTitle={title || ""}
            onClose={this.onCloseModal}
            contentContainerClass="pr-20"
          >
            <p>{message}</p>
            <Button label="Ok" onClick={this.onCloseModal} />
          </Modal>
        )}
        {children}
      </SetErrorContext.Provider>
    );
  }
}

export function useAsyncThrow() {
  return useCallback(useContext(SetErrorContext), []);
}

export function useThrowFetchError() {
  const asyncThrow = useAsyncThrow();
  const [errorObject, setErrorObject] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const { error, ...errorState } = errorObject || {};

    if (!error) {
      return;
    }

    // eslint-disable-next-line no-console
    console.error(error);

    if (error.name === "SessionExpired") {
      (async () => {
        await invalidateSession();

        window.location = process.env.REACT_APP_EDL_URL;
      })();
    } else {
      asyncThrow({
        as: ERROR_AS_MODAL,
        title: t("app-error-applicationError", "Application Error"),
        ...errorState,
      });
    }
  }, [errorObject, asyncThrow]);

  return setErrorObject;
}

export function useThrowErrorSavingData() {
  const throwFetchError = useThrowFetchError();
  const { t } = useTranslation();

  return useCallback(
    ({ error, ...rest }) => {
      throwFetchError({
        message: t(
          "app-error-fetchingData",
          "There was an error saving your data. Try again."
        ),
        error,
        ...rest,
      });
    },
    [throwFetchError]
  );
}
