import React from "react";
import { Button, BUTTON_STYLE_SECONDARY } from "@ca-dmv/core";
import { useTranslation } from "@ca-dmv-radv/translation";
import { useApplication, useGoogleAnalytics } from "@ca-dmv-radv/data";

export default function ButtonSaveAndExit({ buttonClass }) {
  const { t } = useTranslation();
  const { saveAndExit, setSaveAndExit, saveAndExitApplication } =
    useApplication();
  const { setAnalyticsAction } = useGoogleAnalytics();

  return (
    <Button
      btnStyle={BUTTON_STYLE_SECONDARY}
      buttonClass={buttonClass}
      label={t("screens-Dashboard-saveAndExit", "Save and Exit")}
      isLoading={saveAndExit}
      disabled={saveAndExit}
      onClick={async () => {
        setAnalyticsAction("Save and Exit");
        setSaveAndExit(true);
        await saveAndExitApplication(false);
      }}
    />
  );
}
