import React, { useState, useEffect } from "react";
import { useTranslation } from "@ca-dmv-radv/translation";
import { getAppointmentLink } from "@ca-dmv-radv/data";
import { Icon, ICON_SPECIAL_EVENT, Button, Note } from "@ca-dmv/core";

export default function AppointmentLink({ hasAppointmentLink = null }) {
  const [showAppointmentLink, setShowAppointmentLink] = useState(false);
  const [appointmentLink, setAppointmentLink] = useState(hasAppointmentLink);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const appointmentLinkResult = await getAppointmentLink();
      const { success, data } = appointmentLinkResult;
      setShowAppointmentLink(true);
      if (success === true) {
        if (data.eligible) {
          setAppointmentLink(data.appointmentUrl);
        }
      }
    })();
  }, []);

  // if API request hasn't completed, show nothing
  if (!appointmentLink) {
    return null;
  }

  // API returned eligible
  return (
    <>
      <h3 className="text--md text--700 text--primary flex flex--align-center mt-48">
        <Icon
          icon={ICON_SPECIAL_EVENT}
          className="width--100 mr-24 max-width--100"
          alt=""
        />
        <span className="mt-16">
          {t(
            "screens-confirmation-attentionAAA",
            "Special Event: Mobile REAL ID Service"
          )}
        </span>
      </h3>
      <p className="max-width--600">
        {t(
          "screens-confirmation-AAAMessage",
          'Skip the DMV office by clicking the "Appointments" link to find an event near you. This service is unavailable 6:00 a.m. to 6:30 a.m.'
        )}
      </p>
      <Note
        containerClass="max-width--500 mb-40"
        note={t(
          "screens-confirmation-AAA-note",
          "If no DMV REAL ID events are near you, appointments at a DMV office are still available."
        )}
      />

      <Button
        buttonClass="mb-40 bp-md:mb-48"
        label={t(
          "screens-confirmation-selectAAALocation",
          "Appointments"
        )}
        // NOTE: this will take the user away from RADV. How do they get back?
        onClick={() => {
          window.location = appointmentLink;
        }}
      />
    </>
  );
}
