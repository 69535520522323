import React from "react";
import { Modal } from "@ca-dmv/modal";
import { Button, Icon, ICON_WARNING_RED } from "@ca-dmv/core";

export default function ProcessingPhotosErrorModal({ onClose, error, t }) {
  return (
    <Modal
      modalTitle=""
      onClose={onClose}
      contentContainerClass="bp-lg:pl-30 bp-lg:pr-30 bp-lg:pb-30 text--center"
    >
      <Icon
        icon={ICON_WARNING_RED}
        className="ml-auto mr-auto icon--xlg"
        alt="Error"
      />
      <p className="text--xmd text--lh-sm text--blue-dark-2 text--700 mt-30 mb-0">
        {t(
          "screens-Dashboard-Modal-ProcessingPhotosError-subtitle",
          "Processing Error"
        )}
      </p>
      <p className="text--blue-dark-2 mt-10 mb-40 text--xsm">{error}</p>

      <Button
        label={t(
          "screens-Dashboard-Modal-ProcessingPhotosSuccess-buttonOkLabel",
          "Ok"
        )}
        buttonClass="ml-auto mr-auto mb-90"
        onClick={onClose}
      />
    </Modal>
  );
}
