import React, { useState, useRef } from 'react'
import { DatePicker, InputGroup } from '@ca-dmv/core/dist/js'
import { InputText } from '@ca-dmv/core/dist/js/input-text'
import { Button, ButtonWrapper, PATTERN_DRIVERS_LICENSE } from '@ca-dmv/core'
import { useEffect } from 'react'
import { searchMDL } from '@ca-dmv-radv/data'
import { useTranslation } from "@ca-dmv-radv/translation";
import ReCAPTCHA from 'react-google-recaptcha';

const MDLForm = ({ setShowMessage, mdlToken, applicationType }) => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dlNumber, setDLNumber] = useState('');
  const [caseNumber, setCaseNumber] = useState('');
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [year, setYear] = useState('');
  const [expiredMonth, setExpiredMonth] = useState('');
  const [expiredDay, setExpiredDay] = useState('');
  const [expiredYear, setExpiredYear] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const recaptchaRef = useRef(null);

  useEffect(() => {
    setIsValid(validate())
  }, [dlNumber, month, day, year, expiredMonth, expiredDay, expiredYear]);

  const validate = () => {
    const isHasValue = [
      dlNumber,
      month,
      year,
      day,
      expiredMonth,
      expiredYear,
      expiredDay
    ].every(value => value.trim() !== '');

    if (isHasValue) {
      const parsedYear = parseInt(year);
      const currentYear = new Date().getFullYear();

      if (isNaN(parsedYear) || parsedYear >= currentYear) {
        return false;
      }

      const dob = `${month.padStart(2, '0')}/${day.padStart(2, '0')}/${year}`;
      const expiredDate = `${expiredMonth.padStart(2, '0')}/${expiredDay.padStart(2, '0')}/${expiredYear}`;
      return validateDate(dob) && validateDate(expiredDate);
    }

    return false;
  }

  function validateDate (date) {
    var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    return date_regex.test(date);
  }

  const clearFields = () => {
    setDLNumber('');
    setMonth('');
    setDay('');
    setYear('');
    setExpiredMonth('');
    setExpiredDay('');
    setExpiredYear('');
  }

  const onClick = async () => {
    try {
      setIsLoading(true);
      setShowMessage(false);
      const recaptchaToken = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();
      const response = await searchMDL({
        dlNumber,
        dateOfBirth: `${month}/${day}/${year}`,
        dlExpirationDate: `${expiredMonth}/${expiredDay}/${expiredYear}`,
        mdlToken: mdlToken || "",
        recaptchaToken: recaptchaToken || ""
      }, applicationType);
      if (response) {
        setShowMessage(true);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setShowMessage(true);
    }
    window.scrollTo(0, 0);
    clearFields();
  }
  return (
    <InputGroup
      legend={t("screens-MDL-search-heading", "Driver's License Search")}
      containerClass='mt-48'
      required={false}
      legendClass='text--blue-dark-2 text--md text--700 text--lh-1 w--100'
      titleClass='text--blue-dark-2 mt-8 mb-16'
    >
      <p className='text--blue-dark-2 text--sm mb-24 mt-12'>{t("screens-MDL-search-disclaimerText", "Please enter this information as it appears on your driver's license.")}</p>
      <InputText
        containerClass='w--100 mb-24 mt-12'
        inputClass="text--uppercase"
        required
        label={t("screens-MDL-search-dlNumber", "Driver's License Number")}
        pattern={PATTERN_DRIVERS_LICENSE}
        onChange={setDLNumber}
        value={dlNumber}
        hintClass='text--xxsm mb-0 mt-2'
      />
      <DatePicker
        containerClass='w--100 mb-16 pb-0'
        legendClass='text--uppercase text--xxxsm'
        monthAsSelect={false}
        required
        legend={t("screens-MDL-search-dob", "Date of Birth")}
        month={month}
        day={day}
        year={year}
        onMonthChange={newMonth => {
          setMonth(newMonth)
        }}
        onDayChange={newDay => {
          setDay(newDay)
        }}
        onYearChange={newYear => {
          setYear(newYear)
        }}
      />
      <DatePicker
        containerClass='w--100 mb-16 pb-0'
        legendClass='text--uppercase text--xxxsm'
        monthAsSelect={false}
        required
        legend={t("screens-MDL-search-dlExpiredDate", "Driver's License Expiration Date")}
        month={expiredMonth}
        day={expiredDay}
        year={expiredYear}
        onMonthChange={newMonth => {
          setExpiredMonth(newMonth)
        }}
        onDayChange={newDay => {
          setExpiredDay(newDay)
        }}
        onYearChange={newYear => {
          setExpiredYear(newYear)
        }}
      />
      <ReCAPTCHA 
        sitekey={process.env.REACT_APP_MDL_RECAPTCHA_KEY}
        ref={recaptchaRef}
        size="invisible"
      />
      <ButtonWrapper wrapperClass='flex--col-reverse mt-40 bp-md:mt-60'>
        <Button
          disabled={!isValid || isLoading}
          label={t("screens-MDL-search-label", "Search")}
          onClick={onClick}
        />
      </ButtonWrapper>
    </InputGroup>
  )
}

export default MDLForm
