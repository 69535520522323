import React from "react";
import { useTranslation } from "@ca-dmv-radv/translation";
import {
  Icon,
  ICON_MAGNIFYING_GLASS,
  Button,
  ButtonWrapper,
} from "@ca-dmv/core";
import { RadvPageWrapper } from "@ca-dmv-radv/components";

function ErrorScreen({ title }) {
  const { t } = useTranslation();
  const formProps = { isForm: false };
  return (
    <RadvPageWrapper
      fullWidth
      formProps={formProps}
      showRequiredFieldSeparator={false}
      pageTitle={title}
    >
      <div className="flex flex--col flex--align-center">
        <div className="mb-40 mt-40">
          <Icon
            icon={ICON_MAGNIFYING_GLASS}
            isDecorative
            className="w--100 max-width--300 bp-md:max-width--400"
          />
        </div>
        <h2 className="h3 max-width--800 text--xmd bp-sm:text--med bp-md:text--xlg text--700 text--center mb-24 bp-md:mb-48">
          {t("screens-error-heading", "Oops, something went wrong")}
        </h2>
        <p className="text--sm mb-24 text--center max-width--600">
          {t("screens-error-message", "Let's get you back on track.")}
        </p>
        <ButtonWrapper wrapperClass="flex--col-reverse mt-40 bp-md:mt-60">
          <a
            href={process.env.REACT_APP_NEED_HELP_URL}
            className="text--700 bp-md:mr-24"
          >
            {t("screens-error-techSupport", "Get Technical Support")}
          </a>
          <Button
            buttonClass="mb-24 bp-md:mb-0"
            label={t(
              "screens-logout-buttonLabel-login",
              "Log in to your MyDMV Account"
            )}
            onClick={() => {
              window.location = process.env.REACT_APP_BACK_TO_DMV_LOGIN;
            }}
          />
        </ButtonWrapper>
      </div>
    </RadvPageWrapper>
  );
}

export default ErrorScreen;
