import React, { useContext, useMemo, useState, useEffect } from "react";
import { useApplication } from "./application-context-provider";
import { sendEmailUnderReview } from "./api";
import {
  FETCH_STATUS_IDLE,
  FETCH_STATUS_SENDING,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_ERROR,
} from "./constants";

const EmailContext = React.createContext();

export function EmailContextProvider({ children }) {
  const { application } = useApplication();
  const email = application?.email;

  const [shouldSendUnderReviewEmail, setShouldSendUnderReviewEmail] =
    useState(false);
  const [underReviewEmailStatus, setUnderReviewEmailStatus] =
    useState(FETCH_STATUS_IDLE);

  /**
   * Under Review
   */
  useEffect(() => {
    (async () => {
      if (!shouldSendUnderReviewEmail) {
        return;
      }
      /**
       * Guard clause to prevent multiple sends.
       */
      if (underReviewEmailStatus === FETCH_STATUS_SUCCESS) {
        return;
      }

      setUnderReviewEmailStatus(FETCH_STATUS_SENDING);
      const response = await sendEmailUnderReview(email);
      if (response?.success) {
        setUnderReviewEmailStatus(FETCH_STATUS_SUCCESS);
      } else {
        setUnderReviewEmailStatus(FETCH_STATUS_ERROR);
      }

      setShouldSendUnderReviewEmail(false);
    })();
  }, [shouldSendUnderReviewEmail]);

  const value = useMemo(
    () => ({
      shouldSendUnderReviewEmail,
      setShouldSendUnderReviewEmail,
      underReviewEmailStatus,
      setUnderReviewEmailStatus,
    }),
    [
      shouldSendUnderReviewEmail,
      setShouldSendUnderReviewEmail,
      underReviewEmailStatus,
      setUnderReviewEmailStatus,
    ]
  );

  return (
    <EmailContext.Provider value={value}>{children}</EmailContext.Provider>
  );
}

export function useEmail() {
  return useContext(EmailContext);
}
