import { useEffect, useMemo, useState } from "react";
import { getCounties } from "../api";
import { useApplication } from '../application-context-provider';

/**
 * Fetches data from /catalogs/county.
 *
 * @returns {Object}
 */
export default function useCounties() {
  const [shouldFetchCounties, setShouldFetchCounties] = useState(false);
  const [fetchingCounties, setFetchingCounties] = useState(false);
  const [counties, setCounties] = useState(null);
   const { applicationType } = useApplication()
  // Fetch counties.
  useEffect(() => {
    if (counties || fetchingCounties || !shouldFetchCounties) {
      return;
    }

    setFetchingCounties(true);

    (async () => {
      try {
        const fetchedCounties = await getCounties(applicationType);
        setCounties(
          fetchedCounties.data
            .filter((county) => county.active)
            .map((county) => {
              return {
                value: county.county_codes,
                label: county.county_name,
              };
            })
        );
      } catch (error) {
        console.log("counties error: ", error);
      }
      setFetchingCounties(false);
    })();
  }, [shouldFetchCounties, fetchingCounties]);

  return useMemo(
    () => ({
      counties,
      fetchingCounties,
      setShouldFetchCounties,
    }),
    [counties, fetchingCounties]
  );
}
