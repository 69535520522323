import axios from "axios";

const STORAGE = axios.create({
  baseURL: window.REACT_APP_STORAGE_URL,
  headers: {
    "content-type": "multipart/form-data",
    "accept-language": "en",
  },
  withCredentials: true,
});

export default STORAGE;
