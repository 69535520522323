import { React, useState, useEffect } from 'react';
import { RadvPageWrapper } from '@ca-dmv-radv/components';
import { Note } from '@ca-dmv/core';
import MDLForm from '../mdlHome/MDLForm';
import { Trans, useTranslation } from "@ca-dmv-radv/translation";
import { useLocation } from "react-router-dom";

function MDLSearch({ title, applicationType }) {
  const { t } = useTranslation();
  const [showMessage, setShowMessage] = useState(false);
  const location = useLocation();

  return (
    <RadvPageWrapper
      formProps={{
        isForm: false,
      }}
      pageTitle={title}
    >
      {showMessage && <div className="border border--blue-light-2 bg--gray-light-3 p-30 mt-0 mb-24 flex flex--nowrap"> 
        <div className="alert__content" role="alert">
          <p className="text--blue-dark-2 text--700 text--xmd mb-10">
            {t("screens-MDL-search-success-heading", "Thank you!")}
          </p>
				  <p className="text--blue-dark-2 text--small">
            {t("screens-MDL-search-success-message", "A new link will be generated and sent to the corresponding device shortly.")}
            </p>
          <p className="text--blue-dark-2 text--small">
            {t("screens-MDL-search-success-safeText", "It is now safe to exit this page.")}
          </p>
        </div>
      </div>}

      {!showMessage && <>
        <MDLForm setShowMessage={setShowMessage} mdlToken={location?.state?.token} applicationType={applicationType} />
      </>}
    </RadvPageWrapper>
  )
}

export default MDLSearch