import React, { useContext, useMemo, useState } from "react";

const ModalContext = React.createContext();

export function ModalContextProvider({ children }) {
  const [takingPhotoDocumentId, setTakingPhotoDocumentId] = useState(null);
  const [uploadingDocumentId, setUploadingDocumentId] = useState(null);

  const [selectDifferentDocumentId, setSelectDifferentDocumentId] =
    useState(null);

  const value = useMemo(
    () => ({
      takingPhotoDocumentId,
      uploadingDocumentId,
      selectDifferentDocumentId,
      setTakingPhotoDocumentId,
      setUploadingDocumentId,
      setSelectDifferentDocumentId,
    }),
    [takingPhotoDocumentId, uploadingDocumentId, selectDifferentDocumentId]
  );

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
}

export function useModals() {
  return useContext(ModalContext);
}
