import { useMounted } from "@ca-dmv-radv/utilities";
import { useTranslation } from "@ca-dmv-radv/translation";
import { useMemo, useState, useCallback } from "react";
import { refreshDocumentUploads, updateConfirmName } from "../api";
import { FETCH_STATUS_IDLE, FETCH_STATUS_SENDING } from "../constants";
import { useThrowErrorSavingData } from "../error-context-provider";
import { useDocumentUploads } from "../document-uploads-context-provider";

export default function usePostConfirmName({ nameChanges, nameMatches, selectedNameMatchesOption }) {
  const [postConfirmNameStatus, setPostConfirmNameStatus] =
    useState(FETCH_STATUS_IDLE);

  const { t } = useTranslation();
  const { isMDLDocument } = useDocumentUploads();
  const mounted = useMounted();
  const throwErrorSavingData = useThrowErrorSavingData();

  const postConfirmName = useCallback(async () => {
    setPostConfirmNameStatus(FETCH_STATUS_SENDING);

    let firstName;
    let middleName;
    let lastName;
    let suffix;
    let success;

    if (!selectedNameMatchesOption) {
      const [identityDocName] = nameChanges;
      firstName = identityDocName.firstName || null;
      middleName = identityDocName.middleName || null;
      lastName = identityDocName.lastName || null;
      suffix = identityDocName.suffix || null;
    } else {
      firstName = null;
      middleName = null;
      lastName = null;
      suffix = null;
    }

    try {
      const confirmNameService =  updateConfirmName;
      ({ success } = await confirmNameService({
        isApplicantName: selectedNameMatchesOption,
        differentName: {
          firstName,
          middleName,
          lastName,
          suffix,
        },
      }));

      if (!success) {
        throw new Error();
      } else {
        await refreshDocumentUploads();
      }

      if (mounted.current) {
        setPostConfirmNameStatus(FETCH_STATUS_IDLE);
      }
    } catch (error) {
      throwErrorSavingData({
        error,
        message: t(
          "app-error-savingData-confirmName",
          "There was an error confirming your name data. Try again."
        ),
      });
    }

    return success;
  }, [
    nameChanges,
    nameMatches,
    selectedNameMatchesOption,
    setPostConfirmNameStatus,
    throwErrorSavingData,
  ]);

  return useMemo(
    () => ({
      postConfirmNameStatus,
      postConfirmName,
    }),
    [postConfirmNameStatus, postConfirmName]
  );
}
