import { Timeout } from "@ca-dmv-radv/components";
import { APPLICATION_TYPE, invalidateSession } from "@ca-dmv-radv/data";
import React from "react";
import { useLocation } from "react-router-dom";
import { isAuthenticated } from "./isAuthenticatedRoute";

const timeoutInMS =
  parseInt(
    typeof process !== "undefined"
      ? process?.env?.REACT_APP_IDLE_USER_TIMEOUT_MINUTES || 10
      : 10,
    10
  ) * 60000;

export default function TimeoutHandler({ applicationType }) {
  const { pathname } = useLocation();

  if (!isAuthenticated(pathname, applicationType)) {
    return null;
  }

  return (
    <Timeout
      timeoutInMS={timeoutInMS}
      onTimeout={async () => {
        await invalidateSession();
        window.location =
          applicationType !== APPLICATION_TYPE.DV
            ? `/${applicationType}/timeout`
            : "/timeout";
      }}
    />
  );
}
