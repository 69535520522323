import React from "react";
import { Trans, useTranslation } from "@ca-dmv-radv/translation";
import { useApplication } from "@ca-dmv-radv/data";
import Barcodes from "./Barcodes";
import SelectALocationButton from "./SelectALocationButton";

export default function AcceptedContent() {
  const { t } = useTranslation();
  const { application } = useApplication();

  return (
    <>
      <Barcodes barcodeType={39} />
      <h4 className="text--primary text--md text--blue-dark-2 text--700 max-width--800 w--100 max-width--600 mb-24">
        {t(
          "screens-confirmation-description",
          "Your REAL ID documents have been accepted and pre-approved by our online verification system."
        )}
      </h4>
      <p className="max-width--800 mb-16 w--100 max-width--600 text--blue-dark-2">
        <Trans
          i18nKey="screens-confirmation-emailSent"
          defaults="For your convenience, an email with a copy of this page has been sent to <span>{{email}}</span>."
          values={{
            email: application.email,
          }}
          components={{ span: <span className="text--700" /> }}
        />
      </p>
      <div className="flex flex--col bp-md:flex--row bp-md:flex--align-center mt-24">
        <div className="w--100 max-width--500 p-20 bp-md:p-30 bg--yellow-light-2 mb-0">
          <p className="text--sm mb-0 text--blue-dark-2 text--700">
            {t(
              "screens-confirmation-confirmationCode",
              "Have this confirmation code ready for your appointment:"
            )}
          </p>
          <p className="text--md text--700 text--blue-dark-1 mb-0">
            {application.confirmationCode}
          </p>
        </div>
      </div>
      <div className="mt-40 mb-md:mt-48 max-width--700">
        <p className="text--blue-dark-2">
          <Trans
            i18nKey="screens-confirmation-makeAppointment"
            defaults="Click <span>Select a Location</span> below to make an appointment at a DMV location to finalize your REAL ID. Please bring the documents you have submitted with you to your appointment."
            components={{ span: <span className="text--700" /> }}
          />
        </p>
        <SelectALocationButton />
      </div>
    </>
  );
}
