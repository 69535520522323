import { DOC_UPLOAD_STATUS_IDS } from "@ca-dmv-radv/data";
import { ABBYY_ERROR_STOP, ABBYY_ERROR_WARNING } from "@ca-dmv/core";

export default function errorStatus({ documentUpload }) {
  if (documentUpload.statusId === DOC_UPLOAD_STATUS_IDS.FAILED) {
    return ABBYY_ERROR_STOP;
  }

  if (documentUpload.statusId === DOC_UPLOAD_STATUS_IDS.UNDER_REVIEW) {
    return ABBYY_ERROR_WARNING;
  }

  return "";
}
