import { useMemo, useState } from "react";

/**
 * Hook providing drag and drop callbacks.
 */
export default function useDragAndDrop() {
  const [isDragActive, setIsDragActive] = useState(false);

  return useMemo(
    () => ({
      isDragActive,
      setIsDragActive,
      onDragOver: (e) => {
        e.preventDefault();
        if (isDragActive) {
          return;
        }
        setIsDragActive(true);
      },
      onDragEnter: (e) => {
        e.preventDefault();
        setIsDragActive(true);
      },
      onDragLeave: (e) => {
        e.preventDefault();

        if (e.target.nodeName === "P") {
          return;
        }

        setIsDragActive(false);
      },
      onDrop: (e) => {
        e.preventDefault();
        const { files: droppedFiles } = e.dataTransfer;
        setIsDragActive(false);
        return droppedFiles;
      },
    }),
    [isDragActive]
  );
}
