import React from "react";
import { Modal } from "@ca-dmv/modal";
import { useTranslation } from "@ca-dmv-radv/translation";
import { Button, BUTTON_STYLE_SECONDARY } from "@ca-dmv/core";

export default function SelectDifferentDocument({ onClose, onConfirm }) {
  const { t } = useTranslation();

  return (
    <Modal
      titleClass="text--blue-dark-2 text--lg bp-md:text--xlg bp-lg:text--xxxlg text--uppercase text--italics text--italics text--700 text--lh-1"
      onClose={onClose}
      modalTitle={t(
        "screens-Dashboard-Modal-SelectDifferentDocument-modalTitle",
        "Are you sure?"
      )}
    >
      <p className="text--sm bp-md:text--med text--lh-sm text--blue-dark-2">
        {t(
          "screens-Dashboard-Modal-SelectDifferentDocument-modalContent",
          "Selecting “Yes” will delete your previous document and personal information from this section. This action can not be undone."
        )}
      </p>

      <div className="flex flex--align-center flex--justify-between mt-40">
        <Button
          btnStyle={BUTTON_STYLE_SECONDARY}
          label={t("shared.noLabel", "No")}
          onClick={onClose}
          type="button"
        />
        <Button
          label={t("shared.yesLabel", "Yes")}
          onClick={() => {
            onConfirm();
            onClose();
          }}
          type-="button"
        />
      </div>
    </Modal>
  );
}
